
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import logoagross from "./logoagross.png";

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import './Menuheader.css'

import '../../../../index.css';
import { useState, useEffect, useCallback, useRef } from "react";
import { lightGreen } from '@mui/material/colors';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';

import LoadingButton from '@mui/lab/LoadingButton';
import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Badge, Divider, Link, MenuList, Modal, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { UserLogged, useMenuContext } from 'app/shared/contexts';
import { Link as ScrollLink } from 'react-scroll';
import { ShowDivContext } from 'app/shared/contexts/ShowDivContext/ShowDivContext';
import { useContext } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';

import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import axios from 'axios';
import Swal from 'sweetalert2'
import classnames from "classnames";

import AuthService from 'app/shared/services/AuthService';
import { CartContext } from 'app/shared/services/CartContext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TokenContext from 'app/shared/services/token/TokenContext';
import { useCarrinho } from 'app/shared/services/cart/CarrinhoContext';
import useIsMobile from 'app/shared/hooks/useIsMobile';

import MenuIcon from '@mui/icons-material/Menu';


const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';



const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  height: 4rem;
`;

const MenuItem = styled.div`
  margin-right: 20px;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
  position: relative;
  color: ${primary};
  font-family: 'Tiresias Infofont', sans-serif !important;

  &:hover {
    .line {
      width: 100%;
    }
  }
`;

const Line = styled.div<{ isActive: boolean }>`
  width: 0;
  height: 2px;
  background-color: ${primary};
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: width 0.3s ease-in-out;

  ${({ isActive }) =>
        isActive &&
        css`
      width: 100%;
    `}
`;


interface MenuheaderProps {
    onMenuClick?: (menuItem: string) => void;
    authService: AuthService;
}

export const Menuheader: React.FC<MenuheaderProps> = (props) => {
    const { onMenuClick } = props;
    const { showDiv, setShowDiv } = useContext(ShowDivContext);
    const { setUserLogged } = useContext(UserLogged);
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useRef(false);
    const { selectedItem, setSelectedItem } = useMenuContext();

    const [activeItem, setActiveItem] = useState('');

    const [loading, setLoading] = useState(false);

    const [showCadastrar, setshowCadastrar] = useState(false);
    const [showCarrinho, setshowCarrinho] = useState(false);
    const [showEsqueceu, setshowEsqueceu] = useState(false);


    const { hiddenMenuVisible, setHiddenMenuVisible } = useMenuContext();
    const { hiddenMenutgVisible, setHiddenMenutgVisible } = useMenuContext();

    const [showPasswordLogin, setShowPasswordLogin] = React.useState(false);
    const [showPasswordCad, setShowPasswordCad] = React.useState(false);
    const [showPasswordConf, setShowPasswordConf] = React.useState(false);

    const token = React.useContext(TokenContext);

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [razaosocial, setRazaoSocial] = useState('');
    const { pontos, setPontos } = useCarrinho();
    const { setScrollToDepoimentos } = useMenuContext();
    const { setScrollToParticipar } = useMenuContext();
    const { setScrollToVideostv } = useMenuContext();
    const { setScrollToPerguntastv } = useMenuContext();
    const { setScrollToPerguntastg } = useMenuContext();
    const [isScrolled, setIsScrolled] = useState(false);
    const [showLogin, setShowLogin] = useState(true);
    const [verifyLogin, setVerifyLogin] = useState(false);
    const carrinhoClient = useCarrinho();
    const { carrinho, setCarrinho, loaderCarrinho, setLoaderCarrinho, mostrarCarrinho } = useCarrinho();
    const totaldepontos = carrinhoClient.carrinho.reduce((totalPontos, carrinho) => totalPontos + Number(carrinho.total_brinde), 0);
    const [badgeFinal, setBadgeFinal] = useState(false);
    const { fetchCarrinho } = useCarrinho();
    const [LoaderRecuperaSenha, setLoaderRecuperaSenha] = useState(false);

    const [CNPJcadastro, setCNPJCadastro] = useState('');
    const [NomeRespCadastro, setNomeRespCadastro] = useState('');
    const [CPFRespCadastro, setCPFRespCadastro] = useState('');
    const [CNPJRecuperaSenha, setCNPJRecuperaSenha] = useState('');
    const [EmailRespCadastro, setEmailRespCadastro] = useState('');
    const [PrimeiraSenhaCadastro, setPrimeiraSenhaCadastro] = useState('');
    const [SegundaSenhaCadastro, setSegundaSenhaCadastro] = useState('');
    const [MenuMobileOpen, setMenuMobileOpen] = useState(false);



    const Mobile = useIsMobile();

    const handleCadastroCNPJ = (event: any) => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        setCNPJCadastro(onlyNums);
    }
    const handleCadastroNome = (event: any) => {
        setNomeRespCadastro(event.target.value);
    }
    const handleCadastroCPF = (event: any) => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        setCPFRespCadastro(onlyNums);
    }
    const handleCadastroEmail = (event: any) => {
        setEmailRespCadastro(event.target.value);
    }
    const handleCadastroSenha1 = (event: any) => {
        setPrimeiraSenhaCadastro(event.target.value);
    }
    const handleCadastroSenha2 = (event: any) => {
        setSegundaSenhaCadastro(event.target.value);
    }
    const handleRecuperaSenha = (event: any) => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        setCNPJRecuperaSenha(onlyNums);
    }

    const handleRecuperaSenhaEmail = () => {
        console.log(CNPJRecuperaSenha);

        const fetchAlterarSenha = async () => {
            setLoaderRecuperaSenha(true);
            try {
                const response = await axios.post(`https://data-api.agross.com.br/sendEmail.php?cnpj=${CNPJRecuperaSenha}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire(
                    'E-mail Enviado!',
                    'Verifique seu e-mail para recuperar senha.',
                    'success'
                )
                console.log(response);
                setshowEsqueceu(false);
                setLoaderRecuperaSenha(false);
            } catch (error) {
                console.error('Erro ao alterar senha', error);
                Swal.fire(
                    'E-mail Enviado!',
                    'Verifique seu e-mail para recuperar senha.',
                    'success'
                )
                setLoaderRecuperaSenha(false);
                setshowEsqueceu(false);
            }
        };

        fetchAlterarSenha();

    }

    interface ICadastro {
        [key: string]: string;
    }
    const handleCadastrar = () => {
        const cadastrarcompleto: ICadastro = {
            cnpj: CNPJcadastro,
            nome: NomeRespCadastro,
            cpf: CPFRespCadastro,
            email: EmailRespCadastro,
            primeirasenha: PrimeiraSenhaCadastro,
            segundasenha: SegundaSenhaCadastro
        };

        const campoMensagem: ICadastro = {
            cnpj: 'Favor Preencher o campo CNPJ da empresa.',
            nome: 'Favor Preencher o campo Nome do responsável.',
            cpf: 'Favor Preencher o campo CPF do responsável.',
            email: 'Favor Preencher o campo email.',
            primeirasenha: 'Favor Preencher o campo senha.',
            segundasenha: 'Favor Preencher o campo confirmação senha.'
        };

        for (const campo in cadastrarcompleto) {
            if (cadastrarcompleto[campo] === '') {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: campoMensagem[campo],
                });
                return;
            }
        }

        if (cadastrarcompleto.primeirasenha !== cadastrarcompleto.segundasenha) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'A senha informada é diferente da confirmação',
            });
            return;
        }

        console.log(cadastrarcompleto);

        const fetchData = async () => {
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/v1/cadastra_cliente?cnpj=${cadastrarcompleto.cnpj}&nome=${cadastrarcompleto.nome}&cpf=${cadastrarcompleto.cpf}&email=${cadastrarcompleto.email}&senha=${cadastrarcompleto.primeirasenha}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(response);
                Swal.fire({
                    title: response.data.result,
                    text: '',
                });

            } catch (error) {
                console.error('Erro ao alterar quantidade', error);
            }
        };

        fetchData();
    };


    function validaCPF(cpf: string): boolean {
        if (cpf.length !== 11) {
            return false;
        }

        let numeros = cpf.substring(0, 9);
        let digitos = cpf.substring(9);

        let soma = 0;
        for (let i = 10; i > 1; i--) {
            soma += Number(numeros.charAt(10 - i)) * i;
        }

        let resultado = (soma % 11) < 2 ? 0 : 11 - (soma % 11);

        // Validação do primeiro dígito
        if (resultado !== Number(digitos.charAt(0))) {
            return false;
        }

        soma = 0;
        numeros = cpf.substring(0, 10);

        for (let k = 11; k > 1; k--) {
            soma += Number(numeros.charAt(11 - k)) * k;
        }
        resultado = (soma % 11) < 2 ? 0 : 11 - (soma % 11);

        // Validação do segundo dígito
        if (resultado !== Number(digitos.charAt(1))) {
            return false;
        }

        return true;
    }





    const { setPontosCliente } = useContext(CartContext);

    const [open, setOpen] = useState(false);
    const [opentg, setOpentg] = useState(false);
    const handleOpen = () => {
        setOpen(true);

    };
    const handleOpentg = () => {
        setOpentg(true);

    };

    const handleClose = () => {
        setOpen(false);
        setOpentg(false);

    };

    const handleLoginChange = (event: any) => {
        setLogin(event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const handleClickShowPasswordLogin = () => setShowPasswordLogin((show) => !show);
    const handleClickShowPasswordCad = () => setShowPasswordCad((show) => !show);
    const handleClickShowPasswordConf = () => setShowPasswordConf((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    useEffect(() => {
        const cnpj = localStorage.getItem('cliente_cnpj');

        const API_URL = 'https://data-api.agross.com.br/api';
        setVerifyLogin(true);

        props.authService.getLoggedUser()
            .then(user => {
                if (user) {

                    if (cnpj !== null && token !== null) {

                        const fetchData = async () => {
                            try {

                                const balanceResponse = await axios.get(
                                    `${API_URL}/v1/saldo/${cnpj}`,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    }
                                );
                                setVerifyLogin(false);
                                setPontos(balanceResponse.data.saldo[0].saldo_disponivel);
                                setPontosCliente(balanceResponse.data.saldo[0].saldo_disponivel);
                                setRazaoSocial(balanceResponse.data.saldo[0].nome_fantasia);
                                setShowDiv(false);
                                setShowLogin(false);
                                setUserLogged(true);
                                await fetchCarrinho();

                            } catch (error) {
                                console.error('Erro ao fazer login', error);
                            }
                        }
                        fetchData();
                    }
                } else {
                    console.error('No user is signed in');
                    setVerifyLogin(false);
                }
            })
            .catch(error => {
                console.error('An error occurred:', error);
            });


    }, [setShowDiv, props.authService, setPontos, setPontosCliente, setUserLogged, token]);

    const handleItemClick = useCallback(
        (item: string) => {
            setActiveItem(item);
            setSelectedItem(item);
            const animationDuration = 700;

            if (item === 'TvAgRoss' || item === 'aovivo' || item === 'comoparticipar' || item === 'videos' || item === 'perguntasfrequentes' || item === 'regras' || item === 'horadorango') {
                if (!hiddenMenutgVisible) {
                    setHiddenMenuVisible(true);
                } else {
                    setHiddenMenutgVisible(false);
                    setTimeout(() => {
                        setHiddenMenuVisible(true);
                        setMenuMobileOpen(false);
                    }, animationDuration);
                }
                if (item === 'TvAgRoss') {
                    navigate('/tvagross');
                } else if (item === 'aovivo') {
                    navigate('/assistaaovivo');
                } else if (item === 'comoparticipar') {
                    setScrollToParticipar(true);
                    navigate('/comoparticipar');
                } else if (item === 'videos') {
                    setScrollToVideostv(true);
                    navigate('/videos');
                } else if (item === 'perguntasfrequentes') {
                    setScrollToPerguntastv(true);
                    navigate('/perguntasfrequentes');
                } else if (item === 'regras') {
                    navigate('/regras');
                } else if (item === 'horadorango') {
                    navigate('/horadorango');
                }
            } else if (item === 'Troque & Ganhe' || item === 'Regulamento' || item === 'perguntasfrequentestg' || item === 'brindes' || item === 'carrinho' || item === 'minhaconta' || item === 'produtosparticipantes') {
                if (!hiddenMenuVisible) {
                    setHiddenMenutgVisible(true);

                } else {
                    setHiddenMenuVisible(false);
                    setTimeout(() => {
                        setHiddenMenutgVisible(true);
                        setMenuMobileOpen(false);
                    }, animationDuration);
                }
                if (item === 'Troque & Ganhe') {
                    navigate('/troqueeganhe');
                } else if (item === 'Regulamento') {
                    navigate('/regulamento');
                } else if (item === 'perguntasfrequentestg') {
                    setScrollToPerguntastg(true);
                    navigate('/perguntasfrequentestg');
                } else if (item === 'produtosparticipantes') {
                    navigate('/produtosparticipantes');
                } else if (item === 'brindes') {
                    navigate('/brindes');
                } else if (item === 'carrinho') {
                    navigate('/carrinho');
                } else if (item === 'minhaconta') {
                    navigate('/minhaconta');
                }
            } else {
                setHiddenMenuVisible(false);
                setHiddenMenutgVisible(false);
            }
            if (item === 'Início') {
                navigate('/');
            } else if (item === 'Nossa História') {
                navigate('/nossahistoria');
            } else if (item === 'Portfólio') {
                navigate('/portfolio');
            } else if (item === 'Recomendações Técnicas') {
                navigate('/recomendacoes');
            } else if (item === 'Vantagens') {
                navigate('/vantagens');
            } else if (item === 'Notícias') {
                navigate('/noticias');
            } else if (item === 'Depoimentos') {
                setScrollToDepoimentos(true);
                navigate('/depoimentos');
            } else if (item === 'Contato') {
                navigate('/contato');
            }
            window.scrollTo(0, 0);

        },

        [hiddenMenutgVisible, hiddenMenuVisible, navigate, setScrollToDepoimentos, setSelectedItem, setScrollToParticipar, setScrollToVideostv, setScrollToPerguntastv, setScrollToPerguntastg, setHiddenMenuVisible, setHiddenMenutgVisible] // Adicione as dependências relevantes aqui
    );

    useEffect(() => {
        if (!isMounted.current) {
            if (location.pathname === "/tvagross") {
                handleItemClick("TvAgRoss");
            } else if (location.pathname === "/") {
                handleItemClick("Início");
            } else if (location.pathname === "/nossahistoria") {
                handleItemClick("Nossa História");
            } else if (location.pathname === "/portfolio") {
                handleItemClick("Portfólio");
            } else if (location.pathname === "/vantagens") {
                handleItemClick("Vantagens");
            } else if (location.pathname === "/noticias") {
                handleItemClick("Notícias");
            } else if (location.pathname === "/depoimentos") {
                handleItemClick("Depoimentos");
            } else if (location.pathname === "/contato") {
                handleItemClick("Contato");
            } else if (location.pathname === "/assistaaovivo") {
                handleItemClick("aovivo");
            } else if (location.pathname === "/comoparticipar") {
                handleItemClick("comoparticipar");
            } else if (location.pathname === "/videos") {
                handleItemClick("videos");
            } else if (location.pathname === "/perguntasfrequentes") {
                handleItemClick("perguntasfrequentes");
            } else if (location.pathname === "/produtosparticipantes") {
                handleItemClick("produtosparticipantes");
            } else if (location.pathname === "/regras") {
                handleItemClick("regras");
            } else if (location.pathname === "/horadorango") {
                handleItemClick("horadorango");
            } else if (location.pathname === "/troqueeganhe") {
                handleItemClick("Troque & Ganhe");
            } else if (location.pathname === "/regulamento") {
                handleItemClick("regulamento");
            } else if (location.pathname === "/perguntasfrequentestg") {
                handleItemClick("perguntasfrequentestg");
            } else if (location.pathname === "/brindes") {
                handleItemClick("brindes");
            } else if (location.pathname === "/carrinho") {
                handleItemClick("carrinho");
            }
            // Adicione outras condições para outros itens do menu, se necessário

            isMounted.current = true;
        }
    }, [location, handleItemClick]);




    const handleLogin = async () => {


        const API_URL = 'https://data-api.agross.com.br/api';
        const credentials = {
            login: login.replace(/\D/g, ''),
            senha: password,
        };



        setLoading(true);
        try {
            const clientLoginResponse = await axios.post(
                `${API_URL}/v1/clientelogin?cnpj=${credentials.login}&senha=${credentials.senha}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const clientLogin = clientLoginResponse.data.clientelogin[0].clientelogin;

            if (clientLogin === 'S') {
                localStorage.setItem('cliente_cnpj', credentials.login);

                const balanceResponse = await axios.get(
                    `${API_URL}/v1/saldo/${credentials.login}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                props.authService.loginusuario().then(() => {                    
                    console.info('usuário ok');
                })
                setPontos(balanceResponse.data.saldo[0].saldo_disponivel);
                setRazaoSocial(balanceResponse.data.saldo[0].nome_fantasia);
                setShowDiv(false);
                setShowLogin(false);
                setUserLogged(true);
                await fetchCarrinho();
                if (location.pathname === "/carrinho") {
                    window.location.reload();
                } if (location.pathname === "/troqueeganhe") {
                    handleItemClick("brindes");
                }
            } else if (clientLogin === 'N') {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Login ou Senha inválidos!',
                });
            } else {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Cliente não cadastrado!',
                });
            }
        } catch (error) {
            console.error('Erro ao fazer login', error);
        }
        setLoading(false);
    };


    const handleDepoimentosClick = () => {
        if (window.location.pathname !== '/') {
            navigate('/');
            setScrollToDepoimentos(true);
        }
    };
    const handleParticiparClick = () => {
        if (window.location.pathname !== '/tvagross') {
            navigate('/tvagross');
            setScrollToParticipar(true);
        }
    };
    const handleVideostvClick = () => {
        if (window.location.pathname !== '/tvagross') {
            navigate('/tvagross');
            setScrollToVideostv(true);
        }
    };
    const handlePerguntastvClick = () => {
        if (window.location.pathname !== '/tvagross') {
            navigate('/tvagross');
            setScrollToPerguntastv(true);
        }
    };



    const checkScroll = () => {
        const offset = window.scrollY;
        const screenHeight = window.innerHeight;
        if (offset > screenHeight) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };



    useEffect(() => {
        window.addEventListener('scroll', checkScroll);
        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, []);

    const handleClick = () => {
        setShowDiv(true);
        // @ts-ignore
        if (onMenuClick) onMenuClick();
    }
    const Loader = () => (
        <div className="loader">
            {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
            <img src={require('assets/loader.gif')} alt="loader" style={{ height: '40%' }} />
        </div>
    );

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    }

    const handleSair = () => {

        props.authService.loggout()
            .then(() => {
                console.log('usuario desconectado 32');
            })
        setShowLogin(true);
        setUserLogged(false);
        localStorage.removeItem('cliente_cnpj');
        localStorage.removeItem('access_token');
    }
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mx-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })


    const alteraQtd = (quantidade: number, id_brinde: number, pedidoCliente: number) => {
        const cnpj = localStorage.getItem('cliente_cnpj')
        setLoaderCarrinho(true);


        const fetchData = async () => {
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/v1/quantidade?cnpj=${cnpj}&id_pedido=${pedidoCliente}&quantidade=${quantidade}&id_brinde=${id_brinde}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    // Encontre o índice do item no carrinho
                    const itemIndex = carrinho.findIndex(item => item.codbrinde === id_brinde);

                    // Faça uma cópia do carrinho
                    const newCarrinho = [...carrinho];

                    // Altere a quantidade do item
                    newCarrinho[itemIndex] = {
                        ...newCarrinho[itemIndex],
                        quantidade: quantidade,
                    };

                    // Atualize o carrinho
                    setCarrinho(newCarrinho);
                    await fetchCarrinho();

                    // Chama a função para atualizar o carrinho
                }
                console.log(response);
            } catch (error) {
                console.error('Erro ao alterar quantidade', error);
            }
        };

        fetchData();
    }

    const removerItem = (id_brinde: number, pedidoCliente: number) => {
        const cnpj = localStorage.getItem('cliente_cnpj')
        setLoaderCarrinho(true);
        console.log('cnpj: ', cnpj, 'pedido: ', pedidoCliente, 'id: ', id_brinde);


        const fetchData = async () => {
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/v1/remover?cnpj=${cnpj}&id_pedido=${pedidoCliente}&id_brinde=${id_brinde}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,

                    },
                });

                console.log(response);
                if (response.status === 200) {
                    await fetchCarrinho();

                }


            } catch (error) {
                console.error('Erro ao alterar quantidade', error);
            }
        };

        fetchData();

    }

    useEffect(() => {

        if (carrinhoClient.carrinho[0] !== (null || undefined)) {
            if (carrinhoClient.carrinho[0].codbrinde !== null) {
                setBadgeFinal(true);
            } else {
                setBadgeFinal(false);
            }
        }



    }, [carrinhoClient])


    const handleLimparCarrinho = (pedidoCliente: Number) => {
        const cnpj = localStorage.getItem('cliente_cnpj')
        setLoaderCarrinho(true);
        const fetchData = async () => {
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/v1/limpar/?cnpj=${cnpj}&id_pedido=${pedidoCliente}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,

                    },
                });

                console.log(response);
                if (response.status === 200) {

                    await fetchCarrinho();

                }


            } catch (error) {
                console.error('Erro ao alterar quantidade', error);
            }
        };

        fetchData();
    }


    return (
        <div style={{ overflowX: 'clip' }}>
            <AppBar position="relative" >
                <Toolbar style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', paddingRight: 0, paddingLeft: 0 }}>

                    {Mobile ?
                        <div style={{ display: "flex", justifyContent: 'space-between', width: '100%', alignItems: 'center', zIndex: 5 }}>
                            <div className=' d-flex flex-column' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ width: '65%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <img src={logoagross} alt="AgRoss" style={{ display: 'block', margin: "auto", cursor: 'pointer', width: '100%' }} onClick={() => {
                                        handleItemClick('Início');
                                        onMenuClick?.('Início');
                                    }} />
                                </div>
                                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Typography style={{ color: primary, fontSize: '0.8rem' }}>
                                        <MailOutlineIcon style={{ fontSize: '1rem' }} /> contato@agross.com.br
                                    </Typography>
                                </div>
                                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Typography style={{ color: primary, fontSize: '0.8rem' }}>
                                        <PhoneInTalkIcon style={{ fontSize: '1rem' }} /> 0800 055 4533
                                    </Typography>
                                </div>
                                <div
                                    className={classnames('mx-3', { 'd-flex': showLogin, 'd-none': !showLogin })}
                                    id='divbtnentrar'

                                >
                                    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                        {!verifyLogin ?
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                style={{ color: primary, border: `1px solid ${primary}` }}
                                                startIcon={<PersonIcon />}
                                                onClick={() => {
                                                    setShowDiv(true);
                                                    // @ts-ignore
                                                    if (onMenuClick) onMenuClick();
                                                }}
                                            >
                                                Entrar
                                            </Button> : <LoadingButton
                                                loading
                                                loadingPosition="start"
                                                variant="outlined"
                                                size="small"
                                                style={{ color: primary, border: `1px solid ${primary}` }}
                                                startIcon={<PersonIcon />}
                                                onClick={() => {
                                                    setShowDiv(true);
                                                    // @ts-ignore
                                                    if (onMenuClick) onMenuClick();
                                                }}
                                            >
                                                Entrar
                                            </LoadingButton>
                                        }
                                        {!verifyLogin ?
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                style={{ color: primary, border: `1px solid ${primary}` }}
                                                startIcon={<PersonAddAlt1Icon />}
                                                onClick={() => setshowCadastrar(true)}
                                                className="m-2"
                                            >
                                                Cadastrar
                                            </Button> :
                                            <LoadingButton
                                                loading
                                                loadingPosition="start"
                                                variant="outlined"
                                                size="small"
                                                style={{ color: primary, border: `1px solid ${primary}` }}
                                                startIcon={<PersonAddAlt1Icon />}
                                                onClick={() => setshowCadastrar(true)}
                                                className="m-2"
                                            >
                                                Cadastrar
                                            </LoadingButton>
                                        }
                                    </div>

                                </div>
                                <div
                                    className={classnames('mx-3 my-3 flex-column', { 'd-none': showLogin, 'd-flex': !showLogin })}
                                    id='divloginok'

                                >
                                    <div>
                                        <Typography style={{ color: primary, textAlign: 'center' }}>
                                            Olá, {razaosocial}
                                        </Typography>
                                        <Typography style={{ color: primary, textAlign: 'center' }}>
                                            Sua pontuação do Troque & Ganhe: {Number(pontos).toLocaleString()}
                                        </Typography>
                                    </div>
                                    <div className=' d-flex mt-3'>
                                        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <Badge color="secondary" badgeContent={badgeFinal ? carrinhoClient.carrinho.length : 0}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    style={{ color: primary, border: `1px solid ${primary}` }}
                                                    startIcon={<LocalGroceryStoreIcon />}
                                                    onClick={() => setshowCarrinho(true)}

                                                >
                                                    Carrinho
                                                </Button>
                                            </Badge>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                style={{ color: primary, border: `1px solid ${primary}`, marginLeft: '1rem' }}
                                                startIcon={<PersonIcon />}
                                                onClick={() => handleItemClick('minhaconta')}
                                            >
                                                Minha Conta
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                style={{ color: primary, border: `1px solid ${primary}`, marginLeft: '1rem' }}
                                                startIcon={<LogoutIcon />}
                                                onClick={handleSair}
                                            >
                                                Sair
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ display: "flex", alignItems: 'center', zIndex: 5, width: '100%' }}>
                            <div className='px-3 d-flex ' style={{ width: '25%' }}>
                                <div>
                                    <p style={{ fontSize: "0.8rem", color: primary }}><MailOutlineIcon style={{ width: "1.2rem" }} /> contato@agross.com.br</p>
                                </div>
                                <div className='mx-3'>
                                    <p style={{ fontSize: "0.8rem", color: primary }}><PhoneInTalkIcon style={{ width: "1.2rem" }} /> 0800 055 4533&nbsp;
                                        <AddCircleIcon style={{ width: '1.1rem', cursor: 'pointer' }} className='hover-icon' onClick={() => {
                                            handleItemClick('Contato');
                                            onMenuClick?.('Contato');
                                        }} />
                                    </p>
                                </div>
                            </div>
                            <div style={{ width: '50%' }}>
                                <img src={logoagross} alt="AgRoss" height="120" style={{ display: 'block', margin: "auto", cursor: 'pointer' }} onClick={() => {
                                    handleItemClick('Início');
                                    onMenuClick?.('Início');
                                }} />
                            </div>
                            <div
                                className={classnames('mx-3', { 'd-block': showLogin, 'd-none': !showLogin })}
                                id='divbtnentrar'
                                style={{ width: '25%', textAlign: 'right' }}
                            >
                                {!verifyLogin ?
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        style={{ color: primary, border: `1px solid ${primary}` }}
                                        startIcon={<PersonIcon />}
                                        onClick={() => {
                                            setShowDiv(true);
                                            // @ts-ignore
                                            if (onMenuClick) onMenuClick();
                                        }}
                                    >
                                        Entrar
                                    </Button> : <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        variant="outlined"
                                        size="small"
                                        style={{ color: primary, border: `1px solid ${primary}` }}
                                        startIcon={<PersonIcon />}
                                        onClick={() => {
                                            setShowDiv(true);
                                            // @ts-ignore
                                            if (onMenuClick) onMenuClick();
                                        }}
                                    >
                                        Entrar
                                    </LoadingButton>
                                }

                                {!verifyLogin ?
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        style={{ color: primary, border: `1px solid ${primary}` }}
                                        startIcon={<PersonAddAlt1Icon />}
                                        onClick={() => setshowCadastrar(true)}
                                        className="m-2"
                                    >
                                        Cadastrar
                                    </Button> :
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        variant="outlined"
                                        size="small"
                                        style={{ color: primary, border: `1px solid ${primary}` }}
                                        startIcon={<PersonAddAlt1Icon />}
                                        onClick={() => setshowCadastrar(true)}
                                        className="m-2"
                                    >
                                        Cadastrar
                                    </LoadingButton>
                                }

                            </div>
                            <div
                                className={classnames('mx-3 flex-column', { 'd-none': showLogin, 'd-flex': !showLogin })}
                                id='divloginok'
                                style={{ width: '25%', textAlign: 'right' }}
                            >
                                <div>
                                    <Typography style={{ color: primary, textAlign: 'center' }}>
                                        Olá, {razaosocial}
                                    </Typography>
                                    <Typography style={{ color: primary, textAlign: 'center' }}>
                                        Sua pontuação do Troque & Ganhe: {Number(pontos).toLocaleString()}
                                    </Typography>
                                </div>
                                <div className=' d-flex mt-3'>
                                    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                        <Badge color="secondary" badgeContent={badgeFinal ? carrinhoClient.carrinho.length : 0}
                                        >
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                style={{ color: primary, border: `1px solid ${primary}` }}
                                                startIcon={<LocalGroceryStoreIcon />}
                                                onClick={() => setshowCarrinho(true)}

                                            >
                                                Carrinho
                                            </Button>
                                        </Badge>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            style={{ color: primary, border: `1px solid ${primary}`, marginLeft: '1rem' }}
                                            startIcon={<PersonIcon />}
                                            onClick={() => handleItemClick('minhaconta')}
                                        >
                                            Minha Conta
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            style={{ color: primary, border: `1px solid ${primary}`, marginLeft: '1rem' }}
                                            startIcon={<LogoutIcon />}
                                            onClick={handleSair}
                                        >
                                            Sair
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }

                    {Mobile ?
                        <div style={{ color: verdeescuro, zIndex: 999 }}>
                            <div className=' d-flex flex-column position-fixed p-2 rounded-2' style={{ top: '1rem', right: '1rem', backgroundColor: 'rgba(247, 240, 234, 0.7)' }}
                                onClick={() => { setMenuMobileOpen(true) }}
                            >
                                <MenuIcon />
                                <Typography fontSize={'0.55rem'}>
                                    MENU
                                </Typography>
                            </div>


                            <div
                                className={`menumobile p-3`}
                                style={{
                                    backgroundColor: verdeescuro, width: '48%', position: 'fixed', top: 0,
                                    right: MenuMobileOpen ? (hiddenMenuVisible ? '47.9%' : '-1%') : '-48%',
                                    borderBottomLeftRadius: '0.5rem', transition: 'right 0.3s ease-in-out'
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div className=' mx-3'>
                                        <img src={require('assets/tvagross/tvagross.png')} alt="" style={{ height: '2.7rem', cursor: 'pointer' }} onClick={() => handleItemClick('TvAgRoss')} />
                                    </div>

                                    <MenuList className=' mx-3'>
                                        <MenuItem className="menuitemfont text-warning" onClick={() => { window.open('https://tvagross.com.br/inicio', '_blank'); setMenuMobileOpen(false); }}>
                                            Assista Ao Vivo
                                            <CSSTransition in={activeItem === 'aovivo'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'aovivo'} />
                                            </CSSTransition>
                                        </MenuItem>

                                        <MenuItem onClick={() => handleItemClick('comoparticipar')} className="menuitemfont menuitemfontcor">

                                            <ScrollLink
                                                activeClass="active"
                                                to="participar"
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                                onClick={() => {
                                                    handleParticiparClick();
                                                    handleItemClick('TvAgRoss');
                                                    setMenuMobileOpen(false);
                                                }}

                                            >
                                                Como Participar
                                            </ScrollLink>
                                            <CSSTransition in={activeItem === 'comoparticipar'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'comoparticipar'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleItemClick('videos')} className="menuitemfont menuitemfontcor">

                                            <ScrollLink
                                                activeClass="active"
                                                to="videostv"
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                                onClick={() => {
                                                    handleVideostvClick();
                                                    handleItemClick('TvAgRoss');
                                                    setMenuMobileOpen(false);
                                                }}

                                            >
                                                Vídeos
                                            </ScrollLink>
                                            <CSSTransition in={activeItem === 'videos'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'videos'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleItemClick('perguntasfrequentes')} className="menuitemfont menuitemfontcor">

                                            <ScrollLink
                                                activeClass="active"
                                                to="perguntastv"
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                                onClick={() => {
                                                    handlePerguntastvClick();
                                                    handleItemClick('TvAgRoss');
                                                    setMenuMobileOpen(false);
                                                }}

                                            >
                                                Perguntas Freq.
                                            </ScrollLink>
                                            <CSSTransition in={activeItem === 'perguntasfrequentes'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'perguntasfrequentes'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem className="menuitemfont menuitemfontcor" onClick={() => { handleOpen(); setMenuMobileOpen(false); }}>
                                            Regras
                                            <CSSTransition in={activeItem === 'regras'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'regras'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        {/* <MenuItem onClick={() => handleItemClick('horadorango')} className="menuitemfont menuitemfontcor">
                                Hora do Rango
                                <CSSTransition in={activeItem === 'horadorango'} timeout={300} classNames="line">
                                    <Line className="line" isActive={selectedItem === 'horadorango'} />
                                </CSSTransition>
                            </MenuItem> */}

                                    </MenuList>
                                </div>

                            </div>
                            <div
                                className={`menumobile p-3`}
                                style={{
                                    backgroundColor: verdeescuro, width: '48%', position: 'fixed', top: 0,
                                    right: MenuMobileOpen ? (hiddenMenutgVisible ? '47.9%' : '-1%') : '-48%',
                                    borderBottomLeftRadius: '0.5rem', transition: 'right 0.3s ease-in-out'
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div className=' mx-3'>
                                        <img src={require('assets/tg/tglogo.png')} alt="" style={{ height: '2.7rem', cursor: 'pointer' }} onClick={() => handleItemClick('Troque & Ganhe')} />
                                    </div>

                                    <MenuList className=' mx-3'>
                                        <MenuItem onClick={() => { handleOpentg(); setMenuMobileOpen(false); }} className="menuitemfont menuitemfontcor" >
                                            Regulamento
                                            <CSSTransition in={activeItem === 'Regulamento'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'Regulamento'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem className="menuitemfont menuitemfontcor">
                                            <ScrollLink
                                                activeClass="active"
                                                to="perguntastg"
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                                onClick={() => {
                                                    handlePerguntastvClick();
                                                    handleItemClick('Troque & Ganhe');
                                                    setMenuMobileOpen(false);
                                                }}

                                            >
                                                Perguntas Freq.
                                            </ScrollLink>
                                            <CSSTransition in={activeItem === 'perguntasfrequentestg'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'perguntasfrequentestg'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleItemClick('produtosparticipantes')} className="menuitemfont menuitemfontcor">
                                            Produtos Participantes
                                            <CSSTransition in={activeItem === 'produtosparticipantes'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'produtosparticipantes'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem onClick={() => { handleItemClick('brindes'); setMenuMobileOpen(false); }} className="menuitemfont menuitemfontcor">
                                            Brindes
                                            <CSSTransition in={activeItem === 'brindes'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'brindes'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            handleItemClick('carrinho');
                                            setshowCarrinho(false);
                                            setMenuMobileOpen(false);
                                        }} className={`menuitemfont menuitemfontcor `}>
                                            <LocalGroceryStoreIcon style={{ width: '1.2rem' }} /> Carrinho
                                            <CSSTransition in={activeItem === 'carrinho'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'carrinho'} />
                                            </CSSTransition>
                                        </MenuItem>
                                    </MenuList>
                                </div>

                            </div>
                            <div style={{ backgroundColor: bege, top: 0, right: MenuMobileOpen ? '0' : '-48%', width: '48%', borderBottomLeftRadius: '0.5rem', transition: 'right 0.3s ease-in-out' }} className=' d-flex flex-column position-fixed menumobile p-3'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 999, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => setMenuMobileOpen(false)}
                                />
                                <MenuList style={{ margin: 'auto' }}>
                                    <MenuItem onClick={() => {
                                        handleItemClick('Início');
                                        onMenuClick?.('Início');
                                        setMenuMobileOpen(false);
                                    }} className="menuitemfont" >
                                        Início
                                        <CSSTransition in={activeItem === 'Início'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Início'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={() => { handleItemClick('Nossa História'); setMenuMobileOpen(false); }} className="menuitemfont">
                                        Nossa História
                                        <CSSTransition in={activeItem === 'Nossa História'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Nossa História'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleItemClick('Portfólio'); setMenuMobileOpen(false); }} className="menuitemfont">
                                        Portfólio
                                        <CSSTransition in={activeItem === 'Portfólio'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Portfólio'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleItemClick('Recomendações Técnicas'); setMenuMobileOpen(false); }} className="menuitemfont">
                                        Rec. Técnicas
                                        <CSSTransition in={activeItem === 'Recomendações Técnicas'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Recomendações Técnicas'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleItemClick('Vantagens'); setMenuMobileOpen(false); }} className="menuitemfont">
                                        Vantagens
                                        <CSSTransition in={activeItem === 'Vantagens'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Vantagens'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleItemClick('Notícias'); setMenuMobileOpen(false); }} className="menuitemfont">
                                        Notícias
                                        <CSSTransition in={activeItem === 'Notícias'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Notícias'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem className="menuitemfont">
                                        <ScrollLink
                                            activeClass="active"
                                            to="depoimentos"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            onClick={() => {
                                                handleDepoimentosClick();
                                                handleItemClick('Início');
                                                setMenuMobileOpen(false);
                                            }}
                                        >
                                            Depoimentos
                                        </ScrollLink>
                                        <CSSTransition in={activeItem === 'Depoimentos'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Depoimentos'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleItemClick('Contato'); setMenuMobileOpen(false); }} className="menuitemfont">
                                        Contato
                                        <CSSTransition in={activeItem === 'Contato'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Contato'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleItemClick('TvAgRoss'); }} className="menuitemfont">
                                        TvAgRoss
                                        <CSSTransition in={activeItem === 'TvAgRoss'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'TvAgRoss'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleItemClick('Troque & Ganhe') }} className="menuitemfont">
                                        Troque & Ganhe
                                        <CSSTransition in={activeItem === 'Troque & Ganhe'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Troque & Ganhe'} />
                                        </CSSTransition>
                                    </MenuItem>
                                </MenuList>
                            </div>

                        </div>
                        :
                        <div style={{
                            width: '100%', position: isScrolled ? 'fixed' : 'relative',
                            top: isScrolled ? 0 : '-100%',
                            zIndex: 10,
                            transition: 'top 1s ease-in-out'
                        }}>

                            <div style={{
                                display: 'flex',
                                width: '100%',

                                backgroundImage: "url('./header-bg.jpg')"
                            }}>
                                <MenuContainer style={{ margin: 'auto' }}>
                                    <MenuItem onClick={() => {
                                        handleItemClick('Início');
                                        onMenuClick?.('Início');
                                    }} className="menuitemfont">
                                        Início
                                        <CSSTransition in={activeItem === 'Início'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Início'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleItemClick('Nossa História')} className="menuitemfont">
                                        Nossa História
                                        <CSSTransition in={activeItem === 'Nossa História'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Nossa História'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleItemClick('Portfólio')} className="menuitemfont">
                                        Portfólio
                                        <CSSTransition in={activeItem === 'Portfólio'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Portfólio'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleItemClick('Recomendações Técnicas')} className="menuitemfont">
                                        Recomendações Técnicas
                                        <CSSTransition in={activeItem === 'Recomendações Técnicas'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Recomendações Técnicas'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleItemClick('Vantagens')} className="menuitemfont">
                                        Vantagens
                                        <CSSTransition in={activeItem === 'Vantagens'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Vantagens'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleItemClick('Notícias')} className="menuitemfont">
                                        Notícias
                                        <CSSTransition in={activeItem === 'Notícias'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Notícias'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem className="menuitemfont">
                                        <ScrollLink
                                            activeClass="active"
                                            to="depoimentos"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            onClick={() => {
                                                handleDepoimentosClick();
                                                handleItemClick('Início');
                                            }}

                                        >
                                            Depoimentos
                                        </ScrollLink>

                                        <CSSTransition in={activeItem === 'Depoimentos'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Depoimentos'} />
                                        </CSSTransition>
                                    </MenuItem>

                                    <MenuItem onClick={() => handleItemClick('Contato')} className="menuitemfont">
                                        Contato
                                        <CSSTransition in={activeItem === 'Contato'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Contato'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleItemClick('TvAgRoss')} className="menuitemfont">
                                        TvAgRoss
                                        <CSSTransition in={activeItem === 'TvAgRoss'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'TvAgRoss'} />
                                        </CSSTransition>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleItemClick('Troque & Ganhe')} className="menuitemfont">
                                        Troque & Ganhe
                                        <CSSTransition in={activeItem === 'Troque & Ganhe'} timeout={300} classNames="line">
                                            <Line className="line" isActive={selectedItem === 'Troque & Ganhe'} />
                                        </CSSTransition>
                                    </MenuItem>
                                </MenuContainer>

                            </div>
                            <div
                                className={`hidden-menu-slide ${hiddenMenuVisible ? 'hidden-menu-visible' : 'hidden-menu-hidden'}`}
                                style={{ backgroundColor: verdeescuro, width: '100%' }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className=' mx-3'>
                                        <img src={require('assets/tvagross/tvagross.png')} alt="" style={{ height: '2.7rem', cursor: 'pointer' }} onClick={() => handleItemClick('TvAgRoss')} />
                                    </div>

                                    <MenuContainer className=' mx-3'>
                                        <MenuItem className="menuitemfont text-warning" onClick={() => window.open('https://tvagross.com.br/inicio', '_blank')}>
                                            Assista Ao Vivo
                                            <CSSTransition in={activeItem === 'aovivo'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'aovivo'} />
                                            </CSSTransition>
                                        </MenuItem>

                                        <MenuItem onClick={() => handleItemClick('comoparticipar')} className="menuitemfont menuitemfontcor">

                                            <ScrollLink
                                                activeClass="active"
                                                to="participar"
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                                onClick={() => {
                                                    handleParticiparClick();
                                                    handleItemClick('TvAgRoss');
                                                }}

                                            >
                                                Como Participar
                                            </ScrollLink>
                                            <CSSTransition in={activeItem === 'comoparticipar'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'comoparticipar'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleItemClick('videos')} className="menuitemfont menuitemfontcor">

                                            <ScrollLink
                                                activeClass="active"
                                                to="videostv"
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                                onClick={() => {
                                                    handleVideostvClick();
                                                    handleItemClick('TvAgRoss');
                                                }}

                                            >
                                                Vídeos
                                            </ScrollLink>
                                            <CSSTransition in={activeItem === 'videos'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'videos'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleItemClick('perguntasfrequentes')} className="menuitemfont menuitemfontcor">

                                            <ScrollLink
                                                activeClass="active"
                                                to="perguntastv"
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                                onClick={() => {
                                                    handlePerguntastvClick();
                                                    handleItemClick('TvAgRoss');
                                                }}

                                            >
                                                Perguntas Frequentes
                                            </ScrollLink>
                                            <CSSTransition in={activeItem === 'perguntasfrequentes'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'perguntasfrequentes'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem className="menuitemfont menuitemfontcor" onClick={handleOpen}>
                                            Regras
                                            <CSSTransition in={activeItem === 'regras'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'regras'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        {/* <MenuItem onClick={() => handleItemClick('horadorango')} className="menuitemfont menuitemfontcor">
                                Hora do Rango
                                <CSSTransition in={activeItem === 'horadorango'} timeout={300} classNames="line">
                                    <Line className="line" isActive={selectedItem === 'horadorango'} />
                                </CSSTransition>
                            </MenuItem> */}

                                    </MenuContainer>
                                </div>

                            </div>
                            <div
                                className={`hidden-menu-slide ${hiddenMenutgVisible ? 'hidden-menu-visible' : 'hidden-menu-hidden'}`}
                                style={{ backgroundColor: verdeescuro, width: '100%' }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className=' mx-3'>
                                        <img src={require('assets/tg/tglogo.png')} alt="" style={{ height: '2.7rem', cursor: 'pointer' }} onClick={() => handleItemClick('Troque & Ganhe')} />
                                    </div>

                                    <MenuContainer className=' mx-3'>
                                        <MenuItem onClick={handleOpentg} className="menuitemfont menuitemfontcor" >
                                            Regulamento
                                            <CSSTransition in={activeItem === 'Regulamento'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'Regulamento'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem className="menuitemfont menuitemfontcor">
                                            <ScrollLink
                                                activeClass="active"
                                                to="perguntastg"
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                                onClick={() => {
                                                    handlePerguntastvClick();
                                                    handleItemClick('Troque & Ganhe');
                                                }}

                                            >
                                                Perguntas Frequentes
                                            </ScrollLink>
                                            <CSSTransition in={activeItem === 'perguntasfrequentestg'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'perguntasfrequentestg'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleItemClick('produtosparticipantes')} className="menuitemfont menuitemfontcor">
                                            Produtos Participantes
                                            <CSSTransition in={activeItem === 'produtosparticipantes'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'produtosparticipantes'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleItemClick('brindes')} className="menuitemfont menuitemfontcor">
                                            Brindes
                                            <CSSTransition in={activeItem === 'brindes'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'brindes'} />
                                            </CSSTransition>
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            handleItemClick('carrinho');
                                            setshowCarrinho(false);
                                        }} className={`menuitemfont menuitemfontcor `}>
                                            <LocalGroceryStoreIcon style={{ width: '1.2rem' }} /> Carrinho
                                            <CSSTransition in={activeItem === 'carrinho'} timeout={300} classNames="line">
                                                <Line className="line" isActive={selectedItem === 'carrinho'} />
                                            </CSSTransition>
                                        </MenuItem>
                                    </MenuContainer>
                                </div>

                            </div>
                        </div>
                    }

                    {Mobile ?
                        <div className={`diventrargeralmobile `} style={{ transform: showDiv ? 'translateX(0%)' : 'translateX(110%)', transition: showDiv ? 'transform 0.7s ease-in-out' : 'transform 0.7s ease-in-out' }}>
                            {loading && <Loader />}
                            <div className='diventrarmobile'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => setShowDiv(false)}
                                />

                                <FormControl variant="standard" >
                                    <InputLabel htmlFor="login" style={{ color: primary }}>
                                        Digite seu CNPJ
                                    </InputLabel>
                                    <Input
                                        onChange={handleLoginChange}
                                        style={{ color: primary }}
                                        id="login"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl sx={{ mt: 2 }} variant="filled">
                                    <InputLabel htmlFor="senhalogin" style={{ color: primary }}>Senha</InputLabel>
                                    <FilledInput
                                        onKeyDown={handleKeyDown}
                                        onChange={handlePasswordChange}
                                        style={{ color: primary }}
                                        id="senhalogin"
                                        type={showPasswordLogin ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: primary }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordLogin}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordLogin ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <div >

                                    <Typography style={{ color: primary, fontSize: '0.7rem' }} className='my-3 text-center'>
                                        Ainda não possui cadastro?{' '}
                                        <Link href="#" color="secondary"
                                            onClick={() => {
                                                setshowCadastrar(true);
                                                setShowDiv(false);
                                            }}

                                        >
                                            Clique aqui
                                        </Link>{' '}
                                        e se cadastre!
                                    </Typography>

                                    <div className=' d-flex justify-content-evenly mt-3 align-items-center'>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{ color: 'white', border: `1px solid ${bege}` }}
                                            onClick={handleLogin}
                                            startIcon={<PersonIcon />}

                                        >
                                            Entrar
                                        </Button>

                                        <Typography style={{ color: primary, fontSize: '0.7rem' }}>
                                            <Link href="#" style={{ color: primary }}
                                                onClick={() => {
                                                    setshowEsqueceu(true);
                                                    setShowDiv(false);
                                                }}>
                                                Esqueceu sua senha?
                                            </Link>
                                        </Typography>
                                    </div>

                                </div>
                            </div>

                        </div>
                        :
                        <div className={`diventrargeral ${showDiv ? 'diventrargeral-shown' : 'diventrargeral-hidden'}`}>
                            {loading && <Loader />}
                            <div className='diventrar'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => setShowDiv(false)}
                                />
                                <div style={{ width: '50%' }}>
                                    <FormControl variant="standard" >
                                        <InputLabel htmlFor="login" style={{ color: primary }}>
                                            Digite seu CNPJ
                                        </InputLabel>
                                        <Input
                                            onChange={handleLoginChange}
                                            style={{ width: '15.5rem', color: primary }}
                                            id="login"
                                            startAdornment={
                                                <InputAdornment position="start" style={{ color: primary }}>
                                                    <AccountCircle />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <FormControl sx={{ mt: 2 }} variant="filled">
                                        <InputLabel htmlFor="senhalogin" style={{ color: primary }}>Senha</InputLabel>
                                        <FilledInput
                                            onKeyDown={handleKeyDown}
                                            onChange={handlePasswordChange}
                                            style={{ color: primary }}
                                            id="senhalogin"
                                            type={showPasswordLogin ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        style={{ color: primary }}
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPasswordLogin}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPasswordLogin ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div >
                                    <div style={{ height: '60%' }}>
                                        <Typography style={{ color: primary, fontSize: '0.7rem' }}>
                                            Ainda não possui cadastro?{' '}
                                            <Link href="#" color="secondary"
                                                onClick={() => {
                                                    setshowCadastrar(true);
                                                    setShowDiv(false);
                                                }}

                                            >
                                                Clique aqui
                                            </Link>{' '}
                                            e se cadastre!
                                        </Typography>
                                    </div>
                                    <div className=' d-flex justify-content-evenly'>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{ color: 'white', border: `1px solid ${bege}` }}
                                            onClick={handleLogin}
                                            startIcon={<PersonIcon />}

                                        >
                                            Entrar
                                        </Button>

                                        <Typography style={{ color: primary, fontSize: '0.7rem' }}>
                                            <Link href="#" style={{ color: primary }}
                                                onClick={() => {
                                                    setshowEsqueceu(true);
                                                    setShowDiv(false);
                                                }}>
                                                Esqueceu sua senha?
                                            </Link>
                                        </Typography>
                                    </div>

                                </div>
                            </div>

                        </div>
                    }

                    {Mobile ?
                        <div className={`divcadastrargeralmobile`}
                            style={{ transform: showCadastrar ? 'translateX(0%)' : 'translateX(110%)', transition: showCadastrar ? 'transform 0.7s ease-in-out' : 'transform 0.7s ease-in-out' }}
                        >
                            <div className='divcadastrarmobile'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => setshowCadastrar(false)}
                                />
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary, textAlign: 'center' }}>
                                    Faça seu cadastro!
                                </Typography>

                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="cnpjempresa" style={{ color: primary }}>
                                        CNPJ da empresa* (somente números)
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroCNPJ}
                                        id="cnpjempresa"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="nomeresp" style={{ color: primary }}>
                                        Nome do Responsável*
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroNome}
                                        id="nomeresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="cpfresp" style={{ color: primary }}>
                                        CPF do Responsável* (Somente números)
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroCPF}
                                        id="cpfresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="emailresp" style={{ color: primary }}>
                                        E-mail do Responsável*
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroEmail}
                                        id="emailresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <EmailIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl sx={{ mt: 2 }} variant="filled">
                                    <InputLabel htmlFor="cadsenha" style={{ color: primary }}>Senha</InputLabel>
                                    <FilledInput
                                        style={{ color: primary }}
                                        id="cadsenha"
                                        type={showPasswordCad ? 'text' : 'password'}
                                        onChange={handleCadastroSenha1}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: primary }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordCad}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordCad ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl sx={{ mt: 2 }} variant="filled">
                                    <InputLabel htmlFor="confcadsenha" style={{ color: primary }}>Confirme a senha</InputLabel>
                                    <FilledInput
                                        style={{ color: primary }}
                                        id="confcadsenha"
                                        type={showPasswordConf ? 'text' : 'password'}
                                        onChange={handleCadastroSenha2}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: primary }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordConf}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordConf ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <Button
                                    className=' my-3'
                                    variant="contained"
                                    onClick={handleCadastrar}
                                    size="small"
                                    style={{ color: 'white', border: `1px solid ${bege}`, width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
                                    startIcon={<PersonIcon />}
                                >
                                    Cadastrar
                                </Button>
                                <Typography style={{ color: primary, fontSize: '0.7rem', textAlign: 'center' }}>
                                    Já possui cadastro?{' '}
                                    <Link href="#" color="secondary"
                                        onClick={() => {
                                            setshowCadastrar(false);
                                            setShowDiv(true);
                                        }}

                                    >
                                        Clique aqui
                                    </Link>{' '}
                                    e faça login!
                                </Typography>
                            </div>
                        </div>
                        :
                        <div className={`divcadastrargeral ${showCadastrar ? 'divcadastrargeral-shown' : 'divcadastrargeral-hidden'}`}>
                            <div className='divcadastrar'>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => setshowCadastrar(false)}
                                />
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary, textAlign: 'center' }}>
                                    Faça seu cadastro!
                                </Typography>

                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="cnpjempresa" style={{ color: primary }}>
                                        CNPJ da empresa* (somente números)
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroCNPJ}
                                        id="cnpjempresa"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="nomeresp" style={{ color: primary }}>
                                        Nome do Responsável*
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroNome}
                                        id="nomeresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="cpfresp" style={{ color: primary }}>
                                        CPF do Responsável* (Somente números)
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroCPF}
                                        id="cpfresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <AccountCircle />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" className=' my-3'>
                                    <InputLabel htmlFor="emailresp" style={{ color: primary }}>
                                        E-mail do Responsável*
                                    </InputLabel>
                                    <Input
                                        style={{ color: primary }}
                                        onChange={handleCadastroEmail}
                                        id="emailresp"
                                        startAdornment={
                                            <InputAdornment position="start" style={{ color: primary }}>
                                                <EmailIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl sx={{ mt: 2 }} variant="filled">
                                    <InputLabel htmlFor="cadsenha" style={{ color: primary }}>Senha</InputLabel>
                                    <FilledInput
                                        style={{ color: primary }}
                                        id="cadsenha"
                                        type={showPasswordCad ? 'text' : 'password'}
                                        onChange={handleCadastroSenha1}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: primary }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordCad}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordCad ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl sx={{ mt: 2 }} variant="filled">
                                    <InputLabel htmlFor="confcadsenha" style={{ color: primary }}>Confirme a senha</InputLabel>
                                    <FilledInput
                                        style={{ color: primary }}
                                        id="confcadsenha"
                                        type={showPasswordConf ? 'text' : 'password'}
                                        onChange={handleCadastroSenha2}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ color: primary }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordConf}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordConf ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <Button
                                    className=' my-3'
                                    variant="contained"
                                    onClick={handleCadastrar}
                                    size="small"
                                    style={{ color: 'white', border: `1px solid ${bege}`, width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
                                    startIcon={<PersonIcon />}
                                >
                                    Cadastrar
                                </Button>
                                <Typography style={{ color: primary, fontSize: '0.7rem', textAlign: 'center' }}>
                                    Já possui cadastro?{' '}
                                    <Link href="#" color="secondary"
                                        onClick={() => {
                                            setshowCadastrar(false);
                                            setShowDiv(true);
                                        }}

                                    >
                                        Clique aqui
                                    </Link>{' '}
                                    e faça login!
                                </Typography>
                            </div>
                        </div>
                    }
                    <div className={`divesqueceugeral ${showEsqueceu ? 'divesqueceugeral-shown' : 'divesqueceugeral-hidden'}`}>
                        <div className={`loader ${LoaderRecuperaSenha ? 'd-flex' : 'd-none'}`}>
                            {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                            <img src={require('assets/loader.gif')} alt="loader" style={{ width: '20%' }} />
                        </div>
                        <div className='divesqueceu'>
                            <CancelRoundedIcon
                                className='btnclose'
                                style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                onClick={() => setshowEsqueceu(false)}
                            />
                            <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary, textAlign: 'center' }}>
                                Recupere sua senha!
                            </Typography>

                            <FormControl variant="standard" className=' my-3'>
                                <InputLabel htmlFor="cnpjrecuperar" style={{ color: primary }}>
                                    CNPJ da empresa* (somente números)
                                </InputLabel>
                                <Input
                                    style={{ color: primary }}
                                    id="cnpjrecuperar"
                                    onChange={handleRecuperaSenha}
                                    startAdornment={
                                        <InputAdornment position="start" style={{ color: primary }}>
                                            <AccountCircle />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <Button
                                className=' my-3'
                                variant="contained"
                                onClick={() => {

                                    swalWithBootstrapButtons.fire({
                                        title: 'Recuperar Senha',
                                        text: "Deseja enviar e-mail para recuperação de senha?",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Sim',
                                        cancelButtonText: 'Não',
                                        reverseButtons: true
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            handleRecuperaSenhaEmail();
                                        }
                                    })


                                }}
                                size="small"
                                style={{ color: 'white', border: `1px solid ${bege}`, width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
                                startIcon={<RestartAltRoundedIcon />}
                            >
                                Recuperar senha
                            </Button>
                        </div>
                    </div>
                    {Mobile ?
                        <div className={`divcarrinhogeralmobile ${showCarrinho ? 'divcarrinhogeral-shown' : 'divcarrinhogeral-hidden'}`} >
                            <div className={`loader ${loaderCarrinho ? 'd-flex' : 'd-none'}`}>
                                {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                                <img src={require('assets/loader.gif')} alt="loader" style={{ width: '20%' }} />
                            </div>
                            <div className='divcarrinhomobile' style={{ height: '80vh' }}>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => setshowCarrinho(false)}
                                />
                                <div className='d-flex flex-column justify-content-between' style={{ height: '100%' }}>
                                    <div style={{ height: '77%' }}>
                                        <div style={{ maxHeight: '20%' }}>

                                            <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary }} className='m-4'>
                                                Carrinho Troque e Ganhe
                                            </Typography>
                                        </div>
                                        <div style={{
                                            backgroundColor: 'white', width: '100%', maxHeight: '78%',
                                            overflow: 'overlay',
                                            marginLeft: 'auto', marginRight: 'auto'
                                        }} className='rounded-2'
                                        >
                                            {((carrinhoClient.carrinho.length > 0) && (carrinhoClient.carrinho[0].codbrinde !== null)) ? (
                                                <div>

                                                    {carrinhoClient.carrinho.map((carrinho) => (

                                                        <div style={{ boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)', position: 'relative' }} className=' m-3 rounded-2 p-2'>

                                                            <div>

                                                                <DeleteForeverIcon
                                                                    className='btndelete'
                                                                    style={{ position: 'absolute', top: 2, right: 3, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        swalWithBootstrapButtons.fire({
                                                                            title: 'Deletar item',
                                                                            text: "Deseja deletar o item?",
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonText: 'Sim',
                                                                            cancelButtonText: 'Não',
                                                                            reverseButtons: true
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                removerItem(carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                            }
                                                                        })

                                                                    }}
                                                                />

                                                            </div>
                                                            <div className=' d-flex justify-content-around align-items-center'>
                                                                <div style={{ width: '25%' }}>
                                                                    <img src={require(`assets/brindes/${carrinho.codbrinde}.jpg`)} alt="" style={{ width: '100%' }} />

                                                                </div>
                                                                <div style={{ width: '60%' }}>
                                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                                        {carrinho.brinde}

                                                                    </Typography>

                                                                </div>
                                                            </div>
                                                            <div className=' d-flex justify-content-between align-items-center'>

                                                                <div>
                                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                                        Quantidade: {carrinho.quantidade}&nbsp;&nbsp;
                                                                        <RemoveCircleIcon className='btnplusless'
                                                                            onClick={() => {
                                                                                if (Number(carrinho.quantidade) === 1) {
                                                                                    console.log(carrinho.quantidade);
                                                                                    swalWithBootstrapButtons.fire({
                                                                                        title: 'Deletar item',
                                                                                        text: "Deseja deletar o item?",
                                                                                        icon: 'warning',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText: 'Sim',
                                                                                        cancelButtonText: 'Não',
                                                                                        reverseButtons: true
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            removerItem(carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    alteraQtd((Number(carrinho.quantidade) - 1), carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                                    console.log(carrinho.quantidade);
                                                                                }

                                                                            }}
                                                                        />
                                                                        <AddCircleIcon className='btnplusless'
                                                                            onClick={() => {
                                                                                if (Number(pontos) < Number(Number(totaldepontos) + Number(carrinho.pontos))) {
                                                                                    Swal.fire(
                                                                                        'Pontos Insuficientes',
                                                                                        'Você não possui saldo o suficiente para trocar este brinde',
                                                                                        'error'
                                                                                    )
                                                                                } else {
                                                                                    alteraQtd((Number(carrinho.quantidade) + 1), carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                                }
                                                                            }}
                                                                        />

                                                                    </Typography>
                                                                </div>
                                                                <div>

                                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                                        {Number(carrinho.total_brinde).toLocaleString()} Pontos
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (

                                                <Typography style={{ color: primary, backgroundColor: bege }} className='p-4 m-3'>
                                                    Ops... Seu carrinho está vazio!
                                                </Typography>

                                            )}

                                        </div>
                                    </div>
                                    <div className={`${mostrarCarrinho ? 'd-flex' : 'd-none'} flex-column mx-3`} style={{ height: '23%' }}>
                                        <div className=' d-flex rounded-2 justify-content-between py-1 px-3' style={{ border: `1px solid ${primary}`, width: '100%' }}>
                                            <div>
                                                <Typography style={{ color: primary }}>
                                                    Total:&nbsp;
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography style={{ color: primary }}>
                                                    {carrinhoClient.carrinho.reduce((totalPontos, carrinho) => totalPontos + Number(carrinho.total_brinde), 0).toLocaleString()} Pontos
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className=' d-flex rounded-2 justify-content-between py-1 px-3 mt-2' style={{ border: `1px solid ${primary}`, width: '100%' }}>
                                            <div>
                                                <Typography style={{ color: primary }}>
                                                    Sobram:&nbsp;
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography style={{ color: primary }}>
                                                    {(Number(pontos) - totaldepontos).toLocaleString()} Pontos
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className=' d-flex justify-content-between'>
                                            <div>
                                                <Button
                                                    variant="outlined"
                                                    size="large"

                                                    style={{ color: 'red', border: `1px solid ${primary}`, fontSize: '0.78rem' }}
                                                    startIcon={<DeleteForeverIcon />}
                                                    fullWidth={true}
                                                    className='mt-2'
                                                    onClick={() => {
                                                        swalWithBootstrapButtons.fire({
                                                            title: 'Limpar Carrinho',
                                                            text: "Deseja deletar todos os itens do carrinho?",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Sim',
                                                            cancelButtonText: 'Não',
                                                            reverseButtons: true
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                handleLimparCarrinho(Number(carrinhoClient.carrinho[0].id_solicitacao));
                                                            }
                                                        })

                                                    }}
                                                >
                                                    Limpar Carrinho
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    variant="outlined"
                                                    size="large"
                                                    style={{ color: primary, border: `1px solid ${primary}`, fontSize: '0.8rem' }}
                                                    startIcon={<LocalGroceryStoreIcon />}
                                                    fullWidth={true}
                                                    onClick={() => {
                                                        handleItemClick('carrinho');
                                                        setshowCarrinho(false);
                                                    }}
                                                    className='mt-2'
                                                >
                                                    Finalizar Troca
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={`divcarrinhogeral ${showCarrinho ? 'divcarrinhogeral-shown' : 'divcarrinhogeral-hidden'}`} >
                            <div className={`loader ${loaderCarrinho ? 'd-flex' : 'd-none'}`}>
                                {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                                <img src={require('assets/loader.gif')} alt="loader" style={{ width: '20%' }} />
                            </div>
                            <div className='divcarrinho' style={{ height: '80vh' }}>
                                <CancelRoundedIcon
                                    className='btnclose'
                                    style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                    onClick={() => setshowCarrinho(false)}
                                />
                                <div className='d-flex flex-column justify-content-between' style={{ height: '100%' }}>
                                    <div style={{ height: '77%' }}>
                                        <div style={{ maxHeight: '20%' }}>

                                            <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: primary }} className='m-4'>
                                                Carrinho Troque e Ganhe
                                            </Typography>
                                        </div>
                                        <div style={{
                                            backgroundColor: 'white', width: '100%', maxHeight: '78%',
                                            overflow: 'overlay',
                                            marginLeft: 'auto', marginRight: 'auto'
                                        }} className='rounded-2'
                                        >
                                            {((carrinhoClient.carrinho.length > 0) && (carrinhoClient.carrinho[0].codbrinde !== null)) ? (
                                                <div>

                                                    {carrinhoClient.carrinho.map((carrinho) => (

                                                        <div style={{ boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)', position: 'relative' }} className=' m-3 rounded-2 p-2'>

                                                            <div>

                                                                <DeleteForeverIcon
                                                                    className='btndelete'
                                                                    style={{ position: 'absolute', top: 2, right: 3, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        swalWithBootstrapButtons.fire({
                                                                            title: 'Deletar item',
                                                                            text: "Deseja deletar o item?",
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonText: 'Sim',
                                                                            cancelButtonText: 'Não',
                                                                            reverseButtons: true
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                removerItem(carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                            }
                                                                        })

                                                                    }}
                                                                />

                                                            </div>
                                                            <div className=' d-flex justify-content-around align-items-center'>
                                                                <div style={{ width: '25%' }}>
                                                                    <img src={require(`assets/brindes/${carrinho.codbrinde}.jpg`)} alt="" style={{ width: '100%' }} />

                                                                </div>
                                                                <div style={{ width: '60%' }}>
                                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                                        {carrinho.brinde}

                                                                    </Typography>

                                                                </div>
                                                            </div>
                                                            <div className=' d-flex justify-content-between align-items-center'>

                                                                <div>
                                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                                        Quantidade: {carrinho.quantidade}&nbsp;&nbsp;
                                                                        <RemoveCircleIcon className='btnplusless'
                                                                            onClick={() => {
                                                                                if (Number(carrinho.quantidade) === 1) {
                                                                                    console.log(carrinho.quantidade);
                                                                                    swalWithBootstrapButtons.fire({
                                                                                        title: 'Deletar item',
                                                                                        text: "Deseja deletar o item?",
                                                                                        icon: 'warning',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText: 'Sim',
                                                                                        cancelButtonText: 'Não',
                                                                                        reverseButtons: true
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            removerItem(carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    alteraQtd((Number(carrinho.quantidade) - 1), carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                                    console.log(carrinho.quantidade);
                                                                                }

                                                                            }}
                                                                        />
                                                                        <AddCircleIcon className='btnplusless'
                                                                            onClick={() => {
                                                                                if (Number(pontos) < Number(Number(totaldepontos) + Number(carrinho.pontos))) {
                                                                                    Swal.fire(
                                                                                        'Pontos Insuficientes',
                                                                                        'Você não possui saldo o suficiente para trocar este brinde',
                                                                                        'error'
                                                                                    )
                                                                                } else {
                                                                                    alteraQtd((Number(carrinho.quantidade) + 1), carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                                }
                                                                            }}
                                                                        />

                                                                    </Typography>
                                                                </div>
                                                                <div>

                                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                                        {Number(carrinho.total_brinde).toLocaleString()} Pontos
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (

                                                <Typography style={{ color: primary, backgroundColor: bege }} className='p-4 m-3'>
                                                    Ops... Seu carrinho está vazio!
                                                </Typography>

                                            )}

                                        </div>
                                    </div>
                                    <div className={`${mostrarCarrinho ? 'd-flex' : 'd-none'} flex-column mx-3`} style={{ height: '23%' }}>
                                        <div className=' d-flex rounded-2 justify-content-between py-1 px-3' style={{ border: `1px solid ${primary}`, width: '100%' }}>
                                            <div>
                                                <Typography style={{ color: primary }}>
                                                    Total:&nbsp;
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography style={{ color: primary }}>
                                                    {carrinhoClient.carrinho.reduce((totalPontos, carrinho) => totalPontos + Number(carrinho.total_brinde), 0).toLocaleString()} Pontos
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className=' d-flex rounded-2 justify-content-between py-1 px-3 mt-2' style={{ border: `1px solid ${primary}`, width: '100%' }}>
                                            <div>
                                                <Typography style={{ color: primary }}>
                                                    Sobram:&nbsp;
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography style={{ color: primary }}>
                                                    {(Number(pontos) - totaldepontos).toLocaleString()} Pontos
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className=' d-flex justify-content-between'>
                                            <div>
                                                <Button
                                                    variant="outlined"
                                                    size="large"

                                                    style={{ color: 'red', border: `1px solid ${primary}`, fontSize: '0.78rem' }}
                                                    startIcon={<DeleteForeverIcon />}
                                                    fullWidth={true}
                                                    className='mt-2'
                                                    onClick={() => {
                                                        swalWithBootstrapButtons.fire({
                                                            title: 'Limpar Carrinho',
                                                            text: "Deseja deletar todos os itens do carrinho?",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Sim',
                                                            cancelButtonText: 'Não',
                                                            reverseButtons: true
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                handleLimparCarrinho(Number(carrinhoClient.carrinho[0].id_solicitacao));
                                                            }
                                                        })

                                                    }}
                                                >
                                                    Limpar Carrinho
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    variant="outlined"
                                                    size="large"
                                                    style={{ color: primary, border: `1px solid ${primary}`, fontSize: '0.8rem' }}
                                                    startIcon={<LocalGroceryStoreIcon />}
                                                    fullWidth={true}
                                                    onClick={() => {
                                                        handleItemClick('carrinho');
                                                        setshowCarrinho(false);
                                                    }}
                                                    className='mt-2'
                                                >
                                                    Finalizar Troca
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div style={{ overflow: Mobile ? 'overlay' : '', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: Mobile ? '90%' : '70%', height: Mobile ? '90%' : 'auto', boxShadow: '24', background: bege, padding: 20, borderRadius: '0.4rem' }}>
                            <CancelRoundedIcon
                                className='btnclose'
                                style={{ position: 'absolute', top: 2, right: 3, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                onClick={handleClose}
                            />
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: verdeescuro }}>
                                Regras da TV AgRoss
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2, color: verdeescuro }}>

                                1) Sorteio válido apenas para clientes conectados;<br></br><br></br>
                                2) O cliente deverá estar logado com o CNPJ para participar dos sorteios e preencher corretamente os campos de cadastro;<br></br><br></br>
                                3) Os prêmios a serem sorteados, serão sempre divulgados pela TV Agross;<br></br><br></br>
                                4) Apenas notas fiscais de vendas emitidas em um período definido antes da data do sorteio serão válidas. Em cada programa da TV Agross, o período exato de validade das notas será informado;<br></br><br></br>
                                5) Prêmio do Milhar: Será contemplado com o prêmio do milhar quem tiver os últimos 4 (quatro) números da Nota Fiscal Premiada;<br></br><br></br>
                                6) Prêmio da Centena: Será contemplado com o prêmio da centena quem tiver os últimos 3(três)números da Nota Fiscal Premiada;<br></br><br></br>
                                7) O cliente sorteado deverá CLICAR NO NÚMERO DA SUA NF “BOTÃO”, disponível na plataforma de transmissão;<br></br><br></br>
                                8) Se no sorteio houver mais de um ganhador, será realizado o sorteio da bola maior;<br></br><br></br>
                                9) Se o mesmo cliente ganhar os prêmios do Milhar e da Centena, será considerado apenas o prêmio do Milhar. Para validar, o cliente deve clicar novamente no número de sua NF;<br></br><br></br>
                                10) Se não houver ganhador, os prêmios ficam para o próximo programa;<br></br><br></br>
                                Prêmio Extra: Se a bola sorteada apresentar a inscrição “PRÊMIO EXTRA”, o primeiro cliente a clicar no botão “Prêmio Extra” será o vencedor. <br></br>
                                Atenção: Não clique no botão “Prêmio Extra” antes do sorteio correspondente, pois uma vez desativado, o botão não será reativado.<br></br><br></br>
                                As regras podem ser alteradas sem aviso prévio.
                            </Typography>
                        </div>
                    </Modal>
                    <Modal
                        open={opentg}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '70%', boxShadow: '24', background: bege, padding: 20, borderRadius: '0.4rem' }}>
                            <CancelRoundedIcon
                                className='btnclose'
                                style={{ position: 'absolute', top: 2, right: 3, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                onClick={handleClose}
                            />
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: verdeescuro }}>
                                Regulamento Troque & Ganhe
                            </Typography>
                            <div className=" p-2 d-flex flex-column mb-2">
                                <div style={{
                                    backgroundColor: 'transparent', width: '92%', maxHeight: '70vh', color: primary,
                                    overflow: 'overlay',
                                    marginLeft: 'auto', marginRight: 'auto'
                                }} className='rounded-2 my-2 p-2'
                                >
                                    <p className=" text-center"><b> REGULAMENTO DO PROGRAMA TROQUE E GANHE AGROSS.</b></p>
                                    <p><b>DO OBJETO DO PROGRAMA “TROQUE E GANHE”.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 1ª. )</b> A promoção AgRoss Troque e Ganhe é um programa de fidelidade que oferece pontos por cada compra realizada dos produtos participantes da promoção, que podem ser trocados por prêmios a escolha do cliente.</p>
                                    <p><b>DOS REQUISITOS PARA PARTICIPAR DA PROMOÇÃO.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 2ª.)</b>Para ser participante do programa, é necessário que seja observado os seguintes requisitos:</p>
                                    <p ><b>a)</b> Realizar compras dos produtos participantes da promoção da AgRoss;</p>
                                    <p><b>b)</b> Não estar inadimplente com a AgRoss;</p>
                                    <p><b>c)</b> Não ter a “empresa cliente” encerrado suas atividades.</p>
                                    <p style={{ textAlign: 'justify' }}><b>d)</b> Ser um cliente ativo, ou seja, realizando pelo menos uma compra a cada 90 (noventa) dias, com valor mínimo de R$ 10.000,00 (dez mil reais).</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 3ª.)</b> Caso o cliente perca qualquer um destes requisitos, perderá o direito de participar do programa. A tolerância de descumprimento de quaisquer cláusulas ou condições do presente regulamento deverá ser entendida como mera liberalidade, jamais produzindo a perda do direito de exigir o cumprimento de todos os requisitos previstos no presente. </p>
                                    <p><b>DA PONTUAÇÃO.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 4ª.)</b> A proporção dos pontos será distribuída da seguinte forma:</p>
                                    <p><b>a)</b> 2 pontos para cada 1 (um) real em produtos comprados da série Normal;</p>
                                    <p><b>b)</b> 6 pontos para cada 1 (um) real em produtos comprados da série Ouro;</p>
                                    <p><b>c)</b> 12 pontos para cada 1 (um) real em produtos comprados da série Diamante.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 5ª.)</b> Sendo que a descrição dos produtos que compõem cada uma das séries, será sempre apresentada e atualizados frequentemente via site, e os mesmos poderão sofrer alterações a qualquer momento.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 6ª.)</b>   Os pontos conquistados pelos clientes estarão disponíveis para troca, somente após o pagamento de todas as parcelas da(s) Nota(s) fiscal(is) que gerou a pontuação.</p>
                                    <p style={{ textAlign: 'justify' }}><b>a)</b> Caso haja devolução de quaisquer mercadorias adquiridas participantes do programa, o cliente AgRoss perderá os pontos referente a essa devolução, sendo estes pontos debitados do seu saldo adquirido no programa.</p>
                                    <p><b>DO ACUMULO DOS PONTOS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 7ª.)</b> O cliente poderá acumular seus pontos até atingir a pontuação necessária, para trocar pelos prêmios escolhidos. Para que o cliente tenha o controle da sua pontuação, no momento da entrega da mercadoria irá receber um extrato de pontos, já inclusos os pontos da entrega realizada, sendo que estes pontos somente estarão disponíveis para utilização, após o pagamento das parcelas correspondentes da(s) nota(s) fiscais(s).</p>
                                    <p style={{ textAlign: 'justify' }}>Poderá ainda, consultar seus pontos pelo site da promoção, www.troqueeganhe.com.br, mediante cadastro com login e senha previamente definidos, ou ainda poderá utilizar o e-agross.</p>
                                    <p style={{ textAlign: 'justify' }}><b>a)</b> Os pontos serão acumulados, por CNPJ, ou seja, não poderá haver a somatória de pontos entre clientes distintos.</p>
                                    <p style={{ textAlign: 'justify' }}><b>b)</b> A única possibilidade de acumulo de pontos será entre matriz e filiais, sendo que para que ocorra o cliente deverá solicitar via e-mail (troqueeganhe@agross.com.br). A unificação ocorrerá somente para aquele pedido. Dessa forma, toda vez que optar pela juntada dos pontos, o cliente deverá realizar a solicitação para a AgRoss. Considerando ainda, que só é possível juntar os pontos "Disponíveis" ,os pontos "bloqueados" ficam retidos até a quitação das notas fiscais que geraram os mesmos.</p>
                                    <p style={{ textAlign: 'justify' }}><b>c)</b> Quando houver a troca de edição da REVISTA, o cliente que tiver pontos da edição anterior, poderá utilizá-los, mas seguindo a nova pontuação.</p>
                                    <p><b>DOS PRÊMIOS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 8ª.)</b> Os prêmios que são apresentados na revista, no site estão representados por meio de imagens ilustrativas, sendo assim no momento do recebimento, pode ocorrer variações quanto a cores, modelos, marcas e tamanhos, conforme a disponibilidade do mercado.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 9ª.)</b> Caso algum dos prêmios apresentados na revista deixe de ser fabricado ou não seja mais encontrado no mercado, o Cliente poderá utilizar os seus pontos para escolher outro prêmio de igual pontuação.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo Único –</b> Os produtos e viagens participantes da promoção são específicos, não podendo ser solicitados produtos fora da campanha, (Revista TG, site TG).</p>
                                    <p><b>DA DURAÇÃO.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 10ª.)</b> O programa não tem data de validade determinada. Mas está vinculado as condições que poderão ser alteradas diretamente no site, a qualquer momento, são elas: os produtos participantes do programa, os prêmios oferecidos e a pontuação necessária para cada item. No caso de encerramento por definitivo do programa, os pontos já acumulados não serão perdidos, desde que o cliente esteja enquadrado nos requisitos da cláusula 2ª. E que a troca dos pontos acumulados, seja realizada dentro do prazo estipulado pela AgRoss. Caso não seja observado o prazo indicado, o cliente perderá a pontuação acumulada, não tendo direito a reclamações posteriores.</p>
                                    <p><b>DA TROCA DA PONTUAÇÃO PELO PRÊMIO.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 11ª.)</b> Após alcançada a pontuação necessária para troca de prêmios, o cliente deverá solicitar a troca via site, seguindo todo o procedimento indicado para finalizar a troca e inclusive aceitando os termos desse regulamento.</p>
                                    <p><b>DA ENTREGA DOS PRÊMIOS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 12ª.)</b> O prazo para ser entregue o prêmio será, em média de 30 dias, podendo sofrer variações de acordo com o produto solicitado e a disponibilidade do produto no mercado. Este prazo se inicia após o cliente ter concluído o procedimento estabelecido na cláusula 10ª.</p>
                                    <p style={{ textAlign: 'justify' }}><b>a)</b>      Reforçamos ainda, que as entregas não dependem exclusivamente da AgRoss, desta forma, o prazo de entrega poderá variar próximo a datas comemorativas, como natal, feriados e etc, conforme a disponibilidade das empresas de entregas. Portanto os pedidos devem ser realizados antecipadamente neste período.</p>
                                    <p style={{ textAlign: 'justify' }}><b>b)</b>      O endereço da entrega deverá ser o endereço comercial do “Cliente Agross”, caso esta regra não seja respeitada, a Agross se eximi de qualquer responsabilidade quanto a problemas de entrega e não irá arcar com nenhum tipo de indenização, inclusive se houver extravio destes prêmios.</p>
                                    <p style={{ textAlign: 'justify' }}><b>c)</b>       O prêmio deverá ser conferido no momento da entrega, sendo que a AgRoss só aceitará reclamações de desconformidade, no prazo de 7 (sete) dias, não se responsabilizando por quaisquer danos ou avarias que ocorrerem, após este prazo e nem por defeitos que surgirem, devendo o “Cliente AgRoss” premiado seguir as instruções de uso do fabricante do produto para garantir a qualidade de seu funcionamento.</p>
                                    <p><b>DA SEGURANÇA.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 13ª.)</b> Para que a troca de pontos por prêmios, ocorra de uma forma segura, existirá apenas uma forma de realizar essa troca, que será da seguinte maneira:</p>
                                    <p style={{ textAlign: 'justify' }}><b>a)</b> O “CLIENTE AGROSS” precisa se cadastrar diretamente no site Troque e Ganhe www.troqueeganhe.com.br e criar sua senha, portanto a AgRoss não obterá acesso a esta senha, sendo de inteira responsabilidade do representante legal do cliente o uso desta senha que é pessoal e intransferível.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo primeiro: </b>Caso o cliente informe a sua senha para terceiros e o mesmo solicite prêmios. A AgRoss não se responsabilizará e não concederá novamente os pontos perdidos.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo segundo:</b> No caso de perda da senha para realizar o login no site, o cliente deverá marcar a opção esqueci minha senha e a mesma será enviada para o e-mail constante no cadastro realizado.</p>
                                    <p><b>DAS DISPOSIÇÕES GERAIS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 14ª.)</b> Qualquer eventualidade ou fato omisso que não se enquadre nesse regulamento, ficará sobre a apreciação e julgamento da AgRoss ,devendo ser acatada a decisão em caráter inquestionável por todos os participantes.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 15ª.)</b> Todas as imagens dos produtos e viagens são meramente ilustrativas, podendo sofrer alterações de marca, modelo, cor, configurações entre outros, sendo a escolha do produto realizada pela AgRoss e pela disponibilidade do fornecedor.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 16ª.)</b> O programa AgRoss Troque e Ganhe, é uma campanha que tem o único objetivo de premiar nossos clientes mediante a troca de pontos pelo prêmio específico, podendo ser cancelada ou prorrogada a qualquer momento pela AgRoss, conforme este regulamento.</p>
                                    <p><b>ANEXO 1. DO REGULAMENTO DO PROGRAMA TROQUE E GANHE.</b></p>
                                    <p><b>DAS VIAGENS DISPONIBILIZADAS COMO PRÊMIO PELA AGROSS:</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 1ª.)</b> As viagens que a AgRoss está disponibilizando para o Programa TROQUE E GANHE, são apenas os pacotes disponíveis na revista, no site e a pontuação apresentada é para 2 (DUAS pessoas) em apartamento duplo, sujeitas a disponibilidade de datas e vagas.</p>
                                    <p><b>DA RESPONSABILIDADE DA AGROSS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 2ª.)</b> A única responsabilidade da AgRoss mediante a solicitação do cliente para a troca dos seus pontos acumulados no programa Troque e Ganhe é contratar a empresa que irá oferecer o serviço de acordo com viagem escolhida por nosso cliente</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo primeiro:</b> A AgRoss não tem nenhuma responsabilidade sobre qualquer tipo de acidente ou incidente, tais como: doenças, roubo, perda de objetos, falta de transporte do passageiro, perca de voos. Em HIPÓTESE ALGUMA, a AgRoss se responsabilizará por qualquer tipo de indenização sobre acidentes e incidentes ocorridos após a contratação da viagem</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo segundo:</b> Após, o cliente ter realizada a opção de troca de seus pontos por viagem, a AgRoss não reembolsará os pontos, caso a viagem não ocorra, sendo assim e de inteira responsabilidade dos passageiros a verificação de todos os itens obrigatórios para a realização da viagem, tais como: documentos dentro do prazo de validade, vacinas necessárias, horário do voo, bem como quaisquer outras exigências necessárias para a realização da Viagem.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo terceiro:</b> A AgRoss se compromete a contratar empresas de viagens, que prestem serviço com idoneidade reconhecida no mercado, mas caso uma dessas empresas não cumpra com suas obrigações, a responsabilidade pelo serviço contratado e não prestado devidamente será de total responsabilidade da empresa de viagens contratada.</p>
                                    <p><b>DO PERÍODO DE VIAGEM.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 3ª.)</b> Não é permitido que o cliente estenda o tempo da viagem, mesmo que ele pague com seus recursos os dias excedentes.</p>
                                    <p><b>DO ROTEIRO DA VIAGEM.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 4ª.)</b> A AgRoss se reserva o direito de cancelar, seja qual for a viagem, do Programa Troque e Ganhe, desde que a empresas de viagens não ofereçam mais o roteiro escolhido ou por motivo de força maior, conflitos, impossibilidade de viagem para o lugar escolhido por falta de documentação específica, cancelamento de voos para a região ou qualquer outra questão que a AgRoss acredite que possa oferecer RISCO EM SUA IMAGEM INSTITUCIONAL ou ao programa Troque e Ganhe. Caso algum cliente, tenha solicitado a troca para o destino que saiu do programa de pontos, será informado previamente para que escolha um novo destino dentro das opções válidas.</p>
                                    <p><b>DO PRAZO PARA A SOLICITAÇÃO DA VIAGEM.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 5ª.)</b> A data da viagem será de acordo com a disponibilidade de vagas na empresa turística e a solicitação da viagem pelo cliente deverá cumprir o prazo de 60 dias de antecedência no mínimo. Ressalvado os períodos de alta temporada, em que o prazo mínimo para solicitar a viagem deverá ser com 180 dias de antecedência.</p>
                                    <p><b>DAS CONDIÇÕES GERAIS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 6ª.)</b> Todos os pacotes de viagens oferecidas no catálogo AgRoss Troque e Ganhe, terão suas especificações definidas no mesmo, não podendo ser alteradas, apenas alguns destinos contarão com a opção de resort, os quais serão devidamente indicados, seja na revista Troque e Ganhe, no site ou no e-AgRoss. Os destinos que não constarem em sua descrição a especificação de Resort serão de categoria Turística, em apartamentos standard duplo. Não haverá exceções para a troca de categoria.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo único:</b> A pontuação apresentada na revista se refere apenas a uma média dos pontos que serão necessárias para a viagem apresentada, podendo sofrer variações para mais ou menos pontos, dependendo das opções escolhidas pelo cliente, que poderão variar quanto ao período da viagem, escolha do hotel, temporada, opcionais e outras. Desta forma, o cliente deverá passar suas opções para que o departamento de Marketing da AgRoss possa contabilizar os pontos necessários.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 7ª.)</b> A AgRoss não oferece qualquer quantia em dinheiro, transportes EXTRAS ou ingressos para shows, festas, boates, bares e outros, sendo repassado ao cliente apenas a viagem escolhida com suas respectivas datas, roteiro, translado e hospedagem.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo único:</b> A AgRoss não se responsabiliza ainda, por quaisquer tipos de taxas ou encargos, inclusive seguro viagem, bagagens, marcação de assento, taxas ambientais ou de turismo entre outras.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 8ª.)</b> Exclusivamente para viagens, antes de realizar o procedimento de troca, o “Cliente Agross” deverá entrar em contato com o departamento de marketing, para receber as informações e opções sobre a viagem escolhida, e só após ter concordado com as especificações passadas, que deverá realizar o procedimento de troca, conforme cláusula 10°deste regulamento.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 9ª.)</b> Caso haja cancelamento ou alteração da data de viagem motivada por questões de saúde pública, tais como a pandemia do COVID19, a AgRoss não se responsabiliza por nenhum tipo de indenização, mas se compromete a encontrar a melhor solução para o cliente AgRoss, para que o mesmo não seja prejudicado.</p>
                                </div>

                            </div>
                        </div>
                    </Modal>
                </Toolbar>
            </AppBar>
            <div className=' rounded-circle d-flex align-items-center position-fixed'
                style={{
                    backgroundColor: primary,
                    width: '3rem', height: '3rem',
                    color: 'white',
                    bottom: '7rem', right: isScrolled ? '1.4rem' : '-4rem',
                    transition: '0.5s ease-in-out',
                    cursor: 'pointer',
                    zIndex: 999,
                    opacity: 0.7

                }}
                onClick={() => window.scrollTo(0, 0)}
            >
                <KeyboardDoubleArrowUpIcon style={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </div>
            <div className='d-flex align-items-center position-fixed'
                style={{
                    width: '5rem', height: '5rem',
                    bottom: '2rem', right: '0.3rem',
                    transition: '0.5s ease-in-out',
                    cursor: 'pointer',
                    zIndex: 999,


                }}
                onClick={() => {
                    navigate('/contato');
                    window.scrollTo(0, 0);
                }}
            >
                <img src={require('assets/gifwhatsapp.gif')} alt="" style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }} />
            </div>
        </div >

    )
}