import React, { useCallback, useEffect, useState } from 'react';
import { TextField, Button, Modal, Typography, Box, Link, CardContent, CardActionArea, Grid, FormControl, InputLabel, Input, InputAdornment } from '@mui/material';

import { Card } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import './PortfolioComponent.css'
import { Fornecedores } from '../fornecedores/Fornecedores';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SearchIcon from '@mui/icons-material/Search';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import useIsMobile from 'app/shared/hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';



const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

interface Product {
    Produto: string;
    DESCRICAO: string | null;
    LINK_PRODUTO: string | null;
    LINK_FICHA: string | null;
    Marca: string;
    Linha: string;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: `2px solid ${verdeescuro}`,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



export const PortfolioComponent: React.FC = () => {

    const [data, setData] = useState<Product[]>([]);
    const [filteredData, setFilteredData] = useState<Product[]>([]);
    const [search, setSearch] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [currentProduct, setCurrentProduct] = useState<Product | null>(null);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const Mobile = useIsMobile();
    const navigate = useNavigate();


    const handleItemClick = useCallback((item: string) => {
        setSelectedItem(item);
    }, []);

    useEffect(() => {
        fetch('https://agross.com.br/wp-content/uploads/2020/10/PORTFOLIO.json')
            .then(response => response.json())
            .then(data => {
                setData(data[0]);
                setFilteredData(data[0]);
            });
    }, []);

    useEffect(() => {
        setFilteredData(data.filter(product =>
            product.Produto.toLowerCase().includes(search.toLowerCase()) ||
            product.Marca.toLowerCase().includes(search.toLowerCase())
        ));
    }, [search, data]);

    const handleOpen = (product: Product) => {
        setCurrentProduct(product);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const filterByType = (type: string) => {
        setFilteredData(data.filter(product => product.Linha === type));
    };

    const resetFilter = () => {
        setFilteredData(data);
    };

    return (

        <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} className=' my-5'>
            <div className=" d-flex flex-column">
                {Mobile ?
                    <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Produtos Marca AgRoss
                    </Typography>
                    :
                    <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: verdeescuro, textAlign: 'center' }}>
                        Produtos Marca AgRoss
                    </Typography>
                }
                <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </div>

            {Mobile ?
                <div className=' d-flex flex-column mb-5 mt-3'>
                    <div className='divaminoagross p-4 my-3'>
                        <div className=' position-absolute' style={{ bottom: '3rem' }} >
                            <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: 'white' }}>
                                AminoAgRoss Fertilizante Foliar
                            </Typography>
                            <Typography variant="body1" style={{ color: 'white' }} className='my-5 descricaoprodutos'>
                                É um fertilizante foliar organomineral com macro e micronutrientes.
                            </Typography>
                            <Button variant="contained" size='large' color="primary" className='descricaoprodutos'
                                onClick={() => {
                                    navigate('/aminoagross');
                                    window.scrollTo(0, 0);
                                }}
                            >
                                Leia mais
                            </Button>
                        </div>
                    </div>
                    <div className='divsemente1051 p-4 my-3'>
                        <div className=' position-absolute' style={{ bottom: '3rem' }}>
                            <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: 'white' }}>
                                Semente Milho Verde Híbrido AG1051
                            </Typography>
                            <Typography variant="body1" style={{ color: 'white' }} className='my-5 descricaoprodutos'>
                                Atendendo a demanda de mercado por uma semente de Milho Verde.
                            </Typography>
                            <Button variant="contained" size='large' color="primary" href="https://agross.com.br/project/semente-milho/" className='descricaoprodutos'>
                                Leia mais
                            </Button>
                        </div>
                    </div>
                </div>
                :
                <div className=' d-flex justify-content-around mb-5 mt-3'>
                    <div className='divaminoagross p-5 mx-2'
                        onClick={() => {
                            navigate('/aminoagross');
                            window.scrollTo(0, 0);
                        }}>
                        <div className=' position-absolute' style={{ bottom: '3rem' }}>
                            <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: 'white' }}>
                                AminoAgRoss Fertilizante Foliar
                            </Typography>
                            <Typography variant="body1" style={{ color: 'white' }} className='my-5 descricaoprodutos'>
                                É um fertilizante foliar organomineral com macro e micronutrientes.
                            </Typography>
                            <Button variant="contained" size='large' color="primary" className='descricaoprodutos'
                                onClick={() => {
                                    navigate('/aminoagross');
                                    window.scrollTo(0, 0);
                                }}
                            >
                                Leia mais
                            </Button>
                        </div>
                    </div>
                    <div className='divsemente1051 p-5 mx-2'
                        onClick={() => {
                            navigate('/sementemilho');
                            window.scrollTo(0, 0);
                        }}>
                        <div className=' position-absolute' style={{ bottom: '3rem' }}>
                            <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: 'white' }}>
                                Semente Milho Verde Híbrido AG1051
                            </Typography>
                            <Typography variant="body1" style={{ color: 'white' }} className='my-5 descricaoprodutos'>
                                Atendendo a demanda de mercado por uma semente de Milho Verde.
                            </Typography>
                            <Button variant="contained" size='large' color="primary" href="https://agross.com.br/project/semente-milho/" className='descricaoprodutos'>
                                Leia mais
                            </Button>
                        </div>
                    </div>
                </div>
            }

            <div className=" d-flex flex-column my-5">
                <Typography fontFamily={'Arima Madurai'} variant={Mobile ? "h4" : "h3"} style={{ color: verdeescuro, textAlign: 'center' }}>
                    Todos os produtos do Portfólio AgRoss
                </Typography>
                <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                <Typography variant="body1" style={{ textAlign: 'center', color: primary, width: `${Mobile ? '100%' : '60%'}`, marginLeft: 'auto', marginRight: 'auto' }} >
                    A AgRoss se preocupa com a assertividade das compras realizadas pelos nossos clientes.
                    Pesquise os produtos que melhor atenderá sua necessidade.
                </Typography>
            </div>


            {Mobile ?
                <Box sx={{ display: 'flex', p: 2, backgroundColor: bege }} className=' rounded-2 flex-column'>

                    <FormControl variant="standard">
                        <InputLabel>Pesquisar Produtos</InputLabel>
                        <Input value={search} onChange={(e) => setSearch(e.target.value)}
                            startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                        />
                    </FormControl>
                    <div className=' d-flex align-items-center mt-3 flex-column'>
                        <div className=' d-flex justify-content-center w-100'>
                            <Button style={{ marginLeft: '1rem' }} variant='outlined' onClick={() => filterByType('Defensivo')}>Defensivos</Button>
                            <Button style={{ marginLeft: '1rem' }} variant='outlined' onClick={() => filterByType('Sementes')}>Sementes</Button>
                        </div>
                        <div className=' d-flex justify-content-center w-100 mt-3'>
                            <Button style={{ marginLeft: '1rem' }} variant='outlined' onClick={() => filterByType('Pulverizadores')}>Pulverizadores</Button>
                            <Button style={{ marginLeft: '1rem' }} variant='outlined' onClick={resetFilter}>Todos</Button>
                        </div>
                    </div>
                </Box>
                :
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, backgroundColor: bege }} className=' rounded-2'>

                    <FormControl variant="standard">
                        <InputLabel>Pesquisar Produtos</InputLabel>
                        <Input value={search} onChange={(e) => setSearch(e.target.value)}
                            startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                        />
                    </FormControl>
                    <div className=' d-flex align-items-center'>
                        <Button style={{ marginLeft: '1rem' }} variant='outlined' onClick={() => filterByType('Defensivo')}>Defensivos</Button>
                        <Button style={{ marginLeft: '1rem' }} variant='outlined' onClick={() => filterByType('Sementes')}>Sementes</Button>
                        <Button style={{ marginLeft: '1rem' }} variant='outlined' onClick={() => filterByType('Pulverizadores')}>Pulverizadores</Button>
                        <Button style={{ marginLeft: '1rem' }} variant='outlined' onClick={resetFilter}>Todos</Button>
                    </div>
                </Box>
            }



            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: Mobile ? 2 : 4, sm: 8, md: 16 }} style={{ height: '70vh', overflow: 'auto' }} className='my-3'>
                {filteredData.map((product, index) => (
                    <Grid item xs={2} sm={4} md={4}>
                        <Card sx={{ maxWidth: 345 }} className='m-2' style={{ backgroundColor: `${selectedItem === product.Produto ? '#f0f0f0' : 'white'}` }}>
                            <CardActionArea key={index} style={{ height: '10rem' }} >
                                <CardContent style={{ textAlign: 'center' }} className=' d-flex h-100 align-items-center' >
                                    <div style={{ width: '75%' }} className=' d-flex align-items-center h-100' onClick={() => { handleOpen(product); handleItemClick(product.Produto) }}>
                                        <div>
                                            <Typography variant="body1" color="text.secondary">
                                                {product.Produto}
                                            </Typography>
                                            <div className=' d-flex mt-3'>
                                                <img src={(`../marcas/${product.Marca}.png`)} alt="" style={{ width: '40%', marginLeft: 'auto', marginRight: 'auto' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '25%' }} onClick={() => handleItemClick(product.Produto)}>
                                        <div>
                                            <Typography color="text.secondary" style={{ fontSize: '0.6rem' }}>
                                                PÁGINA DO PRODUTO
                                            </Typography>
                                            {product.LINK_PRODUTO &&
                                                <Link href={product.LINK_PRODUTO} target="_blank">
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ color: primary, border: `1px solid ${primary}` }}
                                                    >
                                                        <LanguageOutlinedIcon />
                                                    </Button>
                                                </Link>
                                            }
                                        </div>
                                        <div>
                                            <Typography color="text.secondary" style={{ fontSize: '0.6rem', marginTop: '1rem' }}>
                                                BULA
                                            </Typography>
                                            {product.LINK_FICHA &&
                                                <Link href={product.LINK_FICHA} target="_blank">
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ color: primary, border: `1px solid ${primary}` }}
                                                    >
                                                        <ReceiptOutlinedIcon />
                                                    </Button>
                                                </Link>
                                            }

                                        </div>

                                    </div>

                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Modal open={open} onClose={handleClose}>

                <Box sx={{ ...style, width: `${Mobile ? '80%' : '30%'}`, borderRadius: '0.5rem' }}>
                    {currentProduct && (
                        <>
                            <CancelRoundedIcon
                                className='btnclose'
                                style={{ position: 'absolute', top: 2, right: 2, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                onClick={handleClose}
                            />
                            <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: verdeescuro, textAlign: 'center' }}>
                                {currentProduct.Produto}
                            </Typography>
                            <Typography style={{ color: primary, textAlign: 'justify' }}>{currentProduct.DESCRICAO}</Typography>
                            <div className=' d-flex mt-4 flex-column'>

                                {currentProduct.LINK_PRODUTO && <Link target='_blank' href={currentProduct.LINK_PRODUTO}><Button
                                    variant="outlined"
                                    size="small"
                                    style={{ color: primary, border: `1px solid ${primary}` }}
                                >
                                    Página do Produto
                                </Button></Link>}
                                {currentProduct.LINK_FICHA && <Link target='_blank' href={currentProduct.LINK_FICHA}><Button
                                    variant="outlined"
                                    size="small"
                                    style={{ color: primary, border: `1px solid ${primary}`, marginTop: '1rem' }}
                                >
                                    Bula
                                </Button></Link>}


                            </div>
                            <img src={require('assets/logoagross.png')} style={{ width: '25%', position: 'absolute', bottom: '0.1rem', right: '0.1rem' }} alt='logoagross' />
                        </>
                    )}
                </Box>
            </Modal>

            <div style={{ color: primary }} className="my-5 py-5 px-5">
                <div className={`text-lg-center ${Mobile ? 'd-flex flex-column' : ''}`} >
                    <Typography variant={Mobile ? "body2" : "body1"} style={{ textAlign: 'center' }}>
                        NOSSOS PRINCIPAIS
                    </Typography>
                    <Typography fontFamily={'Arima Madurai'} variant={Mobile ? "h4" : "h3"} style={{ color: verdeescuro }} className="mt-2 text-center">
                        Fornecedores
                    </Typography>
                    <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    <Fornecedores />
                </div>
            </div>
        </div>
    );
};

