import Swal from "sweetalert2";
import { Box, Button, Card, CardActionArea, CardContent, Grid, Link, MenuItem, TextField, Typography, buttonClasses } from "@mui/material"
import { lightGreen } from '@mui/material/colors';
import { CartContext } from "app/shared/services/CartContext";
import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import AuthService from 'app/shared/services/AuthService';
import { UserLogged } from "app/shared/contexts";
import { useNavigate } from 'react-router-dom';
import './Brindes.css'
import TokenContext from "app/shared/services/token/TokenContext";
import { useCarrinho } from "app/shared/services/cart/CarrinhoContext";
import { ShowDivContext } from "app/shared/contexts/ShowDivContext/ShowDivContext";
import useIsMobile from "app/shared/hooks/useIsMobile";
const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';



interface Brindestg {
    id_brinde: number;
    brinde: string;
    id_categoria: number;
    pontos: number;
    img_brinde: string;
    ativo: string;
    destaque: string;
    voltagem: string;
}

const currencies = [
    {
        value: 999,
        label: 'Todos',
    },
    {
        value: 5,
        label: 'Acessórios Automotivos',
    },
    {
        value: 2,
        label: 'Bebês',
    },
    {
        value: 3,
        label: 'Brinquedos',
    },
    {
        value: 4,
        label: 'Camping E Treking',
    },
    {
        value: 22,
        label: 'Casa Inteligente',
    },
    {
        value: 7,
        label: 'Eletrodomésticos',
    },
    {
        value: 9,
        label: 'Eletroportáteis',
    },
    {
        value: 8,
        label: 'Eletrônicos, Escritório E Tecnologia',
    },
    {
        value: 10,
        label: 'Esportes E Lazer',
    },
    {
        value: 12,
        label: 'Ferramentas E Máquinas',
    },
    {
        value: 14,
        label: 'Games',
    },
    {
        value: 18,
        label: 'Locomoção',
    },
    {
        value: 15,
        label: 'Piscina E Verão',
    },
    {
        value: 16,
        label: 'Saúde E Beleza',
    },
    {
        value: 20,
        label: 'Utensílios De Cozinha',
    },
    {
        value: 17,
        label: 'Viagens E Turismo',
    },

];

interface BrindesProps {
    authService: AuthService;
}

interface CartItem {
    id_pedido: number;
    id_brinde: number;
    quantidade: number;
    pontos: number;
    imagem: string;
    brinde: string;
    voltagem: string | null;
    observacao_item: string | null;
}
export const Brindes = (props: BrindesProps) => {

    const [filteredData, setFilteredData] = useState<Brindestg[]>([]);
    const [Data, setData] = useState<Brindestg[]>([]);
    const token = React.useContext(TokenContext);
    const [search, setSearch] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const { userLogged, setUserLogged } = useContext(UserLogged);
    const { showDiv, setShowDiv } = useContext(ShowDivContext);
    const Mobile = useIsMobile();
    const { dispatch } = useContext(CartContext);
    const navigate = useNavigate();
    const addToCart = (item: CartItem) => {
        dispatch({ type: 'ADD_ITEM', payload: item });
    };
    const { state } = useContext(CartContext);

    const { pontosCliente, setPontosCliente } = useContext(CartContext);
    const { fetchCarrinho, pedido, setLoaderCarrinho } = useCarrinho();
    const carrinhoClient = useCarrinho();
    const totaldepontos = carrinhoClient.carrinho.reduce((totalPontos, carrinho) => totalPontos + Number(carrinho.total_brinde), 0);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get('https://data-api.agross.com.br/api/v1/brindes', {
                    headers: {
                        Authorization: `Bearer ${token}`,

                    },
                });
                setFilteredData(response.data.brindes);
                setData(response.data.brindes);
                setIsLoading(false);




            } catch (error) {
                console.error('Erro ao buscar os dados', error);
            }
        };

        fetchData();







    }, [props.authService]);


    const filterByType = (id: number) => {
        if (id === 999) {
            setFilteredData(Data);
        } else {
            setFilteredData(Data.filter(product => Number(product.id_categoria) === id));
        }
    };
    useEffect(() => {
        setFilteredData(Data.filter(product =>
            product.brinde.toLowerCase().includes(search.toLowerCase())
        ));
    }, [search, Data]);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mx-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const handleAdicionarItem = (id_brinde: Number) => {
        const cnpj = localStorage.getItem('cliente_cnpj')
        console.log(pedido);

        if (pedido !== '') {
            const CarrinhoCliente = {
                "carrinho": {
                    "id_pedido": pedido,
                    "cnpj": cnpj,
                    "itens": [
                        {
                            "id_pedido": pedido,
                            "id_brinde": id_brinde,
                            "quantidade": 1
                        }
                    ]
                }
            }

            const fetchData = async () => {
                try {
                    const encodedData = encodeURIComponent(JSON.stringify(CarrinhoCliente));
                    const response = await axios.post(`https://data-api.agross.com.br/api/v1/carrinho/?carrinho=${encodedData}`, {}, {
                        headers: {
                            Authorization: `Bearer ${token}`,

                        },
                    });

                    console.log(response);
                    if (response.status === 200) {
                        setLoaderCarrinho(true);
                        fetchCarrinho();
                    }


                } catch (error) {
                    console.error('Erro ao alterar quantidade', error);
                }
            };

            fetchData();
        } else {
            console.log('pedido: ', pedido);
            const fetchData = async () => {
                try {
                    const responsepedido = await axios.get('https://data-api.agross.com.br/api/v1/pedidonext', {
                        headers: {
                            Authorization: `Bearer ${token}`,

                        },
                    });
                    const pedidonovo = 93060;
                    console.log(pedidonovo);
                    const CarrinhoCliente = {
                        "carrinho": {
                            "id_pedido": pedidonovo,
                            "cnpj": cnpj,
                            "itens": [
                                {
                                    "id_pedido": pedidonovo,
                                    "id_brinde": id_brinde,
                                    "quantidade": 1
                                }
                            ]
                        }
                    }
                    console.log(CarrinhoCliente);

                    const fetchData2 = async () => {
                        try {
                            const encodedData = encodeURIComponent(JSON.stringify(CarrinhoCliente));
                            const response = await axios.post(`https://data-api.agross.com.br/api/v1/carrinho/?carrinho=${encodedData}`, {}, {
                                headers: {
                                    Authorization: `Bearer ${token}`,

                                },
                            });

                            console.log(response);



                        } catch (error) {
                            console.error('Erro ao alterar quantidade', error);
                        }
                    };

                    fetchData2();



                } catch (error) {
                    console.error('Erro ao alterar quantidade', error);
                }
            };
            fetchData();
        }

    }
    return (

        <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} className=' my-5'>

            <div>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, backgroundColor: bege }} className={`rounded-2 ${Mobile ? 'flex-column' : ''}`}>
                    <TextField label="Pesquisar Brindes" value={search} onChange={(e) => setSearch(e.target.value)} variant="outlined" />


                    <TextField
                        style={{ marginLeft: Mobile ? '' : '1rem', width: Mobile ? '100%' : '30%' }}
                        id="outlined-select-currency"
                        select
                        className={Mobile ? 'mt-3' : ''}
                        label="Filtrar por categoria"
                        defaultValue={999}
                        variant='filled'

                    >
                        {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value} onClick={() => filterByType(option.value)}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </Box>
                {isLoading ?
                    <div className=" d-flex">

                        <img src={require('assets/loader.gif')} alt="loader2" style={{ width: '7%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }} />
                    </div>
                    :
                    <div className=" d-flex">
                        <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }} >
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ md: 21 }} className={`my-3 ${Mobile ? 'w100' : ''}`}>
                                {filteredData.map((brindes) => (
                                    <Grid item xs={2} sm={4} md={7}
                                        onClick={() => swalWithBootstrapButtons.fire({
                                            title: 'Trocar Brinde?',
                                            text: "Deseja solicitar este brinde?",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Sim, quero pedir este brinde.',
                                            cancelButtonText: 'Não, cancelar!',
                                            reverseButtons: true
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                if (Number(pontosCliente) < Number(Number(totaldepontos) + Number(brindes.pontos))) {
                                                    Swal.fire(
                                                        'Pontos Insuficientes',
                                                        'Você não possui saldo suficiente para trocar este brinde',
                                                        'error'
                                                    )
                                                } else {
                                                    handleAdicionarItem(brindes.id_brinde);
                                                    Swal.fire({
                                                        title: 'Brinde Adicionado',
                                                        text: "O brinde foi adicionado com sucesso",
                                                        icon: 'success',
                                                        showCancelButton: true,
                                                        confirmButtonText: 'Ir para o Carrinho',
                                                        cancelButtonText: 'Continuar trocando',
                                                        cancelButtonColor: '#198754',
                                                        confirmButtonColor: '#dc3545'
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            navigate('/carrinho');
                                                        }
                                                    })
                                                }
                                            }
                                        })}>
                                        <Card sx={{ maxWidth: 345 }} className={`m-2 ${Mobile ? 'm-auto' : ''}`} style={{ width: Mobile ? '78vw' : '' }}>
                                            <CardActionArea>
                                                <CardContent style={{ textAlign: 'center', height: '25rem', display: 'flex', alignItems: 'center' }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        height: '100%', // Certifique-se de que a div tem altura suficiente
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        width: '100%'
                                                    }}>
                                                        <div className=' d-flex'>
                                                            <img src={require(`assets/brindes/${brindes.id_brinde}.jpg`)} alt="" style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
                                                        </div>

                                                        <Typography variant="body2" color="text.secondary">
                                                            Imagem meramente ilustrativa
                                                        </Typography>
                                                        <Typography gutterBottom variant="h5" component="div" className=' my-3' >
                                                            {brindes.brinde}
                                                        </Typography>
                                                        {String(brindes.id_categoria) === '17' ?
                                                            <Typography variant="body2" color="text.secondary">
                                                                1 Pessoa + 1 Acompanhante
                                                            </Typography>
                                                            :
                                                            ''
                                                        }
                                                        <Typography variant="body2" color="text.secondary">
                                                            {Number(brindes.pontos).toLocaleString()} Pontos
                                                        </Typography>
                                                        {userLogged ?
                                                            <Button variant='outlined' className="mt-2" startIcon={<LocalGroceryStoreIcon />}
                                                                style={{ width: '100%' }}
                                                                onClick={() => swalWithBootstrapButtons.fire({
                                                                    title: 'Trocar Brinde?',
                                                                    text: "Deseja solicitar este brinde?",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Sim, quero pedir este brinde.',
                                                                    cancelButtonText: 'Não, cancelar!',
                                                                    reverseButtons: true
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        if (Number(pontosCliente) < Number(Number(totaldepontos) + Number(brindes.pontos))) {                                                                            
                                                                            Swal.fire(
                                                                                'Pontos Insuficientes',
                                                                                'Você não possui saldo suficiente para trocar este brinde',
                                                                                'error'
                                                                            )
                                                                        } else {
                                                                            handleAdicionarItem(brindes.id_brinde);
                                                                            Swal.fire({
                                                                                title: 'Brinde Adicionado',
                                                                                text: "O brinde foi adicionado com sucesso",
                                                                                icon: 'success',
                                                                                showCancelButton: true,
                                                                                confirmButtonText: 'Ir para o Carrinho',
                                                                                cancelButtonText: 'Continuar trocando',
                                                                                cancelButtonColor: '#198754',
                                                                                confirmButtonColor: '#dc3545'
                                                                            }).then((result) => {
                                                                                if (result.isConfirmed) {
                                                                                    navigate('/carrinho');
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                })}>Trocar
                                                            </Button>
                                                            :
                                                            <Button variant='outlined' className="mt-2" startIcon={<LocalGroceryStoreIcon />}
                                                                style={{ width: '100%' }}
                                                                onClick={() => swalWithBootstrapButtons.fire({
                                                                    title: 'Ops',
                                                                    text: "Para trocar brindes faça login!",
                                                                    icon: 'error',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Fazer Login',
                                                                    cancelButtonText: 'Continuar navegando',
                                                                    reverseButtons: true
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        setShowDiv(true);
                                                                    }
                                                                })}>Trocar
                                                            </Button>
                                                        }

                                                    </div>


                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>

                    </div>}
            </div>



        </div>







    )
}