import { createContext, useContext, useState } from "react";

interface MenuContextValue {
  selectedItem: string | null;
  setSelectedItem: (item: string | null) => void;
  scrollToDepoimentos: boolean;
  setScrollToDepoimentos: (scroll: boolean) => void;
  scrollToParticipar: boolean;
  setScrollToParticipar: (scroll: boolean) => void;
  scrollToVideostv: boolean;
  setScrollToVideostv: (scroll: boolean) => void;
  scrollToPerguntastv: boolean;
  setScrollToPerguntastv: (scroll: boolean) => void;
  scrollToPerguntastg: boolean;
  setScrollToPerguntastg: (scroll: boolean) => void;
  hiddenMenuVisible: boolean;
  setHiddenMenuVisible: (item: boolean) => void;
  hiddenMenutgVisible: boolean;
  setHiddenMenutgVisible: (item: boolean) => void;
}


interface MenuContextProviderProps {
  children: React.ReactNode;
}

const MenuContext = createContext<MenuContextValue | undefined>(undefined);

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenuContext must be used within a MenuContextProvider");
  }
  return context;
};

export const MenuContextProvider: React.FC<MenuContextProviderProps> = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [scrollToDepoimentos, setScrollToDepoimentos] = useState(false);
  const [scrollToParticipar, setScrollToParticipar] = useState(false);
  const [scrollToVideostv, setScrollToVideostv] = useState(false);
  const [scrollToPerguntastv, setScrollToPerguntastv] = useState(false);
  const [scrollToPerguntastg, setScrollToPerguntastg] = useState(false);
  const [hiddenMenuVisible, setHiddenMenuVisible] = useState(false);
  const [hiddenMenutgVisible, setHiddenMenutgVisible] = useState(false);

  return (
    <MenuContext.Provider value={{ selectedItem, setSelectedItem, scrollToDepoimentos, setScrollToDepoimentos, scrollToParticipar, 
    setScrollToParticipar, scrollToVideostv, setScrollToVideostv, scrollToPerguntastv, setScrollToPerguntastv, scrollToPerguntastg, 
    setScrollToPerguntastg, hiddenMenuVisible, setHiddenMenuVisible, hiddenMenutgVisible, setHiddenMenutgVisible  }}>
      {children}
    </MenuContext.Provider>
  );
};
