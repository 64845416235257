
import React from 'react';
import Countdown, { zeroPad, CountdownRenderProps } from 'react-countdown';
import { lightGreen } from '@mui/material/colors';
// Importe seu arquivo CSS aqui
import './FlipClock.css';
import useIsMobile from 'app/shared/hooks/useIsMobile';
const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
    
    if (completed) {
        // Renderize qualquer coisa quando a contagem regressiva terminar
        return <span>Contagem regressiva concluída!</span>;
    } else {
        // Renderiza um flip clock
        return (
            <div className=' d-flex mb-5'>
                <div className="flip-clock d-flex flipclockdiv mb-4 mt-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className="flip-clock__part d-flex flex-column">
                        <span className="flip-clock__value spannumero">{zeroPad(days)}</span>
                        <span className='descricaoclock'>Dias</span>
                    </div>
                    <p>:</p>
                    <div className="flip-clock__part d-flex flex-column">
                        <span className="flip-clock__value spannumero">{zeroPad(hours)}</span>
                        <span className='descricaoclock'>Horas</span>
                    </div>
                    <p>:</p>
                    <div className="flip-clock__part d-flex flex-column">
                        <span className="flip-clock__value spannumero">{zeroPad(minutes)}</span>
                        <span className='descricaoclock'>Minutos</span>
                    </div>
                    <p>:</p>
                    <div className="flip-clock__part d-flex flex-column">
                        <span className="flip-clock__value spannumero">{zeroPad(seconds)}</span>
                        <span className='descricaoclock'>Segundos</span>
                    </div>
                </div>
            </div>
        );
    }
};
const flipmobile = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
    
    if (completed) {
        // Renderize qualquer coisa quando a contagem regressiva terminar
        return <span>Contagem regressiva concluída!</span>;
    } else {
        // Renderiza um flip clock
        return (
            <div className=' d-flex mb-5'>
                <div className="flip-clock d-flex flipclockdivmobile mb-4 mt-3" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className="flip-clock__part d-flex flex-column">
                        <span className="flip-clock__value spannumero">{zeroPad(days)}</span>
                        <span className='descricaoclockmobile'>Dias</span>
                    </div>
                    <p>:</p>
                    <div className="flip-clock__part d-flex flex-column">
                        <span className="flip-clock__value spannumero">{zeroPad(hours)}</span>
                        <span className='descricaoclockmobile'>Horas</span>
                    </div>
                    <p>:</p>
                    <div className="flip-clock__part d-flex flex-column">
                        <span className="flip-clock__value spannumero">{zeroPad(minutes)}</span>
                        <span className='descricaoclockmobile'>Minutos</span>
                    </div>
                    <p>:</p>
                    <div className="flip-clock__part d-flex flex-column">
                        <span className="flip-clock__value spannumero">{zeroPad(seconds)}</span>
                        <span className='descricaoclockmobile'>Segundos</span>
                    </div>
                </div>
            </div>
        );
    }
};
export const Flip = () => {
    const Mobile = useIsMobile();
    const getNextThursday = () => {
        const now = new Date();
        let nextThursday = new Date();

        // Se hoje já é quinta-feira mas já passou das 15:45, ou se hoje é depois de quinta-feira,
        // ajuste para a próxima quinta-feira.
        if (now.getDay() > 4 || (now.getDay() === 4 && now.getHours() >= 15 && now.getMinutes() >= 45)) {
            nextThursday.setDate(now.getDate() + (11 - now.getDay()) % 7);
        } else {
            // Se não, ajuste para a próxima quinta-feira.
            nextThursday.setDate(now.getDate() + (4 - now.getDay()) % 7);
        }

        // Define a hora para 15:45.
        nextThursday.setHours(15);
        nextThursday.setMinutes(45);
        nextThursday.setSeconds(0);
        nextThursday.setMilliseconds(0);

        return nextThursday;
    };
    return (
        <Countdown date={getNextThursday()} renderer={Mobile? flipmobile : renderer} />
    );
}


