import { Typography } from "@mui/material"
import { lightGreen } from "@mui/material/colors";
import { useCallback,  useState } from "react";
import styled, { css } from "styled-components";
import './Menufooter.css'
import { CSSTransition } from 'react-transition-group';
import {  useNavigate } from 'react-router-dom';
import { useMenuContext } from "app/shared/contexts";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import useIsMobile from "app/shared/hooks/useIsMobile";
import classnames from "classnames";

const primary = lightGreen[800];
const MenuContainer = styled.div`
  
  align-items: center;
  height: 4rem;
`;

const MenuItem = styled.div`
  margin-right: 20px;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
  position: relative;
  
  font-family: 'Tiresias Infofont', sans-serif !important;

  &:hover {
    .line {
      width: 2rem;
    }
  }
`;

const Line = styled.div<{ isActive: boolean }>`
  width: 0;
  height: 2px;
  background-color: ${primary};
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: width 0.3s ease-in-out;

  ${({ isActive }) =>
        isActive &&
        css`
      width: 100%;
    `}
`;
export const Menufooter = () => {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState('');
    const { selectedItem, setSelectedItem } = useMenuContext();

    const {hiddenMenuVisible, setHiddenMenuVisible} = useMenuContext();
    const {hiddenMenutgVisible, setHiddenMenutgVisible} = useMenuContext();
    const Mobile = useIsMobile();

    const handleItemClick = useCallback(
        (item: string) => {
            setActiveItem(item);
            setSelectedItem(item);
            const animationDuration = 700;

            if (item === 'TvAgRoss' || item === 'aovivo' || item === 'comoparticipar' || item === 'videos' || item === 'perguntasfrequentes' || item === 'regras' || item === 'horadorango') {
                if (!hiddenMenutgVisible) {
                    setHiddenMenuVisible(true);
                } else {
                    setHiddenMenutgVisible(false);
                    setTimeout(() => {
                        setHiddenMenuVisible(true);
                    }, animationDuration);
                }
                if (item === 'TvAgRoss') {
                    navigate('/tvagross');
                } else if (item === 'aovivo') {
                    navigate('/assistaaovivo');
                } else if (item === 'comoparticipar') {
                    navigate('/comoparticipar');
                } else if (item === 'videos') {
                    navigate('/videos');
                } else if (item === 'perguntasfrequentes') {
                    navigate('/perguntasfrequentes');
                } else if (item === 'regras') {
                    navigate('/regras');
                } else if (item === 'horadorango') {
                    navigate('/horadorango');
                }
            } else if (item === 'Troque & Ganhe' || item === 'Regulamento' || item === 'perguntasfrequentestg' || item === 'brindes' || item === 'carrinho') {
                if (!hiddenMenuVisible) {
                    setHiddenMenutgVisible(true);
                } else {
                    setHiddenMenuVisible(false);
                    setTimeout(() => {
                        setHiddenMenutgVisible(true);
                    }, animationDuration);
                }
                if (item === 'Troque & Ganhe') {
                    navigate('/troqueeganhe');
                } else if (item === 'Regulamento') {
                    navigate('/regulamento');
                } else if (item === 'perguntasfrequentestg') {
                    navigate('/perguntasfrequentestg');
                } else if (item === 'brindes') {
                    navigate('/brindes');
                } else if (item === 'carrinho') {
                    navigate('/carrinho');
                }
            } else {
                setHiddenMenuVisible(false);
                setHiddenMenutgVisible(false);
            }
            if (item === 'Início') {
                navigate('/');
            } else if (item === 'Nossa História') {
                navigate('/nossahistoria');
            } else if (item === 'Portfólio') {
                navigate('/portfolio');
            } else if (item === 'Vantagens') {
                navigate('/vantagens');
            } else if (item === 'Notícias') {
                navigate('/noticias');
            } else if (item === 'Depoimentos') {
                navigate('/depoimentos');
            } else if (item === 'Contato') {
                navigate('/contato');
            }
            window.scrollTo(0, 0);
        },
        [hiddenMenutgVisible, hiddenMenuVisible, navigate, setHiddenMenuVisible, setHiddenMenutgVisible, setSelectedItem] // Adicione as dependências relevantes aqui
    );
    return (
        <div className={classnames('divfooter p-4 d-flex  justify-content-around', { 'd-none': Mobile, 'd-flex': !Mobile })} style={{ color: "white" }}>
            <div className=" mx-3" style={{ width: '20%' }}>
                <Typography fontFamily={'Arima Madurai'} variant="h6" className="titulofooter">
                    Materiais AgRoss
                </Typography>
                <div className="line"></div>
                <Typography style={{ textAlign: 'justify', fontSize: '0.8rem' }} className=" my-5">
                    Aqui você pode fazer o download de Materiais da AgRoss como o manual de identidade visual, logos, Revista do Troque & Ganhe, etc.
                </Typography>

                <Typography style={{ color: "#eddd5e !important" }}>
                    <a href="http://localhost:3000" >Clique Aqui</a>
                </Typography>
            </div>
            <div className="mx-3" style={{ width: '8%' }}>
                <Typography fontFamily={'Arima Madurai'} variant="h6" className="titulofooter">
                    AgRoss
                </Typography>
                <div className="line"></div>
                <MenuContainer style={{ margin: 'auto' }} className="my-5" >
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Início')} className="my-2" >
                        Início
                        <CSSTransition in={activeItem === 'Início'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Início'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Nossa História')} className="my-2">
                        Nossa História
                        <CSSTransition in={activeItem === 'Nossa História'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Nossa História'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Portfólio')} className="my-2">
                        Portfólio
                        <CSSTransition in={activeItem === 'Portfólio'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Portfólio'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Vantagens')} className="my-2">
                        Vantagens
                        <CSSTransition in={activeItem === 'Vantagens'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Vantagens'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Notícias')} className="my-2">
                        Notícias
                        <CSSTransition in={activeItem === 'Notícias'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Notícias'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Depoimentos')} className="my-2">
                        Depoimentos
                        <CSSTransition in={activeItem === 'Depoimentos'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Depoimentos'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Contato')} className="my-2">
                        Contato
                        <CSSTransition in={activeItem === 'Contato'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Contato'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('TvAgRoss')} className="my-2">
                        TvAgRoss
                        <CSSTransition in={activeItem === 'TvAgRoss'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'TvAgRoss'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Troque & Ganhe')} className="my-2">
                        Troque & Ganhe
                        <CSSTransition in={activeItem === 'Troque & Ganhe'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Troque & Ganhe'} />
                        </CSSTransition>
                    </MenuItem>
                </MenuContainer>
            </div>


            <div className="mx-3" style={{ width: '8%' }}>
                <Typography fontFamily={'Arima Madurai'} variant="h6" className="titulofooter">
                    Tv AgRoss
                </Typography>
                <div className="line"></div>
                <MenuContainer style={{ margin: 'auto' }} className="my-5">
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => window.open('https://tvagross.com.br/inicio', '_blank')} className="my-2">
                        Assista Ao Vivo
                        <CSSTransition in={activeItem === 'aovivo'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'aovivo'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('comoparticipar')} className="my-2">
                        Como Participar
                        <CSSTransition in={activeItem === 'comoparticipar'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'comoparticipar'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('videos')} className="my-2">
                        Vídeos
                        <CSSTransition in={activeItem === 'videos'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'videos'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('perguntasfrequentes')} className="my-2">
                        Perguntas Frequentes
                        <CSSTransition in={activeItem === 'perguntasfrequentes'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'perguntasfrequentes'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('regras')} className="my-2">
                        Regras
                        <CSSTransition in={activeItem === 'regras'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'regras'} />
                        </CSSTransition>
                    </MenuItem>
                    {/* <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('horadorango')} className="my-2">
                        Hora do Rango
                        <CSSTransition in={activeItem === 'horadorango'} timeout={300} classNames="line">
                        <Line className="line" isActive={selectedItem === 'horadorango'} />
                        </CSSTransition>
                    </MenuItem> */}
                </MenuContainer>
            </div>

            <div className="mx-3" style={{ width: '15%' }}>
                <Typography fontFamily={'Arima Madurai'} variant="h6" className="titulofooter">
                    Troque e Ganhe
                </Typography>
                <div className="line"></div>
                <MenuContainer style={{ margin: 'auto' }} className="my-5">
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('Regulamento')} className="my-2">
                        Regulamento
                        <CSSTransition in={activeItem === 'Regulamento'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'Regulamento'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('perguntasfrequentestg')} className="my-2">
                        Perguntas Frequentes
                        <CSSTransition in={activeItem === 'perguntasfrequentestg'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'perguntasfrequentestg'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('brindes')} className="my-2">
                        Brindes
                        <CSSTransition in={activeItem === 'brindes'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'brindes'} />
                        </CSSTransition>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => handleItemClick('carrinho')} className="my-2">
                    <LocalGroceryStoreIcon style={{ width: '1.2rem' }} /> Carrinho
                        <CSSTransition in={activeItem === 'carrinho'} timeout={300} classNames="line">
                            <Line className="line" isActive={selectedItem === 'carrinho'} />
                        </CSSTransition>
                    </MenuItem>
                </MenuContainer>
            </div>

            <div className="mx-3" style={{ width: '23%' }}>
                <Typography fontFamily={'Arima Madurai'} variant="h6" className="titulofooter">
                    Materiais AgRoss
                </Typography>
                <div className="line"></div>
                <Typography style={{ textAlign: 'justify', fontSize: '0.8rem' }} className=" my-5">
                    R. Arnaldo Barreto, 795 – São&nbsp;Bernardo – Campinas/SP
                </Typography>

                <Typography style={{ color: "#eddd5e", fontSize: '0.9rem' }}>
                    contato@agross.com.br
                </Typography>
                <Typography style={{ color: "#eddd5e", fontSize: '0.9rem' }}>
                    0800 055 4533
                </Typography>
            </div>

        </div>
    )
}