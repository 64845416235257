import { Routes } from "./routes";
import '../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';


const theme = createTheme({
  typography: {
    fontFamily: 'Tiresias',
  },
  palette: {
    primary: {
      main: '#5B8C51',
    },
    secondary: {
      main: '#f44336',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },

});

const temafinal = createTheme();
temafinal.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [temafinal.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};


export const App = () => {
 
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}
