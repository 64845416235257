import Carousel from 'react-bootstrap/Carousel';
import banner1 from "./banner01.png";
import banner2 from "./banner02.png";
import banner3 from "./banner03.png";
import mobile1 from "./mobile01.png";
import mobile2 from "./mobile02.jpg";
import mobile3 from "./mobile03.jpg";
import useIsMobile from 'app/shared/hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';

export const Carouselag = () => {
  const Mobile = useIsMobile();
  const navigate = useNavigate();
  return (
    <div>
      {Mobile ?
        <div>
          <Carousel>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100"
                src={mobile1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100"
                src={mobile2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={3000} onClick={() => {
              navigate('/sementemilho');
              window.scrollTo(0, 0);
            }}>
              <img
                className="d-block w-100"
                src={mobile3}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>

        :
        <div>
          <Carousel>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100"
                src={banner1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100"
                src={banner2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={3000} onClick={() => {
              navigate('/sementemilho');
              window.scrollTo(0, 0);
            }}
            style={{cursor: 'pointer'}}>
              <img
                className="d-block w-100"
                src={banner3}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      }
    </div>
  );
}
