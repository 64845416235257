import { Typography } from "@mui/material"
import { lightGreen } from '@mui/material/colors';
import useIsMobile from "app/shared/hooks/useIsMobile";


const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';


export const Vantagens = () => {
    const Mobile = useIsMobile();
    return (
        <div>
            <div className="mt-5 d-flex flex-column">
                <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className=" d-flex flex-column">
                        <Typography fontFamily={'Arima Madurai'} variant={Mobile ? "h4" : "h3"} style={{ color: verdeescuro, textAlign: 'center' }}>
                            Atendimento Exclusivo a Revendas
                        </Typography>
                        <img src={require('app/shared/imagens/separador.png')} alt="" className={`my-3`} style={{ marginLeft: 'auto', marginRight: 'auto' }} />

                    </div>
                    <div className={`d-flex justify-content-around my-4  ${Mobile ? 'flex-column' : ''}`}>
                        <div className=" d-flex flex-column">
                            
                            <div style={{ paddingRight: Mobile ? '' : '2rem' }}>
                                <Typography style={{ color: verdeescuro, textAlign: 'justify' }}>
                                    A AgRoss oferece o maior portfólio de defensivos agrícolas do mercado, trabalhando com os melhores fabricantes, garantindo procedência e atendimento exclusivo para revendas. Ofertamos ainda uma ampla linha de produtos, como sementes, fertilizantes foliares, pulverizadores e EPI’s. Dispomos dos melhores serviços para atendimento dos nossos clientes, com suporte técnico qualificado, entrega semanal, com frota própria, programas de incentivo e muito mais.
                                </Typography>
                            </div>
                        </div>
                        <div className=" d-flex">
                            <img src={require('assets/vantagens/vantagens.png')} alt="" style={{ width: '40rem', marginLeft: 'auto', marginRight: 'auto' }} className={` ${Mobile ? 'w-100' : ''}`} />
                        </div>
                    </div>

                </div>
                <div className=" mt-4 p-5 d-flex" style={{ backgroundColor: bege }}>
                    <div style={{ width: Mobile ? '90%' : '80%', marginLeft: 'auto', marginRight: 'auto' }} className={`d-flex justify-content-between ${Mobile ? 'flex-column' : ''}`}>
                        <div className=" d-flex flex-column" style={{ width: Mobile ? '100%' : '48%' }}>
                            <div className=" my-3">
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: verdeescuro }}>
                                    Confiabilidade e segurança
                                </Typography>
                                <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginRight: 'auto' }} />
                                <Typography style={{ color: verdeescuro }}>
                                    Distribuição exclusiva para revendas agrícolas, com garantia e confiabilidade das melhores e maiores marcas do mercado.
                                </Typography>
                            </div>
                            <div className=" my-3">
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: verdeescuro }}>
                                    Múltiplos canais de atendimento
                                </Typography>
                                <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginRight: 'auto' }} />
                                <Typography style={{ color: verdeescuro }}>
                                    Diversos canais de atendimentos disponíveis, com profissionais experientes no mercado para atendê-los. Ex: 0800, WhatsApp, E-agross, Chat, Emails e outros.
                                </Typography>
                            </div>
                            <div className=" my-3">
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: verdeescuro }}>
                                    Grandes Eventos
                                </Typography>
                                <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginRight: 'auto' }} />
                                <Typography style={{ color: verdeescuro }}>
                                    Eventos direcionados, permitindo a proximidade e fortalecimento do relacionamento com clientes e fornecedores, como o Festão AgRoss, Lançamentos de Produtos, Rodadas de Negócios, Camarote AgRoss, Eventos Técnicos, entre outros.
                                </Typography>
                            </div>
                            <div className=" my-3">
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: verdeescuro }}>
                                    Programa de fidelidade
                                </Typography>
                                <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginRight: 'auto' }} />
                                <Typography style={{ color: verdeescuro }}>
                                    Troque e Ganhe – Programa de Fidelidade da AgRoss, reconhecido no mercado e em operação a mais de 20 anos. O programa consiste no acumulo de pontos, adquiridos pelas compras de produtos participantes e na troca dos mesmos, por mais de 500 prêmios vigentes, como eletrodomésticos, itens para escritórios, ferramentas, motos, viagens e etc.
                                </Typography>
                            </div>



                        </div>
                        <div className=" d-flex flex-column" style={{ width: Mobile ? '100%' : '48%' }}>
                            <div className=" my-3">
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: verdeescuro }}>
                                    Entrega em até 48h
                                </Typography>
                                <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginRight: 'auto' }} />
                                <Typography style={{ color: verdeescuro }}>
                                    Logística preparada e segura, realizando a entrega dos produtos em até 48 horas.
                                </Typography>
                            </div>
                            <div className=" my-3">
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: verdeescuro }}>
                                    Orientações e treinamentos técnicos exclusivos
                                </Typography>
                                <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginRight: 'auto' }} />
                                <Typography style={{ color: verdeescuro }}>
                                    Agrônomos disponíveis para orientações técnicas e treinamentos sobre aplicações de produtos específicos, nas revendas ou através da Tv Agross.
                                </Typography>
                            </div>
                            <div className=" my-3">
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: verdeescuro }}>
                                    Plataforma interativa e exclusiva
                                </Typography>
                                <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginRight: 'auto' }} />
                                <Typography style={{ color: verdeescuro }}>
                                    E-AgRoss – Plataforma exclusiva e interativa que une em um único canal todo o portfólio de produtos e serviços da AgRoss, facilitando todo o processo de compra.
                                </Typography>
                            </div>
                            <div className=" my-3">
                                <Typography fontFamily={'Arima Madurai'} variant="h5" style={{ color: verdeescuro }}>
                                    TV - Real Time
                                </Typography>
                                <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginRight: 'auto' }} />
                                <Typography style={{ color: verdeescuro }}>
                                    A TV AgRoss é apresentada semanalmente, desde 2015. Além de contar com equipamentos de alta qualidade, a Tv é apresentada por profissionais experientes e reconhecidos da televisão brasileira. Com diversos quadros, como Palestra Técnica, AgRoss no Campo, Camarote AgRoss, Nota Fiscal Premiada, Pitaco do Rica, Consulte o Consultor, dentre outros.
                                </Typography>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>





    )
}