import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Fornecedores.css';
import useIsMobile from 'app/shared/hooks/useIsMobile';

export const Fornecedores = () => {
  const [autoplay, setAutoplay] = useState(true);
  const Mobile = useIsMobile();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnHover: true,
    beforeChange: () => {
      setAutoplay(false);
    },
    afterChange: () => {
      setAutoplay(true);
    },
  };

  const settingsmobile = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    beforeChange: () => {
      setAutoplay(false);
    },
    afterChange: () => {
      setAutoplay(true);
    },
  };
  return (
    <div>
      {Mobile ?
        <Slider {...settingsmobile} className='d-flex align-items-center'>
          <div style={{ display: 'flex', alignItems: 'center', height: '300px' }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/agroceres-R.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/fmc1.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/Helm.png')} alt="imagem 1" width={'auto'} style={{ marginLeft: 'auto', marginRight: 'auto', maxHeight: '100px' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/jacto.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/Lacsa-01.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/Loyder-Logo-01.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/nortox.png')} alt="imagem 1" width={'auto'} style={{ marginLeft: 'auto', marginRight: 'auto', maxHeight: '100px' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/Ouro-Fino-Agro.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/oxiquimica.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/Seminis.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
        </Slider>
        :
        <Slider {...settings} className='d-flex align-items-center'>
          <div style={{ display: 'flex', alignItems: 'center', height: '300px' }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/agroceres-R.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/fmc1.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/Helm.png')} alt="imagem 1" width={'auto'} style={{ marginLeft: 'auto', marginRight: 'auto', maxHeight: '100px' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/jacto.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/Lacsa-01.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/Loyder-Logo-01.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/nortox.png')} alt="imagem 1" width={'auto'} style={{ marginLeft: 'auto', marginRight: 'auto', maxHeight: '100px' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/Ouro-Fino-Agro.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/oxiquimica.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 300 }} className='sliderfornecedores'>
            <img src={require('assets/fornecedores/Seminis.png')} alt="imagem 1" width={'200px'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
        </Slider>
      }
    </div>

  );
};


