import { Route, BrowserRouter, Routes as Switch, useParams } from "react-router-dom";
import { Agross, AminoAgRoss, AssistaAoVivo, Brindes, Carrinho, ComoParticipar, Contato, Depoimentos, Esqueceu, HoraDoRango, MinhaConta, NossaHistoria, Noticias, Perguntas, PerguntasFrequentes, Portfolio, ProdutosParticipantes, RecomendacoesTecnicas, Regras, Regulamento, SementeMilho, TroqueEGanhe, TvAgross, Vantagens, Videos } from "../pages";
import { Menuheader, Menufooter } from "../shared/components";
import { MenuContextProvider, UserLogged } from "app/shared/contexts";
import NewsDetail from "app/pages/pagesagross/noticias/NewsDetail";
import { useState } from "react";
import { ShowDivContext } from "app/shared/contexts/ShowDivContext/ShowDivContext";
import AuthService from "app/shared/services/AuthService";
import { CartProvider } from "app/shared/services/CartProvider";
import React from 'react';
import TokenContext from "app/shared/services/token/TokenContext";
import getTokenAndSave from "app/shared/services/token/getTokenAndSave";
import { CarrinhoProvider } from "app/shared/services/cart/CarrinhoContext";

function EsqueceuWrapper() {
    // O hook `useParams` é usado para capturar o token da URL
    let { token } = useParams();
    return <Esqueceu tokensenha={token} />;
  }

export const Routes = () => {
    const [showDiv, setShowDiv] = useState(false);
    const [userLogged, setUserLogged] = useState(true);
    const [token, setToken] = React.useState<string | null>(localStorage.getItem('token') || null);

    React.useEffect(() => {
        getTokenAndSave().then(newToken => {
            setToken(newToken);
        });



        const intervalId = setInterval(() => {
            getTokenAndSave().then(newToken => {
                setToken(newToken);
            });
        }, 50 * 60 * 1000);

        // Limpando interval quando o componente é desmontado
        return () => clearInterval(intervalId);
    }, []);

    return (
        <BrowserRouter>
            <TokenContext.Provider value={token}>
                <CarrinhoProvider>
                    <CartProvider>
                        <MenuContextProvider>
                            <ShowDivContext.Provider value={{ showDiv, setShowDiv }}>
                                <UserLogged.Provider value={{ userLogged, setUserLogged }}>
                                    <Menuheader authService={new AuthService()} />


                                    <Switch>

                                        {/* Rotas AgRoss */}
                                        <Route path="/" element={<Agross />} />
                                        <Route path="nossahistoria" element={<NossaHistoria />} />
                                        <Route path="portfolio" element={<Portfolio />} />
                                        <Route path="recomendacoes" element={<RecomendacoesTecnicas />} />
                                        <Route path="vantagens" element={<Vantagens />} />
                                        <Route path="noticias" element={<Noticias />} />
                                        <Route path="noticias/:id" element={<NewsDetail />} />
                                        <Route path="depoimentos" element={<Depoimentos />} />
                                        <Route path="contato" element={<Contato />} />
                                        <Route path="aminoagross" element={<AminoAgRoss />} />
                                        <Route path="sementemilho" element={<SementeMilho />} />


                                        {/* Rotas TvAgross */}
                                        <Route path="tvagross" element={<TvAgross />} />
                                        <Route path="assistaaovivo" element={<AssistaAoVivo />} />
                                        <Route path="comoparticipar" element={<ComoParticipar />} />
                                        <Route path="videos" element={<Videos />} />
                                        <Route path="perguntasfrequentes" element={<Perguntas />} />
                                        <Route path="regras" element={<Regras />} />
                                        <Route path="horadorango" element={<HoraDoRango />} />


                                        {/* Rotas Troque e Ganhe */}
                                        <Route path="troqueeganhe" element={<TroqueEGanhe onLinkClick={() => setShowDiv(true)} />} />
                                        <Route path="regulamento" element={<Regulamento />} />
                                        <Route path="perguntasfrequentestg" element={<PerguntasFrequentes />} />
                                        <Route path="brindes" element={<Brindes authService={new AuthService()} />} />
                                        <Route path="carrinho" element={<Carrinho />} />
                                        <Route path="minhaconta" element={<MinhaConta authService={new AuthService()}/>} />
                                        <Route path="produtosparticipantes" element={<ProdutosParticipantes />} />
                                        <Route path="/esqueceu/:token" element={<EsqueceuWrapper />} />

                                    </Switch>
                                    <Menufooter />
                                </UserLogged.Provider>
                            </ShowDivContext.Provider>
                        </MenuContextProvider>
                    </CartProvider>
                </CarrinhoProvider>
            </TokenContext.Provider>
        </BrowserRouter>
    );
}
