import { Button, Typography } from "@mui/material"
import { TitleComponent } from "app/shared/components"
import { lightGreen } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

export const SementeMilho = () => {
    const navigate = useNavigate();
    return (
        <div className=" d-flex flex-column">
            <TitleComponent texto="Semente Milho Verde Híbrido Ag1051" />
            <Button variant="outlined" size="small" startIcon={<KeyboardBackspaceOutlinedIcon />} style={{ color: primary, width: '5rem' }} className="mx-4" onClick={() => {
                            navigate('/portfolio');
                            window.scrollTo(0, 0);
                        }}>
                                    Voltar
                                </Button>
            <img src={require('assets/Semente-Milho.png')} alt="" className="w-100" />
            <div className="w-75 m-auto mb-5">
                <Typography className="text-center" style={{ color: primary }}>
                Atendendo a demanda de mercado por uma semente de Milho Verde, de qualidade em embalagem legalizada, a AgRoss lança com sua marca própria no mercado a variedade de semente que é a melhor nesse seguimento de milho verde. Semente de Milho Verde AgRoss, híbrido AG1051 01Kg. Uma novidade que visa atender principalmente os pequenos produtores, clientes de nossos clientes. Já disponível em todas as nossas unidades.
                </Typography>
                
            </div>
        </div>
    )
}