import { Link, TextField, Typography } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './Contato.css'
import { lightGreen } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@material-ui/core';
import SendIcon from '@mui/icons-material/Send';
import { TitleComponent } from 'app/shared/components';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import useIsMobile from 'app/shared/hooks/useIsMobile';

const verdeescuro = lightGreen[900];

const primary = lightGreen[800];


const currencies = [
    {
        value: 'Financeiro',
        label: 'Financeiro',
    },
    {
        value: 'Jurídico',
        label: 'Jurídico',
    },
    {
        value: 'Marketing',
        label: 'Marketing',
    },
    {
        value: 'Logística',
        label: 'Logística',
    },
    {
        value: 'RH',
        label: 'RH',
    },
    {
        value: 'Tv Agross',
        label: 'Tv Agross',
    },
    {
        value: 'Troque e Ganhe',
        label: 'Troque e Ganhe',
    },
    {
        value: 'Vendas',
        label: 'Vendas',
    },
    {
        value: 'Outros',
        label: 'Outros',
    },
];

export const Contato = () => {
    const Mobile = useIsMobile();
    return (


        <div className="mt-5 d-flex flex-column">
            <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className=" d-flex flex-column">
                    <Typography fontFamily={'Arima Madurai'} variant={Mobile? "h4":"h3"} style={{ color: verdeescuro, textAlign: 'center' }}>
                        Fale com a AgRoss
                    </Typography>
                    <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
                <div className='mb-3 d-flex justify-content-center'>
                    <LocalPhoneIcon style={{fontSize:Mobile? '2rem': '2.5rem', marginRight: '1rem', color: verdeescuro}}/>
                    <Typography fontFamily={'Arima Madurai'} variant={Mobile? "h4":"h3"} style={{ color: verdeescuro, textAlign: 'center' }}>
                        0800 055 4533
                    </Typography>

                </div>
                <div className=' d-flex justify-content-around flex-wrap'>

                    <div className={`divcontato ${Mobile? 'w-100' : ''}`}>
                        <img src={require('assets/tg/tglogo.png')} alt="" width={'50%'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        <Typography style={{ color: primary }} className='my-1'>
                            Assuntos referentes ao Troque e Ganhe
                        </Typography>
                        <Typography style={{ color: primary }} className='my-1'>
                            (19) 9 9941-3788
                        </Typography>
                        <Typography style={{ color: primary }} className='my-1'>
                            <WhatsAppIcon />
                            {' '}
                            <Link href="#" color="secondary" className='text-warning' style={{ textDecoration: 'none' }}>
                                Ou entre em contato via Whatsapp
                            </Link>{' '}

                        </Typography>

                    </div>
                    <div className={`divcontato ${Mobile? 'w-100' : ''}`}>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }} className='my-4'>
                            Campinas/SP
                        </Typography>
                        <Typography style={{ color: primary }} className='my-1'>
                            R. Arnaldo Barreto, 795 – São Bernardo - CEP:&nbsp;13.030-420
                        </Typography>

                        <Typography style={{ color: primary }} className='my-1'>
                            <WhatsAppIcon />
                            {' '}
                            <Link href="#" color="secondary" className='text-warning' style={{ textDecoration: 'none' }}>
                                Entre em contato via Whatsapp
                            </Link>{' '}

                        </Typography>
                    </div>
                    <div className={`divcontato ${Mobile? 'w-100' : ''}`}>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }} className='my-4'>
                            Sete Lagoas/MG
                        </Typography>
                        <Typography style={{ color: primary }} className='my-1'>
                            Av. Jaime R. Branco, 221 – Eldorado - CEP:&nbsp;35701242
                        </Typography>

                        <Typography style={{ color: primary }} className='my-1'>
                            <WhatsAppIcon />
                            {' '}
                            <Link href="#" color="secondary" className='text-warning' style={{ textDecoration: 'none' }}>
                                Entre em contato via Whatsapp
                            </Link>{' '}

                        </Typography>
                    </div>
                    <div className={`divcontato ${Mobile? 'w-100' : ''}`}>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }} className='my-4'>
                            Anápolis/GO
                        </Typography>
                        <Typography style={{ color: primary }} className='my-1'>
                            Av Brasil, 4061 - Setor Sul - Jamil Miguel - CEP:&nbsp;75.124-145
                        </Typography>

                        <Typography style={{ color: primary }} className='my-1'>
                            <WhatsAppIcon />
                            {' '}
                            <Link href="#" color="secondary" className='text-warning' style={{ textDecoration: 'none' }}>
                                Entre em contato via Whatsapp
                            </Link>{' '}

                        </Typography>
                    </div>
                    <div className={`divcontato ${Mobile? 'w-100' : ''}`}>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }} className='my-4'>
                            Pouso Alegre/MG
                        </Typography>
                        <Typography style={{ color: primary }} className='my-1'>
                            Av Prefeito Olavo Gomes de Oliveira, 2989 – Francisca Augusta Rios - CEP:&nbsp;37557030
                        </Typography>

                        <Typography style={{ color: primary }} className='my-1'>
                            <WhatsAppIcon />
                            {' '}
                            <Link href="#" color="secondary" className='text-warning' style={{ textDecoration: 'none' }}>
                                Entre em contato via Whatsapp
                            </Link>{' '}

                        </Typography>
                    </div>
                    <div className={`divcontato ${Mobile? 'w-100' : ''}`}>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }} className='my-4'>
                            Lins/SP
                        </Typography>
                        <Typography style={{ color: primary }} className='my-1'>
                            Av. São Paulo, 1371 – Jardim Guanabara - CEP:&nbsp;16.403-266
                        </Typography>

                        <Typography style={{ color: primary }} className='my-1'>
                            <WhatsAppIcon />
                            {' '}
                            <Link href="#" color="secondary" className='text-warning' style={{ textDecoration: 'none' }}>
                                Entre em contato via Whatsapp
                            </Link>{' '}

                        </Typography>
                    </div>
                    <div className={`divcontato ${Mobile? 'w-100' : ''}`}>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }} className='my-4'>
                            Cariacica/ES
                        </Typography>
                        <Typography style={{ color: primary }} className='my-1'>
                            R. Cachoeiro de Itapemirim, 123 – Vila Capixaba - CEP:&nbsp; 29.148-210
                        </Typography>

                        <Typography style={{ color: primary }} className='my-1'>
                            <WhatsAppIcon />
                            {' '}
                            <Link href="#" color="secondary" className='text-warning' style={{ textDecoration: 'none' }}>
                                Entre em contato via Whatsapp
                            </Link>{' '}

                        </Typography>
                    </div>
                    <div className={`divcontato ${Mobile? 'w-100' : ''}`}>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }} className='my-4'>
                            Carazinho/RS
                        </Typography>
                        <Typography style={{ color: primary }} className='my-1'>
                            R. Egídio Antonio Endler, Nº 170B – Distrito Industrial - CEP:&nbsp;99.500-000
                        </Typography>

                        <Typography style={{ color: primary }} className='my-1'>
                            <WhatsAppIcon />
                            {' '}
                            <Link href="#" color="secondary" className='text-warning' style={{ textDecoration: 'none' }}>
                                Entre em contato via Whatsapp
                            </Link>{' '}

                        </Typography>
                    </div>
                    <div className={`divcontato ${Mobile? 'w-100' : ''}`}>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }} className='my-4'>
                            Petrolina/PE
                        </Typography>
                        <Typography style={{ color: primary }} className='my-1'>
                            Av Mario Rodrigues Coelho, s/n – Galpão 1040 – Cohab São Francisco - CEP:&nbsp;56313-275
                        </Typography>

                        <Typography style={{ color: primary }} className='my-1'>
                            <WhatsAppIcon />
                            {' '}
                            <Link href="#" color="secondary" className='text-warning' style={{ textDecoration: 'none' }}>
                                Entre em contato via Whatsapp
                            </Link>{' '}

                        </Typography>
                    </div>
                </div>


            </div>
            <div className={`d-flex justify-content-around ${Mobile? 'flex-column' : ''}`}>
                <div className=" d-flex flex-column pt-5" style={{ color: verdeescuro, textAlign: 'center', width:Mobile? '100%':'50%' }}>
                    <Typography>
                        FALE COM A GENTE
                    </Typography>
                    <Typography fontFamily={'Arima Madurai'} variant="h4" >
                        Dúvidas? Sugestões?
                    </Typography>
                    <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    <img src={require('assets/contatos/contatos.png')} alt="" className=" mt-3 w-75" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
                <div className=' d-flex flex-column p-5' style={{ backgroundColor: verdeescuro, width:Mobile? '100%':'50%' }}>
                    <div className=' d-flex flex-column'>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: 'white', textAlign: 'center' }}>
                            Fale com a gente
                        </Typography>
                        <img src={require('app/shared/imagens/separador.png')} alt="" className=" my-3" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                    <div className=' d-flex justify-content-between flex-wrap'>

                        <TextField
                            required
                            id="fcanome"
                            label="Nome"
                            className={`fcainputs m-3 ${Mobile? 'w-100' : ''}`}
                        />
                        <TextField
                            required
                            id="fcaemail"
                            label="E-mail"
                            className={`fcainputs m-3 ${Mobile? 'w-100' : ''}`}
                        />
                        <TextField
                            required
                            id="fcatelefone"
                            label="Telefone"
                            className={`fcainputs m-3 ${Mobile? 'w-100' : ''}`}
                        />
                        <TextField
                            id="outlined-select-currency"
                            select
                            required
                            label="Departamento/Assunto"
                            defaultValue="Financeiro"
                            className={`fcainputs m-3 ${Mobile? 'w-100' : ''}`}
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value} className=' d-flex flex-column'>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            required
                            id="outlined-multiline-flexible"
                            label="Escreva sua mensagem"
                            multiline
                            fullWidth={true}

                            rows={5}
                            className='fcainputs m-3'
                            style={{ width: '100% !important' }}
                        />
                    </div>
                    <Button variant="contained" size="large" style={{ width:Mobile? '90%':'40%', marginLeft: 'auto', marginRight: 'auto', borderColor: verdeescuro, color: verdeescuro }} endIcon={<SendIcon />}>
                        Enviar
                    </Button>
                </div>
            </div>
        </div>


    )
}