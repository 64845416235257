import { Box, } from '@mui/material';

import './Depoimentos.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type Testimonial = {
  name: string;
  city: string;
  message: string;
  photoUrl: string;
};

type TestimonialProps = {
  testimonials: Testimonial[];
};

export const Depoimentos = ({ testimonials }: TestimonialProps) => {



  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
    
  };



  return (
    <Box bgcolor="#558b2f" p={4} mx="auto" style={{ padding: '2rem'}}>
      <Slider  {...settings} className=' p-4'>
        {testimonials.map((testimonial, index) => (
          <Box key={index} >

            <div className=' d-flex flex-column'>
              <div className=' d-flex' style={{ color: 'white' }}>
                <div className=' d-flex align-items-center' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <div style={{width: '20%'}}>
                    <img src={testimonial.photoUrl} alt={testimonial.name} style={{ borderRadius: '5rem', width: '100%' }} />
                  </div>
                  <div className=' d-flex flex-column mx-4'>
                    <div >
                      <Box fontFamily={'Arima Madurai'} style={{ fontSize: '1.5rem' }}>{testimonial.name}</Box>
                    </div>
                    <div>
                      <Box>{testimonial.city}</Box>
                    </div>

                  </div>
                </div>
              </div>

              <div className=' my-4' style={{ color: 'white' }}>
                <Box style={{textAlign: 'center'}}>{testimonial.message}</Box>
              </div>
            </div>

          </Box>
        ))}
      </Slider>
    </Box>
  );
};
