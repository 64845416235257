import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, Link, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useCarrinho } from "app/shared/services/cart/CarrinhoContext";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { lightGreen } from "@mui/material/colors";
import TokenContext from 'app/shared/services/token/TokenContext';
import Swal from 'sweetalert2';
import axios from 'axios';
import * as React from 'react';
import { TitleComponent } from "app/shared/components";
import { useState, useContext } from "react";
import { UserLogged } from "app/shared/contexts";
import useIsMobile from "app/shared/hooks/useIsMobile";

const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

export const Carrinho = () => {

    const carrinhoClient = useCarrinho();
    const { carrinho, setCarrinho, loaderCarrinho, setLoaderCarrinho, mostrarCarrinho, setMostrarCarrinho, pedido } = useCarrinho();
    const totaldepontos = carrinhoClient.carrinho.reduce((totalPontos, carrinho) => totalPontos + Number(carrinho.total_brinde), 0);
    const { fetchCarrinho } = useCarrinho();
    const token = React.useContext(TokenContext);
    const { pontos, setPontos } = useCarrinho();
    const { userLogged, setUserLogged } = useContext(UserLogged);
    const [isChecked, setIsChecked] = useState(false);
    const [voltagem, setVoltagem] = useState(true);
    const Mobile = useIsMobile();
    const [observacao, setObservacao] = useState('');

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mx-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const removerItem = (id_brinde: number, pedidoCliente: number) => {
        const cnpj = localStorage.getItem('cliente_cnpj')
        setLoaderCarrinho(true);
        const fetchData = async () => {
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/v1/remover?cnpj=${cnpj}&id_pedido=${pedidoCliente}&id_brinde=${id_brinde}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,

                    },
                });

                console.log(response);
                if (response.status === 200) {
                    setLoaderCarrinho(true);
                    await fetchCarrinho();

                }


            } catch (error) {
                console.error('Erro ao alterar quantidade', error);
            }
        };

        fetchData();

    }

    const handleAlterarVoltagem = (id_brinde: number, pedidoCliente: number, voltagem: string) => {
        const cnpj = localStorage.getItem('cliente_cnpj')
        setLoaderCarrinho(true);
        const fetchData = async () => {
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/v1/voltagem_carrinho/?cnpj=${cnpj}&id_pedido=${pedidoCliente}&voltagem=${voltagem}&id_brinde=${id_brinde}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,

                    },
                });

                console.log(response);
                if (response.status === 200) {
                    setLoaderCarrinho(true);
                    await fetchCarrinho();

                }


            } catch (error) {
                console.error('Erro ao alterar quantidade', error);
            }
        };

        fetchData();

    }

    const FinalizarCarrinho = () => {
        const cnpj = localStorage.getItem('cliente_cnpj')
        const idpedido = carrinhoClient.pedido;

        swalWithBootstrapButtons.fire({
            title: 'Finalizar Carrinho',
            text: "Deseja finalizar o carrinho?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                carrinhoClient.carrinho.forEach(element => {
                    if (element.troca_voltagem === 'S' && (element.voltagem === null || element.voltagem === 'Selecionar')) {
                        console.log(element.voltagem);
                        Swal.fire(
                            'Escolha a voltagem <br>do(s) produto(s)',
                            '',
                            'error'
                        )
                        return;
                    }
                    const fetchData = async () => {
                        try {
                            const response = await axios.post(`https://data-api.agross.com.br/api/v1/confirmar/?cnpj=${cnpj}&id_pedido=${idpedido}&obs=${observacao}`, {}, {
                                headers: {
                                    Authorization: `Bearer ${token}`,

                                },
                            });

                            console.log(response);
                            if (response.status === 200) {
                                Swal.fire(

                                    'Pedido finalizado com Sucesso!',
                                    'o pedido ficará em análise',
                                    'success'
                                )
                            }


                        } catch (error) {
                            console.error('Erro ao finalizar carrinho', error);
                        }
                    };

                    fetchData();

                });
            }
        })



    }

    const alteraQtd = (quantidade: number, id_brinde: number, pedidoCliente: number) => {
        const cnpj = localStorage.getItem('cliente_cnpj')
        setLoaderCarrinho(true);


        const fetchData = async () => {
            try {
                const response = await axios.post(`https://data-api.agross.com.br/api/v1/quantidade?cnpj=${cnpj}&id_pedido=${pedidoCliente}&quantidade=${quantidade}&id_brinde=${id_brinde}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    // Encontre o índice do item no carrinho
                    const itemIndex = carrinho.findIndex(item => item.codbrinde === id_brinde);

                    // Faça uma cópia do carrinho
                    const newCarrinho = [...carrinho];

                    // Altere a quantidade do item
                    newCarrinho[itemIndex] = {
                        ...newCarrinho[itemIndex],
                        quantidade: quantidade,
                    };

                    // Atualize o carrinho
                    setCarrinho(newCarrinho);
                    await fetchCarrinho();

                    // Chama a função para atualizar o carrinho
                }
                console.log(response);
            } catch (error) {
                console.error('Erro ao alterar quantidade', error);
            }
        };

        fetchData();
    }

    const handleObservacaoChange = (event: any) => {
        setObservacao(event.target.value);
    };

    return (
        <div className=" d-flex flex-column">
            {userLogged ?
                <TitleComponent texto="Carrinho&nbsp; Troque&nbsp;e&nbsp;Ganhe" variant={Mobile ? "h4" : "h3"} />
                :
                ''}
            {!userLogged ?
                <Typography style={{ color: primary, fontSize: '0.7rem', textAlign: 'center' }} className="my-5">
                    Já possui cadastro?{' '}
                    <Link href="#" color="secondary"


                    >
                        Clique aqui
                    </Link>{' '}
                    e faça login!
                </Typography>
                :
                <div className={`d-flex ${Mobile ? 'flex-column' : ''}`} style={{ width: '100%' }}>
                    <div style={{ width: Mobile ? '100%' : '55%' }} className=" position-relative">
                        <div className={`loader ${loaderCarrinho ? 'd-flex' : 'd-none'}`}>
                            {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                            <img src={require('assets/loader.gif')} alt="loader" style={{ width: '10%' }} />
                        </div>
                        <div style={{
                            backgroundColor: 'white', width: '100%', maxHeight: '100vh',
                            overflow: 'overlay',
                            marginLeft: 'auto', marginRight: 'auto'
                        }} className='rounded-2'
                        >
                            {((carrinhoClient.carrinho.length > 0) && (carrinhoClient.carrinho[0].codbrinde !== null)) ? (
                                <div>

                                    {carrinhoClient.carrinho.map((carrinho) => (

                                        <div style={{ boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)', position: 'relative' }} className=' m-3 rounded-2 p-2'>

                                            <div>

                                                <DeleteForeverIcon
                                                    className='btndelete'
                                                    style={{ position: 'absolute', top: 2, right: 3, zIndex: 1, minWidth: 'auto', width: '2rem', height: '2rem', color: primary, cursor: 'pointer' }}
                                                    onClick={() => {
                                                        swalWithBootstrapButtons.fire({
                                                            title: 'Deletar item',
                                                            text: "Deseja deletar o item?",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Sim',
                                                            cancelButtonText: 'Não',
                                                            reverseButtons: true
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                removerItem(carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                            }
                                                        })

                                                    }}
                                                />

                                            </div>
                                            <div className={`d-flex justify-content-around align-items-center ${Mobile ? 'flex-column' : ''}`}>
                                                <div style={{ width: Mobile ? '100%' : '25%' }} className=" d-flex">
                                                    <img src={require(`assets/brindes/${carrinho.codbrinde}.jpg`)} alt="" style={{ height: Mobile ? '8rem' : '12rem', marginLeft: 'auto', marginRight: 'auto' }} />

                                                </div>
                                                <div style={{ width: Mobile ? '90%' : '60%' }} className=' d-flex justify-content-between flex-column'>

                                                    <div className=" my-2">
                                                        <Typography style={{ color: primary }} variant='subtitle2'>
                                                            {carrinho.brinde}
                                                        </Typography>
                                                    </div>
                                                    <div className=" my-2">
                                                        <Typography style={{ color: primary }} variant='subtitle2'>
                                                            Quantidade: {carrinho.quantidade}&nbsp;&nbsp;
                                                            <RemoveCircleIcon className='btnplusless'
                                                                onClick={() => {
                                                                    if (Number(carrinho.quantidade) === 1) {
                                                                        console.log(carrinho.quantidade);
                                                                        swalWithBootstrapButtons.fire({
                                                                            title: 'Deletar item',
                                                                            text: "Deseja deletar o item?",
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonText: 'Sim',
                                                                            cancelButtonText: 'Não',
                                                                            reverseButtons: true
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                removerItem(carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                            }
                                                                        })
                                                                    } else {
                                                                        alteraQtd((Number(carrinho.quantidade) - 1), carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                        console.log(carrinho.quantidade);
                                                                    }

                                                                }}
                                                            />
                                                            <AddCircleIcon className='btnplusless'
                                                                onClick={() => {
                                                                    if (Number(pontos) < Number(Number(totaldepontos) + Number(carrinho.pontos))) {
                                                                        Swal.fire(
                                                                            'Pontos Insuficientes',
                                                                            'Você não possui saldo o suficiente para trocar este brinde',
                                                                            'error'
                                                                        )
                                                                    } else {
                                                                        alteraQtd((Number(carrinho.quantidade) + 1), carrinho.codbrinde, Number(carrinho.id_solicitacao));
                                                                    }
                                                                }}
                                                            />

                                                        </Typography>
                                                    </div>
                                                    <div className=" my-2">

                                                        <Typography style={{ color: primary }} variant='subtitle2'>
                                                            {Number(carrinho.total_brinde).toLocaleString()} Pontos
                                                        </Typography>
                                                    </div>
                                                    <div className=" d-flex">
                                                        <div>
                                                            {["PNEU 13", "PNEU 14", "PNEU 15"].includes(carrinho.brinde) ? (
                                                                <div className=" d-flex">
                                                                    <Typography style={{ color: primary }} variant='subtitle2'>
                                                                        Perfil:&nbsp;
                                                                    </Typography>
                                                                    <Select
                                                                        labelId="demo-simple-select-standard-label"
                                                                        id="demo-simple-select-standard"
                                                                        value={(carrinho.voltagem === null) ? 'Selecionar' : carrinho.voltagem}
                                                                        label="Voltagem"
                                                                        variant="standard"
                                                                        style={{
                                                                            marginLeft: '0.5rem',
                                                                            height: '1rem',
                                                                            color: primary
                                                                        }}
                                                                        onChange={(event) => handleAlterarVoltagem(carrinho.codbrinde, Number(carrinho.id_solicitacao), event.target.value)}
                                                                    >
                                                                        <MenuItem value={'Selecionar'}>Selecionar</MenuItem>
                                                                        <MenuItem value={'110V'}>110V</MenuItem>
                                                                        <MenuItem value={'220V'}>220V</MenuItem>
                                                                    </Select>
                                                                </div>
                                                            ) : (
                                                                <Typography style={{ color: primary }} variant='subtitle2'>
                                                                    Voltagem:&nbsp;{(carrinho.troca_voltagem === 'N') ? 'Indisponível' : ''}
                                                                </Typography>
                                                            )}
                                                        </div>

                                                        <div>
                                                            {(carrinho.troca_voltagem === 'N') ? '' :
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    value={(carrinho.voltagem === null) ? 'Selecionar' : carrinho.voltagem}
                                                                    label="Voltagem"
                                                                    variant="standard"
                                                                    style={{
                                                                        marginLeft: '0.5rem',
                                                                        height: '1rem',
                                                                        color: primary
                                                                    }}
                                                                    onChange={(event) => handleAlterarVoltagem(carrinho.codbrinde, Number(carrinho.id_solicitacao), event.target.value)}

                                                                >
                                                                    <MenuItem value={'Selecionar'}>Selecionar</MenuItem>
                                                                    <MenuItem value={'110V'}>110V</MenuItem>
                                                                    <MenuItem value={'220V'}>220V</MenuItem>
                                                                </Select>}
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            ) : (

                                <Typography style={{ color: primary, backgroundColor: bege }} className='p-4 m-3'>
                                    Ops... Seu carrinho está vazio!
                                </Typography>

                            )}
                        </div>
                    </div>
                    <div style={{ width: Mobile ? '100%' : '45%', padding: '1rem', position: 'relative' }} className={mostrarCarrinho ? 'd-flex' : 'd-none'}>
                        <div className={`loader ${loaderCarrinho ? 'd-flex' : 'd-none'}`}>
                            {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                            <img src={require('assets/loader.gif')} alt="loader" style={{ width: '10%' }} />
                        </div>
                        <div style={{ width: '100%', border: '1px solid gray', borderRadius: '0.4rem' }}>
                            <div style={{ borderBottom: '1px solid gray' }} className=" p-2">
                                Total da Solicitação: {(totaldepontos).toLocaleString()}
                            </div>
                            <div style={{ borderBottom: '1px solid gray' }} className=" p-2">

                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Observação:"
                                    multiline
                                    style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
                                    onChange={handleObservacaoChange}                                   
                                    rows={5}
                                    className='my-3'
                                    
                                />
                            </div>
                            <div className=" p-2 d-flex flex-column mb-2">
                                Regulamento:
                                <div style={{
                                    backgroundColor: 'white', width: '92%', maxHeight: '50vh',
                                    overflow: 'overlay',
                                    marginLeft: 'auto', marginRight: 'auto'
                                }} className='rounded-2 my-2'
                                >
                                    <p className=" text-center"><b> REGULAMENTO DO PROGRAMA TROQUE E GANHE AGROSS.</b></p>
                                    <p><b>DO OBJETO DO PROGRAMA “TROQUE E GANHE”.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 1ª. )</b> A promoção AgRoss Troque e Ganhe é um programa de fidelidade que oferece pontos por cada compra realizada dos produtos participantes da promoção, que podem ser trocados por prêmios a escolha do cliente.</p>
                                    <p><b>DOS REQUISITOS PARA PARTICIPAR DA PROMOÇÃO.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 2ª.)</b>Para ser participante do programa, é necessário que seja observado os seguintes requisitos:</p>
                                    <p ><b>a)</b> Realizar compras dos produtos participantes da promoção da AgRoss;</p>
                                    <p><b>b)</b> Não estar inadimplente com a AgRoss;</p>
                                    <p><b>c)</b> Não ter a “empresa cliente” encerrado suas atividades.</p>
                                    <p style={{ textAlign: 'justify' }}><b>d)</b> Ser um cliente ativo, ou seja, realizando pelo menos uma compra a cada 90 (noventa) dias, com valor mínimo de R$ 10.000,00 (dez mil reais).</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 3ª.)</b> Caso o cliente perca qualquer um destes requisitos, perderá o direito de participar do programa. A tolerância de descumprimento de quaisquer cláusulas ou condições do presente regulamento deverá ser entendida como mera liberalidade, jamais produzindo a perda do direito de exigir o cumprimento de todos os requisitos previstos no presente. </p>
                                    <p><b>DA PONTUAÇÃO.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 4ª.)</b> A proporção dos pontos será distribuída da seguinte forma:</p>
                                    <p><b>a)</b> 2 pontos para cada 1 (um) real em produtos comprados da série Normal;</p>
                                    <p><b>b)</b> 6 pontos para cada 1 (um) real em produtos comprados da série Ouro;</p>
                                    <p><b>c)</b> 12 pontos para cada 1 (um) real em produtos comprados da série Diamante.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 5ª.)</b> Sendo que a descrição dos produtos que compõem cada uma das séries, será sempre apresentada e atualizados frequentemente via site, e os mesmos poderão sofrer alterações a qualquer momento.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 6ª.)</b>   Os pontos conquistados pelos clientes estarão disponíveis para troca, somente após o pagamento de todas as parcelas da(s) Nota(s) fiscal(is) que gerou a pontuação.</p>
                                    <p style={{ textAlign: 'justify' }}><b>a)</b> Caso haja devolução de quaisquer mercadorias adquiridas participantes do programa, o cliente AgRoss perderá os pontos referente a essa devolução, sendo estes pontos debitados do seu saldo adquirido no programa.</p>
                                    <p><b>DO ACUMULO DOS PONTOS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 7ª.)</b> O cliente poderá acumular seus pontos até atingir a pontuação necessária, para trocar pelos prêmios escolhidos. Para que o cliente tenha o controle da sua pontuação, no momento da entrega da mercadoria irá receber um extrato de pontos, já inclusos os pontos da entrega realizada, sendo que estes pontos somente estarão disponíveis para utilização, após o pagamento das parcelas correspondentes da(s) nota(s) fiscais(s).</p>
                                    <p style={{ textAlign: 'justify' }}>Poderá ainda, consultar seus pontos pelo site da promoção, www.troqueeganhe.com.br, mediante cadastro com login e senha previamente definidos, ou ainda poderá utilizar o e-agross.</p>
                                    <p style={{ textAlign: 'justify' }}><b>a)</b> Os pontos serão acumulados, por CNPJ, ou seja, não poderá haver a somatória de pontos entre clientes distintos.</p>
                                    <p style={{ textAlign: 'justify' }}><b>b)</b> A única possibilidade de acumulo de pontos será entre matriz e filiais, sendo que para que ocorra o cliente deverá solicitar via e-mail (troqueeganhe@agross.com.br). A unificação ocorrerá somente para aquele pedido. Dessa forma, toda vez que optar pela juntada dos pontos, o cliente deverá realizar a solicitação para a AgRoss. Considerando ainda, que só é possível juntar os pontos "Disponíveis" ,os pontos "bloqueados" ficam retidos até a quitação das notas fiscais que geraram os mesmos.</p>
                                    <p style={{ textAlign: 'justify' }}><b>c)</b> Quando houver a troca de edição da REVISTA, o cliente que tiver pontos da edição anterior, poderá utilizá-los, mas seguindo a nova pontuação.</p>
                                    <p><b>DOS PRÊMIOS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 8ª.)</b> Os prêmios que são apresentados na revista, no site estão representados por meio de imagens ilustrativas, sendo assim no momento do recebimento, pode ocorrer variações quanto a cores, modelos, marcas e tamanhos, conforme a disponibilidade do mercado.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 9ª.)</b> Caso algum dos prêmios apresentados na revista deixe de ser fabricado ou não seja mais encontrado no mercado, o Cliente poderá utilizar os seus pontos para escolher outro prêmio de igual pontuação.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo Único –</b> Os produtos e viagens participantes da promoção são específicos, não podendo ser solicitados produtos fora da campanha, (Revista TG, site TG).</p>
                                    <p><b>DA DURAÇÃO.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 10ª.)</b> O programa não tem data de validade determinada. Mas está vinculado as condições que poderão ser alteradas diretamente no site, a qualquer momento, são elas: os produtos participantes do programa, os prêmios oferecidos e a pontuação necessária para cada item. No caso de encerramento por definitivo do programa, os pontos já acumulados não serão perdidos, desde que o cliente esteja enquadrado nos requisitos da cláusula 2ª. E que a troca dos pontos acumulados, seja realizada dentro do prazo estipulado pela AgRoss. Caso não seja observado o prazo indicado, o cliente perderá a pontuação acumulada, não tendo direito a reclamações posteriores.</p>
                                    <p><b>DA TROCA DA PONTUAÇÃO PELO PRÊMIO.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 11ª.)</b> Após alcançada a pontuação necessária para troca de prêmios, o cliente deverá solicitar a troca via site, seguindo todo o procedimento indicado para finalizar a troca e inclusive aceitando os termos desse regulamento.</p>
                                    <p><b>DA ENTREGA DOS PRÊMIOS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 12ª.)</b> O prazo para ser entregue o prêmio será, em média de 30 dias, podendo sofrer variações de acordo com o produto solicitado e a disponibilidade do produto no mercado. Este prazo se inicia após o cliente ter concluído o procedimento estabelecido na cláusula 10ª.</p>
                                    <p style={{ textAlign: 'justify' }}><b>a)</b>      Reforçamos ainda, que as entregas não dependem exclusivamente da AgRoss, desta forma, o prazo de entrega poderá variar próximo a datas comemorativas, como natal, feriados e etc, conforme a disponibilidade das empresas de entregas. Portanto os pedidos devem ser realizados antecipadamente neste período.</p>
                                    <p style={{ textAlign: 'justify' }}><b>b)</b>      O endereço da entrega deverá ser o endereço comercial do “Cliente Agross”, caso esta regra não seja respeitada, a Agross se eximi de qualquer responsabilidade quanto a problemas de entrega e não irá arcar com nenhum tipo de indenização, inclusive se houver extravio destes prêmios.</p>
                                    <p style={{ textAlign: 'justify' }}><b>c)</b>       O prêmio deverá ser conferido no momento da entrega, sendo que a AgRoss só aceitará reclamações de desconformidade, no prazo de 7 (sete) dias, não se responsabilizando por quaisquer danos ou avarias que ocorrerem, após este prazo e nem por defeitos que surgirem, devendo o “Cliente AgRoss” premiado seguir as instruções de uso do fabricante do produto para garantir a qualidade de seu funcionamento.</p>
                                    <p><b>DA SEGURANÇA.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 13ª.)</b> Para que a troca de pontos por prêmios, ocorra de uma forma segura, existirá apenas uma forma de realizar essa troca, que será da seguinte maneira:</p>
                                    <p style={{ textAlign: 'justify' }}><b>a)</b> O “CLIENTE AGROSS” precisa se cadastrar diretamente no site Troque e Ganhe www.troqueeganhe.com.br e criar sua senha, portanto a AgRoss não obterá acesso a esta senha, sendo de inteira responsabilidade do representante legal do cliente o uso desta senha que é pessoal e intransferível.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo primeiro: </b>Caso o cliente informe a sua senha para terceiros e o mesmo solicite prêmios. A AgRoss não se responsabilizará e não concederá novamente os pontos perdidos.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo segundo:</b> No caso de perda da senha para realizar o login no site, o cliente deverá marcar a opção esqueci minha senha e a mesma será enviada para o e-mail constante no cadastro realizado.</p>
                                    <p><b>DAS DISPOSIÇÕES GERAIS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 14ª.)</b> Qualquer eventualidade ou fato omisso que não se enquadre nesse regulamento, ficará sobre a apreciação e julgamento da AgRoss ,devendo ser acatada a decisão em caráter inquestionável por todos os participantes.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 15ª.)</b> Todas as imagens dos produtos e viagens são meramente ilustrativas, podendo sofrer alterações de marca, modelo, cor, configurações entre outros, sendo a escolha do produto realizada pela AgRoss e pela disponibilidade do fornecedor.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 16ª.)</b> O programa AgRoss Troque e Ganhe, é uma campanha que tem o único objetivo de premiar nossos clientes mediante a troca de pontos pelo prêmio específico, podendo ser cancelada ou prorrogada a qualquer momento pela AgRoss, conforme este regulamento.</p>
                                    <p><b>ANEXO 1. DO REGULAMENTO DO PROGRAMA TROQUE E GANHE.</b></p>
                                    <p><b>DAS VIAGENS DISPONIBILIZADAS COMO PRÊMIO PELA AGROSS:</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 1ª.)</b> As viagens que a AgRoss está disponibilizando para o Programa TROQUE E GANHE, são apenas os pacotes disponíveis na revista, no site e a pontuação apresentada é para 2 (DUAS pessoas) em apartamento duplo, sujeitas a disponibilidade de datas e vagas.</p>
                                    <p><b>DA RESPONSABILIDADE DA AGROSS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 2ª.)</b> A única responsabilidade da AgRoss mediante a solicitação do cliente para a troca dos seus pontos acumulados no programa Troque e Ganhe é contratar a empresa que irá oferecer o serviço de acordo com viagem escolhida por nosso cliente</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo primeiro:</b> A AgRoss não tem nenhuma responsabilidade sobre qualquer tipo de acidente ou incidente, tais como: doenças, roubo, perda de objetos, falta de transporte do passageiro, perca de voos. Em HIPÓTESE ALGUMA, a AgRoss se responsabilizará por qualquer tipo de indenização sobre acidentes e incidentes ocorridos após a contratação da viagem</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo segundo:</b> Após, o cliente ter realizada a opção de troca de seus pontos por viagem, a AgRoss não reembolsará os pontos, caso a viagem não ocorra, sendo assim e de inteira responsabilidade dos passageiros a verificação de todos os itens obrigatórios para a realização da viagem, tais como: documentos dentro do prazo de validade, vacinas necessárias, horário do voo, bem como quaisquer outras exigências necessárias para a realização da Viagem.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo terceiro:</b> A AgRoss se compromete a contratar empresas de viagens, que prestem serviço com idoneidade reconhecida no mercado, mas caso uma dessas empresas não cumpra com suas obrigações, a responsabilidade pelo serviço contratado e não prestado devidamente será de total responsabilidade da empresa de viagens contratada.</p>
                                    <p><b>DO PERÍODO DE VIAGEM.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 3ª.)</b> Não é permitido que o cliente estenda o tempo da viagem, mesmo que ele pague com seus recursos os dias excedentes.</p>
                                    <p><b>DO ROTEIRO DA VIAGEM.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 4ª.)</b> A AgRoss se reserva o direito de cancelar, seja qual for a viagem, do Programa Troque e Ganhe, desde que a empresas de viagens não ofereçam mais o roteiro escolhido ou por motivo de força maior, conflitos, impossibilidade de viagem para o lugar escolhido por falta de documentação específica, cancelamento de voos para a região ou qualquer outra questão que a AgRoss acredite que possa oferecer RISCO EM SUA IMAGEM INSTITUCIONAL ou ao programa Troque e Ganhe. Caso algum cliente, tenha solicitado a troca para o destino que saiu do programa de pontos, será informado previamente para que escolha um novo destino dentro das opções válidas.</p>
                                    <p><b>DO PRAZO PARA A SOLICITAÇÃO DA VIAGEM.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 5ª.)</b> A data da viagem será de acordo com a disponibilidade de vagas na empresa turística e a solicitação da viagem pelo cliente deverá cumprir o prazo de 60 dias de antecedência no mínimo. Ressalvado os períodos de alta temporada, em que o prazo mínimo para solicitar a viagem deverá ser com 180 dias de antecedência.</p>
                                    <p><b>DAS CONDIÇÕES GERAIS.</b></p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 6ª.)</b> Todos os pacotes de viagens oferecidas no catálogo AgRoss Troque e Ganhe, terão suas especificações definidas no mesmo, não podendo ser alteradas, apenas alguns destinos contarão com a opção de resort, os quais serão devidamente indicados, seja na revista Troque e Ganhe, no site ou no e-AgRoss. Os destinos que não constarem em sua descrição a especificação de Resort serão de categoria Turística, em apartamentos standard duplo. Não haverá exceções para a troca de categoria.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo único:</b> A pontuação apresentada na revista se refere apenas a uma média dos pontos que serão necessárias para a viagem apresentada, podendo sofrer variações para mais ou menos pontos, dependendo das opções escolhidas pelo cliente, que poderão variar quanto ao período da viagem, escolha do hotel, temporada, opcionais e outras. Desta forma, o cliente deverá passar suas opções para que o departamento de Marketing da AgRoss possa contabilizar os pontos necessários.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 7ª.)</b> A AgRoss não oferece qualquer quantia em dinheiro, transportes EXTRAS ou ingressos para shows, festas, boates, bares e outros, sendo repassado ao cliente apenas a viagem escolhida com suas respectivas datas, roteiro, translado e hospedagem.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Parágrafo único:</b> A AgRoss não se responsabiliza ainda, por quaisquer tipos de taxas ou encargos, inclusive seguro viagem, bagagens, marcação de assento, taxas ambientais ou de turismo entre outras.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 8ª.)</b> Exclusivamente para viagens, antes de realizar o procedimento de troca, o “Cliente Agross” deverá entrar em contato com o departamento de marketing, para receber as informações e opções sobre a viagem escolhida, e só após ter concordado com as especificações passadas, que deverá realizar o procedimento de troca, conforme cláusula 10°deste regulamento.</p>
                                    <p style={{ textAlign: 'justify' }}><b>Cláusula 9ª.)</b> Caso haja cancelamento ou alteração da data de viagem motivada por questões de saúde pública, tais como a pandemia do COVID19, a AgRoss não se responsabiliza por nenhum tipo de indenização, mas se compromete a encontrar a melhor solução para o cliente AgRoss, para que o mesmo não seja prejudicado.</p>
                                </div>
                                <FormControlLabel required control={
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                } label="Eu aceito e concordo com o regulamento do Troque e Ganhe AgRoss." style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                <Button
                                    variant="contained"
                                    size="small"
                                    style={{ color: 'white', border: `1px solid ${primary}`, width: '70%', marginLeft: 'auto', marginRight: 'auto' }}
                                    disabled={!isChecked}
                                    onClick={FinalizarCarrinho}
                                >
                                    Finalizar Carrinho
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}