import { Carouselag, Depoimentos, Fornecedores } from "app/shared/components/index"
import './Agross.css'
import imgvantagens from 'app/shared/imagens/imgvantagens.jpg'
import separador from 'app/shared/imagens/separador.png'
import { Typography } from "@mui/material"
import { lightGreen } from '@mui/material/colors';
import { ReactComponent as Caixa } from 'assets/caixa.svg';
import { ReactComponent as Caminhao } from 'assets/caminhao.svg';
import { ReactComponent as Brinde } from 'assets/brinde.svg';
import { ReactComponent as Atendimento } from 'assets/atendimento.svg';
import { ReactComponent as Eagross } from 'assets/eagross.svg';
import { ReactComponent as Telefone } from 'assets/telefone.svg';
import { ReactComponent as Troqueeganhe } from 'assets/troqueeganhe.svg';
import { ReactComponent as Tvagross } from 'assets/tvagross.svg';
import Button from '@mui/material/Button';
import { Element } from 'react-scroll';
import { useEffect } from 'react';
import { useMenuContext } from "app/shared/contexts/index";




import dep01 from 'assets/depoimentos/01.png';
import dep02 from 'assets/depoimentos/02.png';
import dep03 from 'assets/depoimentos/03.png';
import dep04 from 'assets/depoimentos/04.png';
import dep05 from 'assets/depoimentos/05.png';
import dep06 from 'assets/depoimentos/06.png';
import dep07 from 'assets/depoimentos/07.png';
import dep08 from 'assets/depoimentos/08.png';






import useIsMobile from "app/shared/hooks/useIsMobile"




const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

const testimonials = [
    {
        name: 'Mário - Agrima',
        city: 'Domingos Martins - ES',
        message: 'A diversificação de produtos, o pronto atendimento e a entrega semanal, faz da AgRoss a certeza do menor caminho da indústria ao produtor, passando pela assistência técnica da revenda',
        photoUrl: dep01,
    },
    {
        name: 'Airton - Agrícola Zalamena',
        city: 'Campo Novo – RS',
        message: '"Parabenizamos a AgRoss pela iniciativa de expandir seus negócios para o município de Carazinhos/RS, pelo ótimo atendimento, amplo portfólio de produtos, e também pela agilidade na entrega dos pedidos. Por estes motivos, nós da Agrícola Zalamena, somos parceiros da AgRoss."',
        photoUrl: dep02,
    },
    {
        name: 'Eugênio - Agropecuária Manenti',
        city: 'Nova Esperança do Sul – RS',
        message: '"Estamos muito felizes e satisfeitos com a vinda da AgRoss. Um jeito inovador de fazer negócios. A opção de poder comprar produtos variados e sem quantidade mínima e com entrega super-rápida, vem de encontro com a nossa necessidade."',
        photoUrl: dep03,
    },
    {
        name: 'Juarez - Agrofertil Agrícola',
        city: 'Cabo Verde – MG',
        message: '"Agradeço à toda equipe da Agross pelo ótimo atendimento, seu amplo portfólio atendendo todas as culturas e em especial a cultura de café que predomina a região em que atuamos, tendo um diferencial muito grande que é a agilidade nas entregas."',
        photoUrl: dep04,
    },
    {
        name: 'Geárlei - Vendagro Insumos',
        city: 'Careaçu – MG',
        message: '"A AgRoss é sensacional. Nos oferece o maior portfólio de defensivos do mercado, seu atendimento é diferenciado. Tendo maior suporte antes, dentro e pós porteira. Além de treinamentos e palestras. Sua agilidade no transporte, contribui no elo das cadeias produtivas. Não concorre conosco no campo, pelo contrário temos o maior suporte e força de negociação, garantindo uma maior rentabilidade, alcançando produtividade gerando lucratividade para nossos amigos clientes. Seu modelo de sinceridade e honestidade, faz com que nos tornamos parceiros da Agross."',
        photoUrl: dep05,
    },
    {
        name: 'Camilo - José Camilo Machado',
        city: 'Pouso Alegre – MG',
        message: '"Esse ano estamos completando 10 anos de parceria com à agross, e isso sim que é parceria. Uma empresa que valoriza as pequenas revendas trabalho com dedicação e compromisso. Temos um relacionamento muito bom com os cooperadores desde dos ajudantes da entrega até o gerente, relacionamento esse que nos dá confiança e segurança para continuarmos a trabalhar juntos. Somos muito gratos por toda essa história que formamos juntos. Muito obrigado parceiros e amigos da agross!!!!!"',
        photoUrl: dep06,
    },
    {
        name: 'Roni e Samuel - Adubos Terra Fertil',
        city: 'Maria da Fé – MG',
        message: '"É uma satisfação e orgulho ter como fornecedor a Agross, podemos sempre contar com uma equipe técnica de grande qualidade e o mais importante um amplo portfólio de produtos. Por isso nós da Adubos terra Fértil temos orgulho em dizer que somos grandes parceiros da Agross."',
        photoUrl: dep07,
    },
    {
        name: 'Luiz - L A Cezar Insumos',
        city: 'Maria da Fé – MG',
        message: '"Com muita satisfação, nossa equipe parabeniza a Agross pelo seu excelente trabalho. À Agross sempre está a disposição para melhor atender seus parceiros com uma logística organizada e pontual. Além, de todas as quintas proporcionar conhecimento e entretenimento para seus clientes com a TvAgross."',
        photoUrl: dep08,
    },
];

export const Agross = () => {
    const Mobile = useIsMobile();
    const { scrollToDepoimentos, setScrollToDepoimentos } = useMenuContext();

    useEffect(() => {
        if (scrollToDepoimentos) {
            const element = document.getElementById('depoimentos');
            if (element) {
                window.scrollTo({
                    top: element.getBoundingClientRect().top + window.pageYOffset - 80,
                    behavior: 'smooth',
                });
            }
            setScrollToDepoimentos(false);
        }
    }, [scrollToDepoimentos, setScrollToDepoimentos]);

    return (
        <div>
            {/* Carousel AgRoss */}
            <Carouselag />

            {/* Vantagens Cliente AgRoss */}
            {Mobile ?
                <div className="divvantagens my-5 py-5 d-flex flex-column align-items-center">
                    <div style={{ width: "80%", textAlign: "right" }}>
                        <img src={imgvantagens} alt="" width={'100%'} />
                    </div>
                    <div style={{ width: "90%", textAlign: "left", color: `${primary}` }} className="px-1">
                        <div className=" d-flex flex-column mt-5">
                            <Typography style={{ textAlign: 'center' }} variant="body2">
                                VANTAGENS DE SER
                            </Typography>
                            <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }}>
                                Cliente AgRoss
                            </Typography>
                            <img src={separador} alt="" width={70} style={{ margin: 'auto' }} />
                            <Typography className="w-100 mt-5" style={{ textAlign: 'justify' }}>
                                A AgRoss oferece o maior portfólio de defensivos agrícolas do
                                mercado, trabalhando com os melhores fabricantes, garantindo
                                procedência e atendimento exclusivo para revendas.
                            </Typography>
                            <div className="d-flex justify-content-between mt-4 w-100" style={{ color: verdeescuro, margin: 'auto' }}>
                                <div style={{ margin: 'auto' }}>
                                    <Typography >
                                        <Caixa className="icones" /> Confiabilidade e segurança
                                    </Typography>
                                    <Typography>
                                        <Telefone className="icones" /> Múltiplos canais de atendimento
                                    </Typography>
                                    <Typography>
                                        <Brinde className="icones" /> Grandes Eventos
                                    </Typography>
                                    <Typography>
                                        <Troqueeganhe className="icones" /> Programa de Fidelidade
                                    </Typography>
                                    <Typography>
                                        <Caminhao className="icones" /> Entrega em até 48h
                                    </Typography>
                                    <Typography>
                                        <Atendimento className="icones" /> Orientação técnica exclusiva
                                    </Typography>
                                    <Typography>
                                        <Eagross className="icones" /> Plataforma interativa exclusiva
                                    </Typography>
                                    <Typography>
                                        <Tvagross className="icones" /> Tv AgRoss - Ao vivo
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="divvantagens my-5 py-5 d-flex justify-content-between align-items-center">
                    <div style={{ width: "42%", textAlign: "right", marginLeft: "2rem" }}>
                        <img src={imgvantagens} alt="" width={'100%'} />
                    </div>
                    <div style={{ width: "60%", textAlign: "left", color: `${primary}` }} className="px-5">
                        <div>
                            <Typography>
                                VANTAGENS DE SER
                            </Typography>
                            <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: verdeescuro }}>
                                Cliente AgRoss
                            </Typography>
                            <img src={separador} alt="" />
                            <Typography className="w-75 mt-5" style={{ textAlign: 'justify' }}>
                                A AgRoss oferece o maior portfólio de defensivos agrícolas do
                                mercado, trabalhando com os melhores fabricantes, garantindo
                                procedência e atendimento exclusivo para revendas.
                            </Typography>
                            <div className="d-flex  mt-4" style={{ color: verdeescuro }}>
                                <div>
                                    <Typography >
                                        <Caixa className="icones" /> Confiabilidade e segurança
                                    </Typography>
                                    <Typography>
                                        <Telefone className="icones" /> Múltiplos canais de atendimento
                                    </Typography>
                                    <Typography>
                                        <Brinde className="icones" /> Grandes Eventos
                                    </Typography>
                                    <Typography>
                                        <Troqueeganhe className="icones" /> Programa de Fidelidade
                                    </Typography>
                                </div>
                                <div className="mx-5">
                                    <Typography>
                                        <Caminhao className="icones" /> Entrega em até 48h
                                    </Typography>
                                    <Typography>
                                        <Atendimento className="icones" /> Orientação técnica exclusiva
                                    </Typography>
                                    <Typography>
                                        <Eagross className="icones" /> Plataforma interativa exclusiva
                                    </Typography>
                                    <Typography>
                                        <Tvagross className="icones" /> Tv AgRoss - Ao vivo
                                    </Typography>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* FIM Vantagens Cliente AgRoss */}

            {/* Portfólio */}
            {Mobile ?
                <div style={{ backgroundColor: bege, color: primary }} className="mb-5 py-5 px-5">
                    <div className=" d-flex flex-column">
                        <Typography variant="body1" style={{ textAlign: 'center' }}>
                            TODOS OS PRODUTOS DO
                        </Typography>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center' }} className="mt-2">
                            Portfólio AgRoss
                        </Typography>
                        <img src={separador} alt="" className=" m-auto" />
                    </div>

                    <div className=" d-flex justify-content-between divportfolio my-5 flex-column" style={{ textAlign: "center" }} >
                        <div className="divimg my-3" style={{ backgroundColor: 'white', borderRadius: '0.5rem', width: '100%' }}>
                            <div className="divtotal">
                                <div style={{ width: '100%', padding: '2rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h6">
                                        MARCA AGROSS
                                    </Typography>
                                </div>
                                <div>
                                    <img src={require('assets/portfolio/marcaagross.jpg')} alt="" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>

                        <div className="divimg my-3" style={{ backgroundColor: 'white', borderRadius: '0.5rem', width: '100%' }}>
                            <div className="divtotal">
                                <div style={{ width: '100%', padding: '2rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h6">
                                        DEFENSIVOS
                                    </Typography>
                                </div>
                                <div>
                                    <img src={require('assets/portfolio/defensivos.jpg')} alt="" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>

                        <div className="divimg my-3" style={{ backgroundColor: 'white', borderRadius: '0.5rem', width: '100%' }}>
                            <div className="divtotal">
                                <div style={{ width: '100%', padding: '2rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h6">
                                        PULVERIZADORES
                                    </Typography>
                                </div>
                                <div>
                                    <img src={require('assets/portfolio/pulverizadores.jpg')} alt="" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>

                        <div className="divimg my-3" style={{ backgroundColor: 'white', borderRadius: '0.5rem', width: '100%' }}>
                            <div className="divtotal">
                                <div style={{ width: '100%', padding: '2rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h6">
                                        SEMENTES
                                    </Typography>
                                </div>
                                <div>
                                    <img src={require('assets/portfolio/sementes.jpg')} alt="" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                :
                <div style={{ backgroundColor: bege, color: primary }} className="mb-5 py-5 px-5">
                    <div className=" text-lg-center ">
                        <Typography>
                            TODOS OS PRODUTOS DO
                        </Typography>
                        <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: verdeescuro }} className="mt-2">
                            Portfólio AgRoss
                        </Typography>
                        <img src={separador} alt="" />
                    </div>

                    <div className=" d-flex justify-content-between divportfolio my-5" style={{ textAlign: "center" }} >
                        <div className="divimg" style={{ backgroundColor: 'white', borderRadius: '0.5rem', width: '23%' }}>
                            <div className="divtotal">
                                <div style={{ width: '100%', padding: '2rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h6">
                                        MARCA AGROSS
                                    </Typography>
                                </div>
                                <div>
                                    <img src={require('assets/portfolio/marcaagross.jpg')} alt="" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>

                        <div className="divimg" style={{ backgroundColor: 'white', borderRadius: '0.5rem', width: '23%' }}>
                            <div className="divtotal">
                                <div style={{ width: '100%', padding: '2rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h6">
                                        DEFENSIVOS
                                    </Typography>
                                </div>
                                <div>
                                    <img src={require('assets/portfolio/defensivos.jpg')} alt="" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>

                        <div className="divimg" style={{ backgroundColor: 'white', borderRadius: '0.5rem', width: '23%' }}>
                            <div className="divtotal">
                                <div style={{ width: '100%', padding: '2rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h6">
                                        PULVERIZADORES
                                    </Typography>
                                </div>
                                <div>
                                    <img src={require('assets/portfolio/pulverizadores.jpg')} alt="" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>

                        <div className="divimg" style={{ backgroundColor: 'white', borderRadius: '0.5rem', width: '23%' }}>
                            <div className="divtotal">
                                <div style={{ width: '100%', padding: '2rem' }}>
                                    <Typography fontFamily={'Arima Madurai'} variant="h6">
                                        SEMENTES
                                    </Typography>
                                </div>
                                <div>
                                    <img src={require('assets/portfolio/sementes.jpg')} alt="" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            }

            {/* FIM Portfólio */}

            {/* Fornecedores */}
            {Mobile ?
                <div style={{ color: primary }} className="mb-5 py-5 px-5">
                    <div className=" d-flex flex-column text-center">
                        <Typography variant="body2">
                            NOSSOS PRINCIPAIS
                        </Typography>
                        <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro }} className="mt-2">
                            Fornecedores
                        </Typography>
                        <img src={separador} alt="" className=" m-auto" />
                        <Fornecedores />
                    </div>
                </div>
                :
                <div style={{ color: primary }} className="mb-5 py-5 px-5">
                    <div className=" text-lg-center ">
                        <Typography>
                            NOSSOS PRINCIPAIS
                        </Typography>
                        <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: verdeescuro }} className="mt-2">
                            Fornecedores
                        </Typography>
                        <img src={separador} alt="" />
                        <Fornecedores />
                    </div>
                </div>
            }
            {/* FIM Fornecedores */}

            {/* Faixa Tv */}
            <div className="divchamadatv d-flex align-items-lg-center " style={{ width: '100%', height: 'auto', padding: "7rem" }}>
                <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                    <a href="/tvagross">
                        <img src={require('assets/tvagross/linkfaixatv.gif')} alt="" />
                    </a>
                    <Typography style={{ color: 'white' }} className="my-4">
                        ASSISTA A TV AGROSS E
                    </Typography>
                    <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: 'white' }} className="mt-2">
                        Concorra a Prêmios
                    </Typography>

                </div>

            </div>
            {/* FIM Faixa Tv */}

            {/* Depoimentos */}
            {Mobile ?
                <Element name="depoimentos" id="depoimentos">
                    <div className=" d-flex flex-column" style={{ backgroundColor: bege, color: primary, paddingBottom: "3rem", paddingTop: "3rem" }}>
                        <div style={{ width: '100%' }} className=" d-flex flex-column">
                            <Typography className=" m-auto">
                                DEPOIMENTOS
                            </Typography>
                            <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro, textAlign: 'center', width: '90%', margin: 'auto' }}>
                                O que os nossos clientes&nbsp;dizem
                            </Typography>
                            <img src={separador} alt="" className=" m-auto" />
                        </div>
                        <div style={{ width: '90%', margin: 'auto' }} className="mt-4">
                            <Depoimentos testimonials={testimonials} />
                        </div>
                    </div>
                </Element>
                :
                <Element name="depoimentos" id="depoimentos">
                    <div className=" d-flex justify-content-center" style={{ backgroundColor: bege, color: primary, paddingBottom: "3rem", paddingTop: "3rem" }}>
                        <div style={{ width: '30%' }}>
                            <Typography>
                                DEPOIMENTOS
                            </Typography>
                            <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: verdeescuro }}>
                                O que os nossos clientes&nbsp;dizem
                            </Typography>
                            <img src={separador} alt="" />
                        </div>
                        <div style={{ width: '40%' }}>
                            <Depoimentos testimonials={testimonials} />
                        </div>
                    </div>
                </Element>
            }
            {/* FIM Depoimentos */}

            {/* Troque e Ganhe */}
            {Mobile ?
                <div className="divvantagens my-5 py-5 d-flex justify-content-between align-items-center flex-column">
                    
                    <div style={{ width: "90%", textAlign: "left", color: `${primary}` }} className="my-4">
                        <div className="px-5 flex-column d-flex">
                          
                            <img src={require('assets/tg/tglogo.png')} alt="" width={'70%'} className=" m-auto" style={{cursor: 'pointer'}}
                            onClick={() => window.open('/troqueeganhe', '_self')}/>

                            <Typography fontFamily={'Arima Madurai'} variant="h4" style={{ color: verdeescuro }} className="mt-4 text-center">
                                Conheça o nosso <br></br>programa de fidelidade <br></br>Troque e Ganhe
                            </Typography>
                            <img src={separador} alt="" className=" m-auto"/>
                            <Typography className="w-100 mt-5" style={{ textAlign: 'justify' }}>
                                O maior e melhor programa de fidelidade do segmento agrícola, com 20 anos
                                de tradição, presenteando os clientes com produtos diferenciados, serviços
                                exclusivos e viagens incríveis, realizando sonhos de todas as famílias que formam
                                a base do mercado de revendas agrícolas.
                            </Typography>
                            <Button size="small" variant="contained" className="mt-4" onClick={() => window.open('/brindes', '_self')}>Troque por Brindes</Button>
                        </div>
                    </div>
                </div>
                :
                <div className="divvantagens my-5 py-5 d-flex justify-content-between align-items-center">
                    <div style={{ width: "50%", textAlign: "right" }}>
                        <img src={require('assets/tg/tgpremios.png')} alt="" width={'90%'} />
                    </div>
                    <div style={{ width: "50%", textAlign: "left", color: `${primary}` }} className="px-5">
                        <div className="px-5">
                            <div>
                            </div>
                            <img src={require('assets/tg/tglogo.png')} alt="" width={'30%'} style={{cursor: 'pointer'}}
                            onClick={() => window.open('/troqueeganhe', '_self')}/>

                            <Typography fontFamily={'Arima Madurai'} variant="h3" style={{ color: verdeescuro }} className="mt-4">
                                Conheça o nosso <br></br>programa de fidelidade <br></br>Troque e Ganhe
                            </Typography>
                            <img src={separador} alt="" />
                            <Typography className="w-75 mt-5" style={{ textAlign: 'justify' }}>
                                O maior e melhor programa de fidelidade do segmento agrícola, com 20 anos
                                de tradição, presenteando os clientes com produtos diferenciados, serviços
                                exclusivos e viagens incríveis, realizando sonhos de todas as famílias que formam
                                a base do mercado de revendas agrícolas.
                            </Typography>
                            <Button size="small" variant="contained" className="mt-4" onClick={() => window.open('/brindes', '_self')}>Troque por Brindes</Button>
                        </div>
                    </div>
                </div>
            }
            {/* FIM Troque e Ganhe */}






        </div>



    )
}