import { Chip, Typography } from "@mui/material"
import { lightGreen } from "@mui/material/colors";
import TokenContext from 'app/shared/services/token/TokenContext';
import { useState, useContext, useEffect } from "react";
import axios from 'axios';
import AuthService from "app/shared/services/AuthService";
import useIsMobile from "app/shared/hooks/useIsMobile";


const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

interface CreditoProps {
    authService: AuthService;
}

interface Credito {
    cnpj: string,
    desconto: string,
    desdobramento: string,
    dtemissao: string,
    dtpagto: string,
    dtvencto: string,
    juros: string,
    limite: string,
    numnf: string,
    operacao: string,
    status: string,
    valorpago: string,
    valortit: string
}

export const Credito = (props: CreditoProps) => {

    const token = useContext(TokenContext);
    const cnpj = localStorage.getItem('cliente_cnpj');
    const [Credito, setCredito] = useState<Credito[] | null>(null)
    const [FilteredCredito, setFilteredCredito] = useState<Credito[] | null>(null)
    const [CreditoAtraso, setCreditoAtraso] = useState<Credito[] | null>(null)
    const [loaderCredito, setLoaderCredito] = useState(false);
    const [LimiteDisponivel, setLimiteDisponivel] = useState('');
    const [TituloSaldos, setTituloSaldos] = useState('Todos os Títulos');
    const [LimiteCredito, setLimiteCredito] = useState<string | null>(null);
    const [SaldoAberto, setSaldoAberto] = useState<string | null>(null);
    const [SaldoAtraso, setSaldoAtraso] = useState<string | null>(null);
    const Mobile = useIsMobile();

    const filtracredito = () => {

        const filtered = Credito?.filter(credito => { return credito.status === 'EM ABERTO'; }) || null;
        setFilteredCredito(filtered);
        setTituloSaldos('Saldo em Aberto');
    }
    const todosCreditos = () => {
        setFilteredCredito(Credito);
        setTituloSaldos('Todos os Títulos');
    }

    const filtracreditoAberto = () => {
        const currentDate = new Date();
        const filtered = Credito?.filter(credito => {
            const [day, month, year] = credito.dtvencto.split("/");
            const dtvencto = new Date(`${month}/${day}/${year}`);
            return dtvencto < currentDate && credito.status === 'EM ABERTO';
        }) || null;
        setFilteredCredito(filtered);
        setTituloSaldos('Saldo em Atraso');
    }

    useEffect(() => {
        props.authService.getLoggedUser()
            .then(user => {
                if (user) {
                    const getDadosCliente = async () => {
                        setLoaderCredito(true);
                        try {
                            const response = await axios.get(`https://data-api.agross.com.br/api/v1/pagamentos/${cnpj}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`,

                                },
                            });

                            setCredito(response.data.pagamentos);
                            setFilteredCredito(response.data.pagamentos);
                            const total = response.data.pagamentos.reduce((totalValortit: any, credito: any) => {
                                if (credito.status === 'EM ABERTO') {
                                    const valortitsp = credito.valortit.replace('.', '');
                                    const valortitsv = Number(valortitsp.replace(',', '.'));
                                    return totalValortit + valortitsv;
                                } else {
                                    return totalValortit;
                                }
                            }, 0);
                            const currentDate = new Date();
                            const totalTitulosEmAberto = response.data.pagamentos.reduce((totalValortit: any, credito: any) => {
                                const [day, month, year] = credito.dtvencto.split("/");
                                const dtvencto = new Date(`${month}/${day}/${year}`);

                                if (dtvencto < currentDate && credito.status === 'EM ABERTO') {
                                    const valortitsp = credito.valortit.replace('.', '');
                                    const valortitsv = Number(valortitsp.replace(',', '.'));
                                    return totalValortit + valortitsv;
                                } else {
                                    return totalValortit;
                                }
                            }, 0);

                            console.log(response);

                            setLimiteCredito(response.data.pagamentos[0].limite);
                            const limitecredsp = response.data.pagamentos[0].limite.replace('.', '');
                            const limitecredsv = limitecredsp.replace(',', '.');
                            const totalfinal = Number(limitecredsv) - Number(total);

                            setSaldoAtraso(totalTitulosEmAberto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
                            setSaldoAberto(total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
                            console.log(totalfinal.toLocaleString());

                            setLimiteDisponivel(totalfinal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
                            setLoaderCredito(false);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                    getDadosCliente();
                } else {
                    console.log('No user is signed in');
                    setLoaderCredito(false);

                }
            })
            .catch(error => {
                console.error('An error occurred:', error);
                setLoaderCredito(false);
            });



    }, []);


    return (
        <div style={{ width: Mobile ? '90%' : '70%' }} className={`${Mobile ? 'm-auto' : 'mx-2'} my-2 p-1 position-relative`}>
            <div className={`loaderConta ${loaderCredito ? 'd-flex' : 'd-none'}`}>
                {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                <img src={require('assets/loader.gif')} alt="loader" style={{ width: '13%' }} />
            </div>
            <div style={{ border: '1px solid gray', borderRadius: '0.3rem' }}>
                <div style={{ borderBottom: '1px solid gray', backgroundColor: bege, borderRadius: '0.3rem' }} className="p-2">
                    <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.5rem', color: primary }}>
                        Crédito e Contas a Pagar
                    </Typography>
                </div>
                <div className={`d-flex align-items-center my-3 p-3 justify-content-between rounded-1 ${Mobile ? 'flex-column' : ''}`}>
                    <Chip label={`Limite de Crédito: R$ ${LimiteCredito}`} variant="outlined" style={{ color: primary, borderColor: primary }} className={Mobile ? 'my-2' : ''} />
                    <Chip label={`Limite Disponível: ${LimiteDisponivel}`} variant="outlined" style={{ color: primary, borderColor: primary }} className={Mobile ? 'my-2' : ''}/>
                    <Chip label={'Todos os Títulos'} variant="outlined" style={{ color: primary, borderColor: primary }} clickable onClick={() => todosCreditos()} className={Mobile ? 'my-2' : ''}/>
                    <Chip label={`Saldo em Aberto: ${SaldoAberto}`} variant="outlined" style={{ color: primary, borderColor: primary }} clickable onClick={() => filtracredito()} className={Mobile ? 'my-2' : ''}/>
                    <Chip label={`Saldo em Atraso: ${SaldoAtraso}`} variant="outlined" style={{ color: 'red', borderColor: 'red' }} clickable onClick={() => filtracreditoAberto()} className={Mobile ? 'my-2' : ''}/>

                </div>


            </div>
            <div style={{ border: '1px solid gray', borderRadius: '0.3rem' }} className=" my-3">
                <div style={{ borderBottom: '1px solid gray', backgroundColor: bege, borderRadius: '0.3rem' }} className="p-2">
                    <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.5rem', color: primary }}>
                        {TituloSaldos}
                    </Typography>
                </div>

                <div className=" d-flex align-items-center my-3 p-3 justify-content-between rounded-1"
                    style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto', boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)', backgroundColor: bege }}>
                    <div style={{ width: '25%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.2rem', color: primary }}>
                            Título
                        </Typography>
                    </div>
                    <div style={{ width: '25%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.2rem', color: primary }}>
                            Emissão
                        </Typography>
                    </div>
                    <div style={{ width: '25%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.2rem', color: primary }}>
                            Vencimento
                        </Typography>
                    </div>
                    <div style={{ width: '25%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.2rem', color: primary }}>
                            Valor
                        </Typography>
                    </div>

                </div>
                {FilteredCredito && FilteredCredito.map((solicitacao) => (
                    <div className=" d-flex align-items-center my-3 p-3 justify-content-between rounded-1"
                        style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto', boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)' }}>

                        <div className=" d-flex flex-column align-items-center" style={{ width: '25%', color: primary }}>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                <div>
                                    {Number(solicitacao.numnf).toLocaleString()}
                                </div>
                            </div>
                        </div>
                        <div className=" d-flex flex-column align-items-center" style={{ width: '25%', color: primary }}>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                <div>
                                    {solicitacao.dtemissao}
                                </div>
                            </div>
                        </div>
                        <div className=" d-flex flex-column align-items-center" style={{ width: '25%', color: primary }}>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                <div>
                                    {solicitacao.dtvencto}
                                </div>
                            </div>
                        </div>
                        <div className=" d-flex flex-column align-items-center" style={{ width: '25%', color: primary }}>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                R$ {solicitacao.valortit}

                            </div>

                        </div>


                    </div>
                ))
                }

            </div>
        </div>
    )
}