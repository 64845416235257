import { Typography } from "@mui/material"
import { lightGreen } from "@mui/material/colors";
import TokenContext from 'app/shared/services/token/TokenContext';
import { useState, useContext, useEffect } from "react";
import axios from 'axios';
import AuthService from "app/shared/services/AuthService";
import useIsMobile from "app/shared/hooks/useIsMobile";


const primary = lightGreen[800];
const verdeescuro = lightGreen[900];
const bege = '#F7F0EA';

interface ExtratoProps {
    authService: AuthService;
}

interface ExtratoInterface {
    codfilial: string,
    filial: string,
    cnpj: string,
    nro_nf: string,
    datatrans: string,
    pontos: string,
    tipo: string,
    saldo: string,
    historico: string,
    bloqueado: string
}

export const Extrato = (props: ExtratoProps) => {

    const token = useContext(TokenContext);
    const cnpj = localStorage.getItem('cliente_cnpj');
    const [extrato, setExtrato] = useState<ExtratoInterface[] | null>(null)
    const [loaderExtrato, setLoaderExtrato] = useState(false);
    const Mobile = useIsMobile();

    useEffect(() => {
        props.authService.getLoggedUser()
            .then(user => {
                if (user) {
                    const getDadosCliente = async () => {
                        setLoaderExtrato(true);
                        try {
                            const response = await axios.get(`https://data-api.agross.com.br/api/v1/extrato/${cnpj}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`,

                                },
                            });
                            console.log('minhas solicitações: ', response.data.extrato);
                            setExtrato(response.data.extrato);
                            setLoaderExtrato(false);

                        } catch (error) {
                            console.error(error);
                            setLoaderExtrato(false);
                        }
                    }
                    getDadosCliente();
                } else {
                    console.log('No user is signed in');
                    setLoaderExtrato(false);

                }
            })
            .catch(error => {
                console.error('An error occurred:', error);
                setLoaderExtrato(false);
            });



    }, []);


    return (
        <div style={{ width: Mobile ? '90%' : '70%' }} className={`${Mobile ? 'm-auto' : 'mx-2'} my-2 p-1 position-relative`}>
            <div className={`loaderConta ${loaderExtrato ? 'd-flex' : 'd-none'}`}>
                {/* Aqui você pode adicionar um spinner ou qualquer outra animação de loading */}
                <img src={require('assets/loader.gif')} alt="loader" style={{ width: '13%' }} />
            </div>
            <div style={{ border: '1px solid gray', borderRadius: '0.3rem', fontSize:Mobile? '0.5rem' : '' }}>
                <div style={{ borderBottom: '1px solid gray', backgroundColor: bege, borderRadius: '0.3rem' }} className="p-2">
                    <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize: '1.5rem', color: primary }}>
                        Extrato
                    </Typography>
                </div>
                <div className=" d-flex align-items-center my-3 p-3 justify-content-between rounded-1"
                    style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto', boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)', backgroundColor: bege }}>
                    <div style={{ width: '20%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize:Mobile ? '0.8rem' : '1.2rem', color: primary }}>
                            Nota
                        </Typography>
                    </div>
                    <div style={{ width: '20%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize:Mobile ? '0.8rem' : '1.2rem', color: primary }}>
                            Pontos
                        </Typography>
                    </div>
                    <div style={{ width: '20%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize:Mobile ? '0.8rem' : '1.2rem', color: primary }}>
                            Transação
                        </Typography>
                    </div>
                    <div style={{ width: '20%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize:Mobile ? '0.8rem' : '1.2rem', color: primary }}>
                            Saldo
                        </Typography>
                    </div>
                    <div style={{ width: '20%' }}>
                        <Typography fontFamily={'Arima Madurai'} style={{ textAlign: 'center', fontSize:Mobile ? '0.8rem' : '1.2rem', color: primary }}>
                            Histórico
                        </Typography>
                    </div>
                </div>
                {extrato && extrato.map((solicitacao) => (
                    <div className=" d-flex align-items-center my-3 p-3 justify-content-between rounded-1"
                        style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto', boxShadow: '0px 0px 7px 3px rgba(0, 0, 0, 0.2)' }}>

                        <div className=" d-flex flex-column align-items-center" style={{ width: '20%', color: primary }}>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                <div>
                                    {solicitacao.nro_nf}
                                </div>
                                <div>
                                    {(solicitacao.datatrans).split(' ')[0].split('-').reverse().join('/')}
                                </div>

                            </div>

                        </div>
                        <div className=" d-flex flex-column align-items-center" style={{ width: '20%', color: primary }}>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                {Number(solicitacao.pontos).toLocaleString()}

                            </div>

                        </div>
                        <div style={{ width: '20%', textAlign: 'center' }}>
                            {solicitacao.tipo === 'D' ?
                                <Typography className=" p-2 rounded-2" style={{ width: 'fit-content', color: 'white', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'red' }}>
                                    Débito
                                </Typography>
                                :
                                (solicitacao.tipo === 'C' ?
                                    <Typography className=" bg-success p-2 rounded-2" style={{ width: 'fit-content', color: 'white', marginLeft: 'auto', marginRight: 'auto' }}>
                                        Crédito
                                    </Typography>
                                    :
                                    ((solicitacao.tipo === 'E') ?
                                        <Typography className=" bg-success p-2 rounded-2" style={{ width: 'fit-content', color: 'white', marginLeft: 'auto', marginRight: 'auto' }}>
                                            Extorno
                                        </Typography>
                                        : '')
                                )
                            }
                        </div>
                        <div style={{ width: '20%', color: primary, textAlign: 'center' }}>
                            {Number(solicitacao.saldo).toLocaleString()}
                        </div>
                        <div style={{ width: '20%', color: primary, textAlign: 'center' }}>
                            {solicitacao.historico}
                        </div>

                    </div>
                ))
                }

            </div>
        </div>
    )
}