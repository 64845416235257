import React, { useEffect, useState } from 'react';
import { isAfter, addDays, set, differenceInDays, format, nextThursday } from 'date-fns';
// Importe sua biblioteca de flip clock aqui. Isso é apenas um placeholder.
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';


const getNextThursday = (): Date => {
    const now = new Date();
    const today = now.getDay();
    const targetDay = 4; // Quinta-feira
    const daysUntilNextThursday = (targetDay + 7 - today) % 7 || 7;
  
    let nextThursday = addDays(now, daysUntilNextThursday);
    nextThursday = set(nextThursday, { hours: 15, minutes: 45, seconds: 0 });
  
    if (isAfter(now, nextThursday)) {
      nextThursday = addDays(nextThursday, 7);
    }
  
    return nextThursday;
  };


export const Cronometro = () => {
    const [time, setTime] = useState<string>();

    useEffect(() => {
        const targetDate = getNextThursday();

        const interval = setInterval(() => {
            const now = new Date();

            // Calcula a diferença total em segundos
            const totalDifferenceInSeconds = Math.max(Math.floor((targetDate.getTime() - now.getTime()) / 1000), 0);

            // Calcula os dias e o restante em segundos
            const days = differenceInDays(targetDate, now);
            const remainderSeconds = totalDifferenceInSeconds - days * 86400; // segundos em um dia

            // Formata o tempo restante para o formato 'DD:HH:mm:ss'
            const timeFormat = 'dd:HH:mm:ss';
            const formattedTime = format(new Date(remainderSeconds * 1000), timeFormat);

            setTime(`${String(days).padStart(2, '0')}:${formattedTime}`);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return <FlipClockCountdown to={getNextThursday()} />;
}