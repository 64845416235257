import { PortfolioComponent } from "app/shared/components"




export const Portfolio = () => {

    return (
        <div>
            
            <PortfolioComponent />
            
            
        </div>



    )
}