async function fetchToken() {
    const url = 'https://data-api.agross.com.br/api/auth/login/?email=suporte-mkt2022@agross.com.br&password=Ces2020@mkt';

    const response = await fetch(url, {
        method: 'POST',
    });

    const data = await response.json();
    

    return data.access_token; // Substitua "token" pelo nome correto da chave do token na resposta da sua API.
}

export default fetchToken;
